import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { MESSAGE } from 'src/app/constant/message';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-reassigneddriver',
  templateUrl: './reassigneddriver.component.html',
  styleUrls: ['./reassigneddriver.component.css']
})
export class ReassigneddriverComponent implements OnInit,OnDestroy {

  public onClose: Subject<{}>;
  public formGroup: FormGroup = new FormGroup({
    bookingNo: new FormControl({ value: '', disabled: true }, [Validators.required]),
    driverId: new FormControl('', [Validators.required])
  });
  public data: any = null;
  public driverList: any[] = [];
  public showError: boolean = false;
  private formGroupSubscription: Subscription;

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private utilityService: UtilityService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    if (this.options.initialState) {
      const tempData: any = this.options.initialState;
      this.data = (tempData || {}).data || null;
      this.driverList = (tempData || {}).driverList || [];
      this.formGroup.patchValue({
        bookingNo: ((tempData || {}).data || {}).bookingNo || ''
      });
    }
    this.formGroupSubscription = this.formGroup.valueChanges.subscribe((value) => {
      this.showError = false;
    });
  }

  assignDriverManually = (): void => {
    if (this.formGroup.valid) {
      const postData: any = this.formGroup.getRawValue();
      const driverDetails = this.driverList.find((item) => item._id == postData.driverId);
      if ((((driverDetails || {}).tankDetails || [])[0] || {}).truckId) {
        this.loaderService.showLoader();
        postData['driverName'] = driverDetails.firstName + ' ' + driverDetails.lastName;
        postData['truckId'] = driverDetails.tankDetails[0].truckId;
        this.httpService.putData(ApiUrl.ASSIGN_DRIVER_MANUALLY, postData).subscribe((response) => {
          this.loaderService.hideLoader();
          if (response.statusCode == ResponseStatusCode.OK) {
            this.messageService.alertWithTimer('success', MESSAGE.UPDATED, '');
            this.closeModalWithYes();
          }
        }, (error) => {
          this.loaderService.hideLoader();
          this.utilityService.unAuthorized(error);
        });
      } else {

      }
    } else {
      this.showError = true;
    }
  }

  closeModalWithYes = (): void => {
    this.onClose.next({ success: true });
    this._bsModalRef.hide();
  }

  closeModal = (): void => {
    this.onClose.next({ success: false });
    this._bsModalRef.hide();
  }

  ngOnDestroy(): void {
    if (this.formGroupSubscription) {
      this.formGroupSubscription.unsubscribe();
    }
  }


}

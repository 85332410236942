<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">
                {{formGroup.get('status')?.value == 5 ? 'Ended' : 'Canceled' }} Order
            </div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()"><img
                        src="/assets/images/login-flow-images/Group 791063.png"></a>
            </div>
        </div>

        <div class="bg-white">
            <div class="p-30 popup-input">
                <form [formGroup]="formGroup" (ngSubmit)="updateBookingStatusWithReason()">
                    <h2 class="delet-card mb-3 text-break">Are you sure you want to <br />
                        {{ formGroup.get('status')?.value == 5 ? 'Ended' : 'Canceled'}} this order?</h2>

                    <div class="col-12 mb-3">
                        <label for="reason">Reason</label>
                        <textarea class="form-control" placeholder="Enter Reason" name="reason" id="reason"
                            formControlName="reason" cols="30" rows="6">
                            </textarea>
                        <span class="show-input-error"
                            *ngIf="formGroup.controls['reason'].hasError('required') && showError">Please
                            enter Reason</span>
                    </div>

                    <button type="submit"
                        class="btn btn-default btn-hover color-1 text-uppercase w-100 mx-auto d-block height-50">Submit</button>
                </form>
            </div>
        </div>

    </div>
</div>
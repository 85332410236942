import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [CookieService]
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    // loginBy: new FormControl(3),
    // loginThrough: new FormControl('WEB'),
    password: new FormControl('', [Validators.required])
  });
  errorMsg: string = '';
  loginFormSubscription: Subscription;
  showError: boolean = false;
  isRememberMe: boolean = false;
  public passwordType: string = 'password';
  public passwordTypeClass: string = 'fa fa-fw fa-eye field-icon toggle-password fa-eye fa-eye-slash';


  constructor(
    private httpService: HttpService,
    private router: Router,
    private loaderService: LoaderService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.loginFormSubscription = this.loginForm.valueChanges.subscribe((value) => {
      this.errorMsg = '';
      this.showError = false;
    });
    if (this.cookieService.get('GAS_IT_UP_CUSTOMER_PANEL_REMEMBER_ME') &&
      this.cookieService.get('GAS_IT_UP_CUSTOMER_PANEL_REMEMBER_ME') === 'YES') {
      this.loginForm.patchValue({
        email: this.cookieService.get('GAS_IT_UP_CUSTOMER_PANEL_EMAIL') || '',
        password: this.cookieService.get('GAS_IT_UP_CUSTOMER_PANEL_PASSWORD') || ''
      });
      this.isRememberMe = true;
    }
  }

  login = (): void => {
    if (this.loginForm.valid) {
      this.loaderService.showLoader();
      this.httpService.postData(ApiUrl.LOGIN, this.loginForm.getRawValue()).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK && response.data && response.data.accessToken && response.data._id) {
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('companyTrackingId', response.data._id);
          localStorage.setItem('twoStepVerification', response.data.twoStepVerification);
          if (this.isRememberMe) {
            this.cookieService.set('GAS_IT_UP_CUSTOMER_PANEL_REMEMBER_ME', 'YES', 7, '', '', true, 'Strict');
            this.cookieService.set('GAS_IT_UP_CUSTOMER_PANEL_EMAIL', this.loginForm.get('email')?.value, 7, '', '', true, 'Strict');
            this.cookieService.set('GAS_IT_UP_CUSTOMER_PANEL_PASSWORD', this.loginForm.get('password')?.value, 7, '', '', true, 'Strict');
          } else {
            this.cookieService.set('GAS_IT_UP_CUSTOMER_PANEL_REMEMBER_ME', 'NO', 7, '', '', true, 'Strict');
            this.cookieService.set('GAS_IT_UP_CUSTOMER_PANEL_EMAIL', '', 7, '', '', true, 'Strict');
            this.cookieService.set('GAS_IT_UP_CUSTOMER_PANEL_PASSWORD', '', 7, '', '', true, 'Strict');
          }
          if (response.data && response.data.twoStepVerification) {
            localStorage.setItem('loginDetails', JSON.stringify(response.data));
            this.router.navigate(['/two-step-verification', response.data]);
          } else {
            this.router.navigate(['/admin-defaults']);
          }
        }
      }, (error) => {
        this.loaderService.hideLoader();
        // if (error.statusCode == ResponseStatusCode.UNAUTHORIZED) {
        //   this.router.navigate(['/login']);
        // }
        // this.errorMsg = error.message;
      });
    } else {
      this.showError = true;
    }
  }

  ngOnDestroy(): void {
    if (this.loginFormSubscription) {
      this.loginFormSubscription.unsubscribe();
    }
  }

  changePasswordType = (): void => {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
      this.passwordTypeClass = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.passwordType = 'password';
      this.passwordTypeClass = 'fa fa-fw fa-eye field-icon toggle-password fa-eye fa-eye-slash';
    }
  }

}

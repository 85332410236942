<header class="login-bg ">
    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <!-- <img
                    src="./../../../assets/images/login-flow-images/logo-with-title-purple.svg"> -->
                    <i class="">
                        <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                        <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px" class="logotext"/>
                    </i>
                </a>
            <div class="login-cover">
                <h4 class="login-head">Forgot Your Password</h4>
                <form [formGroup]="forgotForm" (ngSubmit)="forgot()">
                    <div class="mb-3">
                        <label for="email">Reset By</label>
                        <div class="styled-select">
                            <select class="form-select" id="sel1" name="resetBy" formControlName="resetBy">
                                <option value="EMAIL">E-mail</option>
                                <option value="PHONE">Phone</option>
                            </select>
                        </div>
                    </div>
                    <div class="mb-3" *ngIf="forgotForm.controls['resetBy'].value == 'PHONE' ">
                        <label for="pwd">Phone Number</label>
                        <input type="text" class="form-control" id="email" placeholder="Enter your Phone Number"
                            name="phoneNumber" autocomplete="off" formControlName="phoneNumber">
                        <span class="show-input-error" *ngIf="forgotForm.controls['phoneNumber'].hasError('required') 
                            && showError">Please enter your Phone Number</span>
                    </div>

                    <div class="mb-3" *ngIf="forgotForm.controls['resetBy'].value == 'EMAIL' ">
                        <label for="pwd">E-mail</label>
                        <input type="email" class="form-control" id="email" placeholder="Enter your E-mail" name="email"
                            formControlName="email" autocomplete="off">
                        <span class="show-input-error"
                            *ngIf="forgotForm.controls['email'].hasError('required') && showError">Please enter your
                            E-mail</span>
                        <span class="show-input-error" *ngIf="!forgotForm.controls['email'].hasError('required') 
                            && forgotForm.controls['email'].hasError('email') && showError">Please enter valid
                            E-mail</span>
                    </div>

                    <button type="submit" class="btn btn-default btn-hover color-1 mb-3 ">Request Reset Link</button>
                    <p class="acc-create">You remember your password?<a href="javascript:void(0)"
                            [routerLink]="['/login']">Login</a></p>
                </form>
            </div>
        </div>
    </nav>
</header>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';
import { UploadVehicleInfoComponent } from '../modal/upload-vehicle-info/upload-vehicle-info.component';
import { Location } from '@angular/common';

declare let bootstrap: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public headerType: number = 0;

  private routeSubscription: Subscription;

  public notificationList: any[] = [];
  public profileDetails: any = null;
  public locationId: any = '';

  constructor(
    private httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private utilityService: UtilityService,
    private modalService: BsModalService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.checkHeaderType();
    this.getDetails();
    this.routeSubscription = this.router.events.subscribe(() => {
      this.checkHeaderType();
    });
  }

  getDetails = (): void => {
    this.loaderService.showLoader();
    this.profileDetails = null;
    this.notificationList = [];
    forkJoin([
      // this.httpService.getData(ApiUrl.GET_PROFILE, {}), // for profile
      // this.httpService.postDataFormData(ApiUrl.GET_NOTIFICATIONS, { clearAll: false, isWeb: true, pageNo: 0 }) // for notifications
    ]).subscribe((response: any[]) => {
      this.loaderService.hideLoader();
      this.profileDetails = response[0].data || null;
      if (((response[1].data || [])[0] || {}).count) {
        const data: any[] = response[1].data || [];
        data.splice(3);
        this.notificationList = data || [];
      }
    }, (error) => {
      this.loaderService.hideLoader();
      this.utilityService.unAuthorized(error);
    });
  }

  checkHeaderType = (): void => {
    if (this.router.url.includes('dashboard')) {
      this.headerType = 136;
      if (this.router.url.includes('dashboard-subscription')) {
        this.headerType = 99;
      } else if (this.router.url.includes('dashboard/notifications')) {
        this.headerType = 3;
      } else if (this.router.url.includes('dashboard/payment')) {
        this.headerType = 4;
      } else if (this.router.url.includes('dashboard/select-vehicles')) {
        this.headerType = 5;
      }
       else if (this.router.url.includes('dashboard/dashboard-overview')) {
       this.headerType = 199;
     }
    } else if (this.router.url.includes('delivery-locations')) {
      this.headerType = 11;
      if (this.router.url.includes('delivery-locations/add-new-location')) {
        this.headerType = 12;
      } else if (this.router.url.includes('delivery-locations/edit-location')) {
        this.headerType = 13;
      }
    } else if (this.router.url.includes('manage-vehicles')) {
      this.headerType = 21;
      if (this.router.url.includes('manage-vehicles/add-new-vehicle')) {
        this.headerType = 22;
      } else if (this.router.url.includes('manage-vehicles/edit-vehicle')) {
        this.headerType = 23;
      }
    } else if (this.router.url.includes('subscription-report')) {
      this.headerType = 41;
      // if (this.router.url.includes('automatic-orders/edit-automatic-order')) {
      //   this.headerType = 42;
      // }
    } else if (this.router.url.includes('subscription')) {
      this.headerType = 31;
      // if (this.router.url.includes('orders/edit-order')) {
      //   this.headerType = 32;
      // } else if (this.router.url.includes('orders/track-order')) {
      //   this.headerType = 33;
      // }
    } else if (this.router.url.includes('payments')) {
      this.headerType = 51;
    } else if (this.router.url.includes('detailed-reports')) {
      this.headerType = 71;
    } else if (this.router.url.includes('custom-reports')) {
      this.headerType = 91;
    } else if (this.router.url.includes('snapshot-reports')) {
      this.headerType = 61;
    } else if (this.router.url.includes('profile')) {
      this.headerType = 81;
    } else if (this.router.url.includes('telematics')) {
      this.headerType = 101;
    } else if (this.router.url.includes('wallet')) {
      this.headerType = 111;
    }
    else if (this.router.url.includes('route-optimization')) {
      this.headerType = 121;
    }
    else if (this.router.url.includes('admin-defaults')) {
      this.headerType = 131;
    }
    else if (this.router.url.includes('transactions')) {
      this.headerType = 786;
    }
    else if (this.router.url.includes('meta-vehicles')) {
      this.headerType = 106;
    }
    else if (this.router.url.includes('invoice-template')) {
      this.headerType = 105;
    }
    else if (this.router.url.includes('business-type')) {
      this.headerType = 101;
    }
    else if (this.router.url.includes('dtn-taxes')) {
      this.headerType = 110;
    }
    else if (this.router.url.includes('dtn-product-code')) {
      this.headerType = 102;
    }
    else if (this.router.url.includes('dtn-blend-month')) {
      this.headerType = 103;
    }
    else if (this.router.url.includes('dtn-terminal-detail')) {
      this.headerType = 104;
    }
    else if (this.router.url.includes('business-business-detail')) {
      this.headerType = 111;
    }
    else if (this.router.url.includes('business-business-report')) {
      this.headerType = 112;
    }
    else if (this.router.url.includes('fuel-master/price-master')) {
      this.headerType = 150;
    }
    else if (this.router.url.includes('fuel-master/analytics-master')) {
      this.headerType = 151;
    }
    else if (this.router.url.includes('fuel-master/margin-master')) {
      this.headerType = 152;
    }
    else if (this.router.url.includes('fuel-master/market-master')) {
      this.headerType = 153;
    }
    else if (this.router.url.includes('fuel-master/demand-master')) {
      this.headerType = 154;
    }
    else if (this.router.url.includes('reports/financial-report')) {
      this.headerType = 160;
    }
    else if (this.router.url.includes('reports/marketing-report')) {
      this.headerType = 161;
    }
    else if (this.router.url.includes('reports/feedback-report')) {
      this.headerType = 162;
    }
    else if (this.router.url.includes('reports/revenue-report')) {
      this.headerType = 163;
    }
    else if (this.router.url.includes('reports/price-model-report')) {
      this.headerType = 164;
    }
    else if (this.router.url.includes('reports/churn-rate-report')) {
      this.headerType = 165;
    }
    else if (this.router.url.includes('support/chat-report')) {
      this.headerType = 140;
    }
    // else if (this.router.url.includes('dashboard/dashboard-overview')) {
    //   this.headerType = 199;
    // }
    // else if (this.router.url.includes('dashboard-revenue-generated')) {
    //   this.headerType = 136;
    // }
    // else if (this.router.url.includes('dashboard-subscription')) {
    //   this.headerType = 99;
    // }
    

    if (this.headerType == 5) {
      this.locationId = (this.router.url.split('/') || [])[4] || '';
    }

    // for tooltip
    this.utilityService.initializeToolTip();

  }

  navigate = (routeLink: string): void => {
    if (routeLink) {
      this.router.navigate([routeLink]);
    }
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  uploadVehicleInfo = (): void => {
    const reference = this;
    const modalRef = this.modalService.show(UploadVehicleInfoComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'custom-modal-content-radius modal-dialog-centered',
      // initialState: { vehicleData: vehicleData }
    });
    modalRef.content.onClose.subscribe((result: { success: any; }) => {
      if (result && result.success) {
        reference.utilityService.refreshDataTable.next(true);
      }
    });
  }

  refreshGraphDetails = (): void => {
    switch (this.headerType) {
      case 1:
        this.utilityService.refreshDashboardGraphDetails.next(true);
        break;
      default:
        this.utilityService.refreshCustomGraphDetails.next(true);
        break;
    }
  }

  onClickBack = (): void => {
    //  this.location.back();
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ResponseStatusCode } from '../common/core/responseStatusCode';
import { jsPDF } from "jspdf";
import * as kjua from 'kjua-svg';
import { MessageService } from './message.service';
declare let bootstrap: any;

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private imageEl: HTMLImageElement;
  /**  
  * This @Property is used to subscribe child component to re-render its data table.
  */
  refreshDataTable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshDashboardGraphDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshCustomGraphDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**  
 * This @Property is used for creating new Order.
 */
  public newOrderPlaceDetails: { locationDetails: any, vehicleDetails: any[] } =
    { locationDetails: null, vehicleDetails: [] };
  UtilityService: any;
  /**  
  * This @Property is used to stored data of graph
  */
  // public dashboardGraphDetails: any = null;

  constructor(
    private router: Router,
    private messageService: MessageService
  ) { }

  /**  
  * This @method is being called whenever HTTP throw error.
  * @param error
  */
  unAuthorized = (error: any): void => {
    if (error.statusCode == ResponseStatusCode.UNAUTHORIZED || error.statusCode == ResponseStatusCode.BAD_REQUEST) {
      this.messageService.alertWithTimer('error', error.customMessage ? error.customMessage : error.message)
      this.router.navigate(['/login']);
    } else if (error.statusCode == ResponseStatusCode.INTERNAL_SERVER_ERROR) {
      this.messageService.alertWithTimer('error', error.customMessage ? error.customMessage : error.message)
    } else {
      this.messageService.alert('error', `${'Something Went Error!'} \n ${'Please Refresh The Page'}`)
    }
  }

  /**  
  * This @method is used to show the fuel grade.
  * @param grade fuel grade
  * @return empty string if fuel grade is as number otherwise return grade
  */
  getFuelGrade = (grade: any): any => {
    if (grade) {
      if (/^-?[\d.]+(?:e-?\d+)?$/.test(grade)) {
        return '';
      } else {
        return ' (' + grade + ')';
      }
    } else {
      return '';
    }
  }

  /**  
  * This @method is used to download CSV file.
  * @param response (CSV data).
  * @param name file name of CSV
  */
  downloadCSV = (response: any, name: string): void => {
    const myBlob = new Blob([response], { type: 'text/csv' });
    const downloadUrl = URL.createObjectURL(myBlob);

    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${name}.csv`; // you can take a custom name as well as provide by server

    // start download
    a.click();
    // after certain amount of time remove this object!!!
    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
    }, 100);
  }

  /**  
  * This @method is used to download PDF file.
  * @param response (PDF data).
  * @param name file name of PDF
  */
  downloadPDF = (response: any, name: string): void => {
    const myBlob = new Blob([response], { type: 'application/pdf' });
    const downloadUrl = URL.createObjectURL(myBlob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${name}.pdf`; // you can take a custom name as well as provide by server

    // start download
    a.click();
    // after certain amount of time remove this object!!!
    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
    }, 100);

  }

  /**  
   * This @method is used to download QR-CODE Image.
   * @param response (IMAGE data).
   * @param name image name of QR-Code
   */
  downLoadQRCodeImage = (response: any, name: string): void => {
    const TYPED_ARRAY: any = new Uint8Array(response.data);
    const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
    const base64String = btoa(STRING_CHAR);
    var a = document.createElement('a');
    a.href = `${'data:image/jpg;base64,'}${base64String}`;
    a.download = `${name}.jpg`;
    a.target = `${'_blank'}`;
    a.click();
  }

  /**  
   * Initialize the bootstrap tooltip from all html element where having @property data-bs-toggle="tooltip".
   */
  initializeToolTip = (): void => {
    // for tooltip
    setTimeout(() => {
      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      });
    }, 2000);
  }

  /**  
  * This @method is used to download CSV file after converting data to CSV.
  * @param data (data to convert into CSV data for download).
  * @param filename file name of CSV
  */
  convertCSV(data: any[], filename: string) {
    const csvData = this.ConvertToCSV(data);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;

    // const isIE = /* @cc_on!@ */ false || !!document.documentMode;

    // if (isIE) {
    //   const retVal = navigator.msSaveBlob(blob, `${filename}.csv`);
    // } else {
    //   a.download = `${filename}.csv`;
    // }
    // If you will any error in a.download then dont worry about this.
    a.download = `${filename}.csv`;
    a.click();

  }

  /**  
  * This @method is used to convert data to CSV.
  * @param objArray .
  * @return CSV data after making
  */
  private ConvertToCSV(objArray: any[]) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (var index in objArray[0]) {
      // Now convert each value to string and comma-separated
      row += `${index},`;
    }
    row = row.slice(0, -1);
    // append Label row with line break
    str += `${row}\r\n`;

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (var index in array[i]) {
        if (line != '') line += ',';

        line += array[i][index];
      }
      str += `${line}\r\n`;
    }
    return str;
  }

  /**  
  * This @method is used to download CSV file after converting data to CSV with Multi Header of CSV file.
  * @param data (data to convert into CSV data for download).
  * @param filename file name of CSV
  */
  convertCSVMultiHeader(data: any[], filename: string) {
    const csvData = this.ConvertToCSVMultiHeader(data);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;

    a.download = `${filename}.csv`;
    a.click();
  }

  /**  
  * This @method is used to convert data to CSV with Multi Header of CSV file.
  * @param objArray .
  * @return CSV data after making
  */
  private ConvertToCSVMultiHeader(objArray: any[]) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (var index in objArray[0]) {
      // Now convert each value to string and comma-separated
      row += `${index},`;
    }
    row = row.slice(0, -1);
    // append Label row with line break
    str += `${row}\r\n`;

    for (let i = 0; i < array.length; i++) {

      let tempRow = '';
      if (array[i] == '\n') {
        for (var indexFirst in array[i + 1]) {
          tempRow += `${indexFirst},`;
        }
        str += `\r\n${tempRow}\r\n`;
        continue;
      }

      let line = '';
      for (var index in array[i]) {
        if (line != '') line += ',';

        line += array[i][index];
      }
      str += `${line}\r\n`;
    }
    return str;
  }

  /**  
  * This @method is used to get the to fixed value.
  * @param data . need to fixed
  * @param toFixedValue how many to fixed the value
  * @return fixed value as string
  */
  getFixed(data: any, toFixedValue: number) {
    return parseFloat(data).toFixed(toFixedValue);
  }

  /**  
 * This @method is used to get number with commas seprated.
 * @param data . need to be commas seprated
 * @return 
 */
  numberWithCommas = (data: any): any => {
    var parts = data.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  normalizePhoneNumber = (phone: any): any => {
    phone = phone.replace(/[^\d]/g, "");
    if (phone.length == 10) {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return null;
  }

  unNormalizePhoneNumber = (phone: any): any => {
    phone = phone.replace(/[^\d]/g, "");
    if (phone.length == 10) {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1$2$3");
    }
    return null;
  }

  getPaymentMethodText = (paymentMethod: number): string => {
    let text = '';
    switch (paymentMethod) {
      case 1:
        text = 'CHASE_PAY';
        break;
      case 2:
        text = 'AUTHORIZE_CARD';
        break;
      case 3:
        text = 'GOOGLE_PAY';
        break;
      case 4:
        text = 'APPLE_PAY';
        break;
      case 5:
        text = 'ACH_IN';//'ACH_BANK';
        break;
      case 6:
        text = 'WEX_CARD';
        break;
      case 7:
        text = 'COMDATA_CARD';
        break;
      case 8:
        text = 'VOYAGER_CARD';
        break;
      case 10:
        text = 'ACH_OUT';//'CASH';
        break;
      case 11:
        text = 'WEX(EFS)_CARD';
        break;
      case 12:
        text = 'BANK';
        break;
      default:
        break;
    }
    return text;
  }

  generateQr = (data: any, type: string): void => {

    // this.loaderService.showLoader();
    // const postData: any = {
    //     id: data._id
    // };
    // this.httpService.postBlobData(ApiUrl.GENERATE_QR, postData).subscribe((response) => {
    //     this.loaderService.hideLoader();
    //     this.utilityService.downLoadBlobImage(response, 'qrCode');
    // }, (error) => {
    //     this.loaderService.hideLoader();
    //     this.utilityService.unAuthorized(error);
    // });
    let document = new jsPDF();
    let barcodeData;
    if (((data || {}).vehicleId || {})._id) {
      barcodeData = this.getBarcodeData(data.vehicleId._id, '');
    } else {
      barcodeData = this.getBarcodeData(data._id, '');
    }
    document.addImage(barcodeData, 'png', 35, 20, 150, 150);
    document.setFontSize(12);
    document.setTextColor('#36155d');
    let pdfName: any = '';
    switch (type) {
      case 'CUSTOMER_DETAILS':
        // document.text((data.truckNumber ? ('Vehicle Number: ' + data.truckNumber) : ('License Plate: ' + data.licensePlate)), 80, 170);
        document.text((('Vehicle Number : ' + (data || {}).truckNumber) + ' \n' + ('License Plate : ' + (data || {}).licensePlate)), 80, 170);
        // pdfName = (data.truckNumber ? ('Vehicle Number: ' + data.truckNumber) : ('License Plate: ' + data.licensePlate));
        pdfName = (('Vehicle Number : ' + (data || {}).truckNumber) + ' - ' + ('License Plate : ' + (data || {}).licensePlate));
        break;
      case 'VEHICLES':
        document.text((('Make : ' + (data || {}).make + '') + ' \n' + ('Model : ' + (data || {}).model + '')), 80, 170);
        pdfName = (('Make : ' + (data || {}).make + '') + ' - ' + ('Model : ' + (data || {}).model + ''));
        break;
      case 'DRIVERS':
        document.text((('Driver Name: ' + (((data || {}).firstName + '') + ' ' + (data || {}).lastName + '')) + ' \n' + ('Driver ID: ' + (data || {}).driverId + '')), 80, 170);
        pdfName = (('Driver Name: ' + (((data || {}).firstName + '') + ' ' + (data || {}).lastName + '')) + ' - ' + ('Driver ID: ' + (data || {}).driverId + ''));
        break;
      case 'TRUCK_AND_COSTS':
        document.text((('Truck Name: ' + (data || {}).name + '') + ' \n' + ('Truck Number: ' + (data || {}).number + '')), 80, 170);
        pdfName = (('Truck Name: ' + (data || {}).name + '') + ' - ' + ('Truck Number: ' + (data || {}).number + ''));
        break;
      case 'ORDER_VEHICLE_LISTING':
        if (((data || {}).vehicleId || {})._id) {
          // document.text((data.vehicleId.truckNumber ? ('Vehicle Number: ' + data.vehicleId.truckNumber) : ('License Plate: ' + data.vehicleId.licensePlate)), 80, 170);
          // pdfName = (data.vehicleId.truckNumber ? ('Vehicle Number: ' + data.vehicleId.truckNumber) : ('License Plate: ' + data.vehicleId.licensePlate));
          document.text((('Vehicle Number : ' + (data || {}).vehicleId.truckNumber) + ' \n' + ('License Plate : ' + (data || {}).vehicleId.licensePlate)), 80, 170);
          pdfName = (('Vehicle Number : ' + (data || {}).vehicleId.truckNumber) + ' - ' + ('License Plate : ' + (data || {}).vehicleId.licensePlate));
        }
        break;

      default:
        break;
    }
    document.save(`${pdfName}${' '}QR-Code.pdf`);
  }

  getBarcodeData(text: string, label: string) {
    let options: any = {
      render: 'image',
      crisp: true,
      minVersion: 5,
      ecLevel: "H",
      size: 800,
      ratio: undefined,
      fill: '#36155d',
      back: '#ffffff',
      text,
      rounded: 10,
      quiet: 2,
      mode: 'imagelabel',
      mSize: [20, 5],
      mPosX: [48, 50],
      mPosY: [50, 100],
      // label,
      fontname: 'Ubuntu',
      fontcolor: '#552d86',
      image: this.imageEl,
      imageAsCode: false
    }
    return kjua(options);
  }

}

<div class="edit-modal icon-place">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">
                Add Manual Delivery Date ({{formGroup.get('bookingNo')?.value}})
            </div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="/assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>

        <div class="bg-white">
            <div class="p-30 light-blue">
                <form [formGroup]="formGroup" (ngSubmit)="addDeliveryDateManualy()">
                    <div class="row border-grey grayinput">
                        <div class="col-md-12 mb-3 ">
                            <label for="date">Select Date</label>
                            <input type="date" class="form-control height-50 border-8" name="date" id="date"
                                placeholder="Select Date" formControlName="date" [min]="minSelectDate" />
                            <span class="show-input-error"
                                *ngIf="formGroup.controls['date'].hasError('required') && showError">Please
                                select Date</span>
                        </div>
                        <div class="col-md-12 mb-3 ">
                            <label ffor="timeInput">Select Time</label>
                            <input type="time" class="form-control height-50 border-8" name="time" id="timeInput"
                                placeholder="Select Time" formControlName="time" />
                            <span class="show-input-error"
                                *ngIf="formGroup.controls['time'].hasError('required') && showError">Please
                                select Time</span>
                        </div>
                        <div class="col-12">
                            <button type="submit"
                                class="btn btn-default btn-hover color-1 w-100 height-50 mx-auto d-block ">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>
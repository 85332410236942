import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Pagination } from 'src/app/models/pagination';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges, DoCheck {

  @Input() paginationOptions: Pagination;
  @Output() changePage: EventEmitter<any> = new EventEmitter<any>();

  public pageList: any[] = [];

  constructor() { }

  ngDoCheck(): void {
    // this.pageList = [];
    // if (this.paginationOptions.pageNo && this.paginationOptions.pageMaxSize) {
    //   for (let index = this.paginationOptions.pageNo; index < (this.paginationOptions.pageMaxSize + this.paginationOptions.pageNo); index++) {
    //     this.pageList.push(index);
    //   }
    // }
  }

  ngOnInit(): void {
    this.pageList = [];
    if (this.paginationOptions.pageNo && this.paginationOptions.pageMaxSize) {
      for (let index = this.paginationOptions.pageNo; index <= this.paginationOptions.pageMaxSize; index++) {
        this.pageList.push(index);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  cal() {
    if (this.paginationOptions.skip + this.paginationOptions.limit <= this.paginationOptions.total) {
      return this.paginationOptions.skip + this.paginationOptions.limit;
    } else {
      return this.paginationOptions.total;
    }
  }

  onChangePage = (currentPage: number): void => {
    this.paginationOptions.pageNo = currentPage;
    this.changePage.emit({});
  }

  previous = (): void => {
    if (parseInt(this.pageList[0]) == 1) {
      return;
    }
    this.paginationOptions.pageNo -= 1;
    this.pageList.unshift(parseInt(this.pageList[this.paginationOptions.pageMaxSize - 1]) - this.paginationOptions.pageMaxSize);
    this.pageList.pop();
    this.changePage.emit({});
  }

  next = (): void => {
    if (parseInt(this.pageList[this.paginationOptions.pageMaxSize - 1]) ==
      (parseInt((this.paginationOptions.total / this.paginationOptions.limit).toFixed()) + 1)) {
      return;
    }
    this.paginationOptions.pageNo += 1;
    this.pageList.push(parseInt(this.pageList[this.paginationOptions.pageMaxSize - 1]) + 1);
    this.pageList.shift();
    this.changePage.emit({});
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-schedule-fuel-delivery',
  templateUrl: './schedule-fuel-delivery.component.html',
  styleUrls: ['./schedule-fuel-delivery.component.css']
})
export class ScheduleFuelDeliveryComponent implements OnInit, OnDestroy {

  public onClose: Subject<{}>;

  public scheduleFuelDeliveryForm: FormGroup = new FormGroup({
    date: new FormControl('', [Validators.required]),
    time: new FormControl('', [Validators.required])
  });
  private scheduleFuelDeliveryFormSubscription: Subscription;
  public showError: boolean = false;


  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private utilityService: UtilityService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.scheduleFuelDeliveryForm.patchValue({
      date: moment().format("yyyy-MM-DD"),
      time: moment().add(2, 'hours').format("HH:mm")
    });
    this.onClose = new Subject();
    // if (this.options.initialState) {
    //   this.data = this.options.initialState['data'] || '';
    // }
    this.scheduleFuelDeliveryFormSubscription = this.scheduleFuelDeliveryForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
  }

  closeModalWithYes = (): void => {
    if (this.scheduleFuelDeliveryForm.valid) {
      this.showError = false;
      const toDate = moment().unix() * 1000;
      const time = 2 * 60 * 60 * 1000;
      const newTime = moment(new Date(this.scheduleFuelDeliveryForm.get('date')?.value + ' ' + this.scheduleFuelDeliveryForm.get('time')?.value)).unix() * 1000;
      if ((newTime - toDate) >= time) {
        this.onClose.next({ success: true, data: this.scheduleFuelDeliveryForm.getRawValue() });
        this._bsModalRef.hide();
      } else {
        this.messageService.alert('error', 'Scheduled orders must be placed at least 2 hours in advance');
      }
    } else {
      this.showError = true;
    }
  }

  closeModal = (): void => {
    this.onClose.next({ success: false });
    this._bsModalRef.hide();
  }

  ngOnDestroy(): void {
    if (this.scheduleFuelDeliveryFormSubscription) {
      this.scheduleFuelDeliveryFormSubscription.unsubscribe();
    }
  }

}

import { UtilityService } from './../../services/utility.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
declare var google: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit, OnDestroy {
  public contactPersonFlag: boolean = false;
  public businessAddressFlag: boolean = false;

  // Business form 
  signUpForm: FormGroup = new FormGroup({
    businessEmail: new FormControl('', [Validators.email, Validators.required]),
    businessName: new FormControl('', [Validators.required]),
    businessPhone: new FormControl('', [Validators.required]),
    // countryCode: new FormControl('+1'),
    // userType: new FormControl(2),
    referBy: new FormControl(''),
    businessPassword: new FormControl('', [Validators.required, Validators.minLength(6)]) // Password length must be at least 6 characters long.
  });
  // Contact Person Form
  contactPersonForm: FormGroup = new FormGroup({
    businessContactPersonFirstName: new FormControl('', [Validators.required]),
    businessContactPersonLastName: new FormControl('', [Validators.required]),
    businessContactPersonEmail: new FormControl('', [Validators.email, Validators.required]),
    businessContactPersonPhone: new FormControl('', [Validators.required]),
  });
  // Address Form
  addressSetupForm: FormGroup = new FormGroup({
    searchAddress: new FormControl(''),
    businessAddress1: new FormControl('', [Validators.required]),
    businessAddress2: new FormControl(''),
    businessZipCode: new FormControl(''),
    businessCountry: new FormControl('', [Validators.required]),
    businessState: new FormControl('', [Validators.required]),
    businessCity: new FormControl('', [Validators.required]),
    businessCounty: new FormControl('', [Validators.required]),

  });

  signUpFormSubscription: Subscription;
  contactPersonFormSubscription: Subscription;
  addressSetupFormSubscription: Subscription;
  showError: boolean = false;
  public passwordType: string = 'businessPassword';
  public passwordTypeClass: string = 'fa fa-fw fa-eye field-icon toggle-password fa-eye fa-eye-slash';


  constructor(
    private httpService: HttpService,
    private router: Router,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.signUpFormSubscription = this.signUpForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
    this.contactPersonFormSubscription = this.contactPersonForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
    this.addressSetupFormSubscription = this.contactPersonForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
  }



  changePasswordType = (): void => {
    if (this.passwordType == 'businessPassword') {
      this.passwordType = 'text';
      this.passwordTypeClass = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.passwordType = 'businessPassword';
      this.passwordTypeClass = 'fa fa-fw fa-eye field-icon toggle-password fa-eye fa-eye-slash';
    }

  }

  // BUSINESS
  signUp = (): void => {
    if (this.signUpForm.valid) {
      this.contactPersonFlag = true
      this.businessAddressFlag = false
    } else {
      this.showError = true;
    }
  }

  // CONTACT PERSON
  contactPersonSubmit = (): void => {
    if (this.contactPersonForm.valid) {
      this.businessAddressFlag = true
      this.contactPersonFlag = false
    } else {
      this.showError = true;
    }
  }
  backToBusiness = (): void => {
    this.contactPersonFlag = false;
    this.businessAddressFlag = false
  }

  // ADDRESS SETUP
  backToContact = (): void => {
    this.businessAddressFlag = false
    this.contactPersonFlag = true
  }

  getCurrentPosition = (): void => {
    const reference = this;
    navigator.geolocation.getCurrentPosition((pos) => {
      const currentLocation = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
      const geocoder = new google.maps.Geocoder();
      const location_obj: any = {};
      geocoder.geocode({ 'latLng': currentLocation }, (results: any, status: any) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            for (var i = 0; i < results[0].address_components.length; i++) {
              for (var b = 0; b < results[0].address_components[i].types.length; b++) {
                if (results[0].address_components[i].types.indexOf('locality') > -1) {
                  location_obj['locality'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('administrative_area_level_1') > -1) {
                  location_obj['admin_area_l1'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('street_number') > -1) {
                  location_obj['street_number'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('route') > -1) {
                  location_obj['route'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('country') > -1) {
                  location_obj['country'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('postal_code') > -1) {
                  location_obj['postal_code'] = results[0].address_components[i]['long_name'];
                }
                location_obj['formatted_address'] = results[0].formatted_address;
              }
            }
          }

        }
      });
    }, (error) => {
      console.log('Unable to get location: ' + error.message);
    });
  }

  searchAddress = (data: any): void => {
    this.addressSetupForm.patchValue({
      businessAddress1: (data || {})?.name,
      businessAddress2: (data || {})?.route,
      businessCountry: (data || {})?.country || '',
      businessState: (data || {})?.admin_area_l1 || '',
      businessCity: (data || {})?.locality || '',
      // businessCounty: (data || {})?.locality || '',
      businessZipCode: (data || {})?.postal_code ? (data || {})?.postal_code : '1000',
    });
    this.getCountyUsingFromZipcode();
  }

  getCountyUsingFromZipcode = (): void => {
    if (this.addressSetupForm.get('businessZipCode')?.valid) {
      const postData = {
        zipcode: this.addressSetupForm.get('businessZipCode')?.value
      };
      this.httpService.getCommonData(ApiUrl.SAAS_GET_COUNTY_USING_ZIPCODE, postData).subscribe((response) => {
        if (response.statusCode == ResponseStatusCode.OK && response.data) {
          this.addressSetupForm.patchValue({
            businessCounty: (response || {}).data.county || '',
          });
        } else {
          this.addressSetupForm.patchValue({
            businessCounty: '',
          });
        }
      }, (error) => {
        this.loaderService.hideLoader();
      });
    }
  }

  addressSetup = (): void => {
    if (this.addressSetupForm.valid) {
      this.loaderService.showLoader();
      const signUpData = this.signUpForm.getRawValue();
      const contactPersonData = this.contactPersonForm.getRawValue();
      const addressData = this.addressSetupForm.getRawValue();
      const postData = { ...signUpData, ...contactPersonData, ...addressData };
      if (postData.referBy == '') {
        delete postData.referBy;
      }
      delete postData.searchAddress;
      this.loaderService.hideLoader();

      this.httpService.postData(ApiUrl.SAAS_CREATE_BUSINESS_USER, postData).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK) {
          this.messageService.alertWithTimer('success', 'Successfully created');
          setTimeout(() => {
            localStorage.setItem('alldata', JSON.stringify(postData));
            this.router.navigate(['/subscription-plans']);
          }, 2000);
        }
      }, (error) => {
        this.messageService.alert('error', error.customMessage);
        this.loaderService.hideLoader();
      });
    } else {
      this.showError = true;
    }
  }

  ngOnDestroy(): void {
    if (this.signUpFormSubscription) {
      this.signUpFormSubscription.unsubscribe();
    } else if (this.contactPersonFormSubscription) {
      this.contactPersonFormSubscription.unsubscribe();
    } else if (this.addressSetupFormSubscription) {
      this.addressSetupFormSubscription.unsubscribe();
    }
  }



}

<!--modal-csv-->
<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">
                <img src="./../../../../assets/images/login-flow-images/Vector (2).png" class="pe-2">
                Upload Vehicle Info
            </div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="./../../../../assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>

        <div class="p-30 light-blue">
            <form [formGroup]="uploadVehicleInfoForm" >
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <label for="email">Location</label>
                        <div class="styled-select black-icon">
                            <select class="form-select" id="locationId" name="locationId"
                            formControlName="locationId">
                            <option value="">All</option>
                            <option [value]="location?._id" *ngFor="let location of locationList">
                                {{location?.locationName}} ({{location?.address}})
                            </option>
                        </select>
                        </div>
                        <span class="show-input-error"
                        *ngIf="uploadVehicleInfoForm.controls['locationId'].hasError('required') && showError">Please select Location</span>
                    </div>


                    <div class="col-md-12 mb-2">
                        <label for="pwd">Upload CSV</label>
                        <div class="icon-input">
                            <div class="fileUpload blue-btn btn width100 input-design">
                                <span>{{fileName}}</span>
                                <input type="file" class="uploadlogo" id="file-logo" accept=".csv" 
                                (change)="changeSelectFile($event)"/>
                                <label for="file-logo" class="up-file"> Choose file</label>
                            </div>
                        </div>
                        <span class="show-input-error"
                        *ngIf="uploadVehicleInfoForm.controls['file'].hasError('required') && showError">Please select CSV File</span>
                    </div>
                    <p class="sample-c" (click)="downloadSample()" style="cursor: pointer;">Download CSV Sample</p>
                </div>
                <button type="button" (click)="uploadVehicleInfo()"
                    class="btn btn-default btn-hover color-1 mt-3 w-75 mx-auto d-block text-uppercase ">
                    Submit
                </button>
            </form>
        </div>

    </div>
</div>
<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">
                <img src="./../../../../assets/images/login-flow-images/Group.png" class="pe-2">
                Schedule Fuel Delivery
            </div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="./../../../../assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>

        <div class="p-30 popup-input popup-input-date">
            <form [formGroup]="scheduleFuelDeliveryForm" (ngSubmit)="closeModalWithYes()">

                <div class="mb-3">
                    <label for="date">Date</label>
                    <div class="ui calendar" id="date">
                        <div class="icon-place ui input left icon">
                            <input type="date" class="form-control" id="date" placeholder="" name="date"
                                autocomplete="off" formControlName="date"
                                [min]="scheduleFuelDeliveryForm.controls['date']?.value">
                            <!-- <img src="./../../../../assets/images/login-flow-images/calender.png" class="icon-pos"> -->
                        </div>
                    </div>
                    <span class="show-input-error"
                        *ngIf="scheduleFuelDeliveryForm.controls['date'].hasError('required') && showError">Please
                        select date</span>
                </div>

                <div class="mb-4 pb-2">
                    <label for="time">Time</label>
                    <div class="ui calendar" id="time">
                        <div class="icon-place ui input left icon">
                            <input type="time" class="form-control" id="time" placeholder="" name="time"
                                autocomplete="off" formControlName="time">
                            <!-- <img src="./../../../../assets/images/login-flow-images/Vector (1).png" class="icon-pos"> -->
                        </div>
                    </div>
                    <span class="show-input-error"
                        *ngIf="scheduleFuelDeliveryForm.controls['time'].hasError('required') && showError">Please
                        select time</span>
                </div>

                <button type="submit" class="btn btn-default btn-hover color-1 text-uppercase mt-2 ">Next</button>

            </form>
        </div>

    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-access-user',
  templateUrl: './access-user.component.html',
  styleUrls: ['./access-user.component.css']
})
export class AccessUserComponent implements OnInit {

  private accessToken: string = '';

  constructor(
    private httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    localStorage.removeItem('accessToken');
    const routeParams = this.route.snapshot.paramMap;
    this.accessToken = routeParams.get('token') || '';
    this.loginByAccessToken();
  }

  loginByAccessToken = (): void => {
    if (this.accessToken) {
      this.loaderService.showLoader();
      localStorage.setItem('accessToken', this.accessToken);
      this.httpService.getData(ApiUrl.LOGIN_BY_ACCESS_TOKEN).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK && response.data && response.data.accessToken && response.data._id) {
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('companyTrackingId', response.data._id);
          localStorage.setItem('twoStepVerification', response.data.twoStepVerification);
          if (response.data && response.data.twoStepVerification) {
            localStorage.setItem('loginDetails', JSON.stringify(response.data));
            this.router.navigate(['/two-step-verification', response.data]);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      }, (error) => {
        this.loaderService.hideLoader();
        // if (error.statusCode == ResponseStatusCode.UNAUTHORIZED) {
        //   this.router.navigate(['/login']);
        // }
        // this.errorMsg = error.message;
      });
    }
  }

}

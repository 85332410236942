<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">Vehicles Listing
            </div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="/assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>

        <div class="bg-white">
            <div class="light-blue">
                <div class="table-responsive popup-table table-height bg-th h-auto">
                    <table class="table mb-0">
                        <thead class="table-dark table-light">
                            <tr>
                                <th class="serialno">Sr No.</th>
                                <th>Order ID</th>
                                <th>Vin Number</th>
                                <th>Plate Number</th>
                                <th>Car Detail</th>
                                <th>Fuel Used</th>
                                <th>Fillup</th>
                                <th>Filling Time</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Download<br />QR Code </th>
                            </tr>
                        </thead>
                        <tbody class="table-td">
                            <tr
                                *ngFor="let item of paginationControlsOptions.data | paginate: { itemsPerPage: paginationControlsOptions.limit, currentPage: paginationControlsOptions.page, totalItems: paginationControlsOptions.total }; let i = index">
                                <td class="serialno">
                                    {{(paginationControlsOptions.page-1)*paginationControlsOptions.limit + i + 1}}
                                </td>
                                <td> {{item?.bookingId || '-'}} </td>
                                <td>{{item?.vehicleId?.vinNumber || item?.vehicleId[0]?.vinNumber || '-'}}</td>
                                <td>{{item?.vehicleId?.licensePlate || item?.vehicleId[0]?.licensePlate || '-'}}</td>
                                <td>{{item?.vehicleId?.make || item?.vehicleId[0]?.make || '-'}} -
                                    {{item?.vehicleId?.model || item?.vehicleId[0]?.model || '-'}}</td>
                                <td>{{item?.gasId.name}} {{item?.gasId?.grade ? item?.gasId?.grade:''}}
                                </td>
                                <td>{{item?.quantity || 0}}</td>
                                <td> {{item?.fillingTime ? (item?.fillingDateTime | date:'HH:mm:ss') : '-'}} </td>
                                <td>${{(item?.amountTotal || 0) | number:'1.2-2' }}</td>
                                <td>
                                    <span *ngIf="item?.bookingStatus == 0">Pending</span>
                                    <span *ngIf="item?.bookingStatus == 8">Canceled
                                        <span *ngIf="item?.cancelReason">-
                                            ({{item?.cancelReason}})</span>
                                    </span>
                                    <span *ngIf="item.bookingStatus == 15">In Progress</span>
                                    <span *ngIf="item.bookingStatus == 16">Filled</span>
                                </td>
                                <td>
                                    <a class="me-2" href="javascript:void(0)"
                                        (click)="this.utilityService.generateQr(item ,'ORDER_VEHICLE_LISTING')">
                                        <i class="icon-16" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                            title="Download QR Code"><img src="/assets/images/login-flow-images/qr.png"
                                                alt="">
                                        </i>
                                    </a>
                                </td>
                            </tr>
                            <tr *ngIf="!paginationControlsOptions.data?.length">
                                <td colspan="11" style="text-align: center;"> No Record Found!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="p-3 pt-0" *ngIf="paginationControlsOptions.total > 0">
                <!-- <div class="row mt-4" > -->
                    <div class="col-md-12" style="text-align: end;">
                      <pagination-controls class="my-pagination" (pageChange)="getPage($event)"></pagination-controls>
                    </div>
                  <!-- </div> -->
            </div>


        </div>

    </div>
</div>
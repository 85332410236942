<div class="row">
    <div class="col-md-12 position-relative">
    <div class="customsearchbar">
        <input type="text" placeholder="Google map search location" class="form-control" appGooglePlace
            (selectedData)="address($event)" [(ngModel)]="addressText">
        <button class="border-0 bg-transparent" type="button"><img src="/assets/img/search.png" width="16px" height="16px"></button>
        <button class="cross-close border-0 bg-transparent" type="button" *ngIf="addressText"
            (click)="addressText = '' "> <img src="/assets/img/cross.svg"> </button>
    </div>
    </div>
</div>

<div class="wrapper" id="locationContainer">
    <div class="row m-0">
        <div class="col-12 no-gutters p-0 position-relative">
            <div class="map-height">
                <div class="satelite-image">
                    <img src="./../../../../assets/images/login-flow-images/location-satelite-icon.png"
                        (click)="switchLayer('satellite-v9')" *ngIf="layerIsStreet" style="cursor: pointer;">
                    <img src="./../../../../assets/images/login-flow-images/location-street-icon.png"
                        (click)="switchLayer('streets-v11')" *ngIf="!layerIsStreet" style="cursor: pointer;">
                </div>
                <!-- <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1735769.855425726!2d77.29539!3d31.8202134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1650607698349!5m2!1sen!2sin"
                    width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe> -->
            </div>
            <!-- <div class="search-bar">
                <input type="search" placeholder="Search Loctions">
                <img src="./../../../../assets/images/login-flow-images/Vector (5).png" class="img-fluid icon-pos">
            </div> -->
            <!-- <div class="search_location position-absolute d-flex align-items-center justify-content-between">
                <input class="form-control" type="text" name="" id="" placeholder="Enter Location Name">
                <button class="common-btn" type="button" (click)="addLocation()">Submit</button>
            </div> -->
        </div>
    </div>
</div>
<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side"><img src="./../../../../assets/images/login-flow-images/fe0W2I.tif_1_.png"
                    class="pe-2">
                Automatic order details
            </div>
            <div class="cross-side"><a href="javascript:void(0)" class="btn-close" (click)="closeModal()"><img
                        src="./../../../../assets/images/login-flow-images/Group 791063.png"></a></div>
        </div>

        <div class="p-20 popup-input popup-input-date p-l-30">
            <h5 class="locatinn-h">Vehicles Filled</h5>
        </div>
        <div class="table-responsive popup-table table-height">
            <table class="table mb-0">
                <thead class="table-dark table-light">
                    <tr>
                        <th class="p-l-30">Plate Number </th>
                        <th>Vehicle Details </th>
                        <th>Fuel Type </th>
                    </tr>
                </thead>
                <tbody class="table-td">
                    <tr *ngFor="let item of  previousBooking; let i = index">
                        <td class="p-l-30">{{item.vehicles?.vehicleId?.licensePlate ?
                            item.vehicles?.vehicleId?.licensePlate : '-'}}</td>
                        <td> {{item.vehicles?.vehicleId?.make ||
                            item.vehicles?.vehicleId?.make}} -
                            {{item.vehicles?.vehicleId?.model ||
                            item.vehicles?.vehicleId?.model}} </td>
                        <td>{{item.vehicles?.gasId?.name}} {{item.vehicles?.gasId?.grade ? item.vehicles?.gasId?.grade
                            :''}}</td>
                    </tr>
                    <tr *ngIf="!previousBooking?.length">
                        <td colspan="3" class="no-found">
                            <span>No vehicles filled found</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  private routeSubscription: Subscription;
  public welcomeName: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((params) => {
      if (JSON.stringify(params) !== JSON.stringify({})) {
        this.welcomeName = (params || {}).companyName || '';
      }
    });
  }

  navigateToDashboard = (): void => {
    this.router.navigate(['/dashboard']);
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  navigateToLogin = (): void => {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}

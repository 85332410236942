import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';

declare var google: any;

@Component({
  selector: 'app-add-new-card',
  templateUrl: './add-new-card.component.html',
  styleUrls: ['./add-new-card.component.css']
})
export class AddNewCardComponent implements OnInit, OnDestroy {

  public cardForm: FormGroup = new FormGroup({
    cardNumber: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]),
    cardHolderName: new FormControl('', [Validators.required]),
    expireMonth: new FormControl('', [Validators.required]),
    expireYear: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required])
  });
  private cardFormSubscription: Subscription;
  public showError: boolean = false;
  public monthList: { name: string, value: string }[] = [
    { name: 'January', value: "01" },
    { name: 'February', value: "02" },
    { name: 'March', value: "03" },
    { name: 'April', value: "04" },
    { name: 'May', value: "05" },
    { name: 'June', value: "06" },
    { name: 'July', value: "07" },
    { name: 'August', value: "08" },
    { name: 'September', value: "09" },
    { name: 'October', value: "10" },
    { name: 'November', value: "11" },
    { name: 'December', value: "12" }
  ];
  public yearList: any[] = [];
  private cardRegex: { type: string, regex: any }[] = [
    { type: "VISA", regex: /^4[0-9]{6,}$/ },
    { type: "MASTER", regex: /^5[1-5][0-9]{5,}$/ },
    { type: "AMEX", regex: /^3[47][0-9]{13}$/ },
    { type: "DISCOVER", regex: /^6(?:011|5[0-9]{2})[0-9]{12}$/ }
  ];

  public onClose: Subject<{}>;

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    for (var i = (new Date().getFullYear()); i < ((new Date().getFullYear()) + 25); i++) {
      this.yearList.push(i);
    }
    this.onClose = new Subject();
    this.cardFormSubscription = this.cardForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
  }

  private testPattern = (num: any) => {
    let type = '';
    if (num) {
      this.cardRegex.forEach((col) => {
        const regexs = col.regex;
        const status = regexs.test(num);
        if (!!status) {
          type = col.type;
        }
      });
    }
    return type;
  }

  addCard = (): void => {
    if (this.cardForm.valid) {
      if (parseInt(this.cardForm.get('expireYear')?.value) == (new Date().getFullYear()) &&
        parseInt(this.cardForm.get('expireMonth')?.value) < ((new Date().getMonth()) + 1)) {
        this.messageService.alert('error', 'Please select valid expiry Month or Year.');
      } else {
        this.loaderService.showLoader();
        const postData: any = this.cardForm.getRawValue();
        postData['cardType'] = this.testPattern(postData.cardNumber);
        this.httpService.postDataFormData(ApiUrl.ADD_CARD, postData).subscribe((response) => {
          this.loaderService.hideLoader();
          if (response.statusCode == ResponseStatusCode.OK) {
            this.messageService.alert('success', response.message, '');
            this.onClose.next({ success: true });
            this.closeModal();
          }
        }, (error) => {
          this.loaderService.hideLoader();
          if (error.statusCode == ResponseStatusCode.UNAUTHORIZED) {
            this.closeModal();
          }
          this.utilityService.unAuthorized(error);
        });
      }
    } else {
      this.showError = true;
    }
  }

  closeModal = (): void => {
    this._bsModalRef.hide();
  }

  ngOnDestroy(): void {
    if (this.cardFormSubscription) {
      this.cardFormSubscription.unsubscribe();
    }
  }

  address = (data: any): void => {
    this.cardForm.patchValue({
      address: (data || {}).formatted_address_second || '',// (data || {}).formatted_address || '',
      country: (data || {}).country || '',
      state: (data || {}).admin_area_l1 || '',
      city: (data || {}).locality || '',
      zipCode: (data || {}).postal_code || ''
    });
  }

  getCurrentPosition = (): void => {
    const reference = this;
    navigator.geolocation.getCurrentPosition((pos) => {
      const currentLocation = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
      const geocoder = new google.maps.Geocoder();
      const location_obj: any = {};
      geocoder.geocode({ 'latLng': currentLocation }, (results: any, status: any) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            for (var i = 0; i < results[0].address_components.length; i++) {
              for (var b = 0; b < results[0].address_components[i].types.length; b++) {
                if (results[0].address_components[i].types.indexOf('locality') > -1) {
                  location_obj['locality'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('administrative_area_level_1') > -1) {
                  location_obj['admin_area_l1'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('street_number') > -1) {
                  location_obj['street_number'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('route') > -1) {
                  location_obj['route'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('country') > -1) {
                  location_obj['country'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('postal_code') > -1) {
                  location_obj['postal_code'] = results[0].address_components[i]['long_name'];
                }
                location_obj['formatted_address'] = results[0].formatted_address;
              }
            }
          }
          reference.cardForm.patchValue({
            address: (location_obj || {}).formatted_address || '',
            country: (location_obj || {}).country || '',
            state: (location_obj || {}).admin_area_l1 || '',
            city: (location_obj || {}).locality || '',
            zipCode: (location_obj || {}).postal_code || ''
          });
        }
      });
    }, (error) => {
      console.log('Unable to get location: ' + error.message);
    });
  }

}

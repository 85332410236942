<div class="edit-modal">
    <div class="modal-body">
        <div class="p-20 popup-input">
            <form>

                <img src="./../../../../assets/images/login-flow-images/Ellipse-34151.png"
                    class="img-fluid mx-auto d-block mb-4">
                <h2 class="delet-card mb-3">Your order({{data?.bookingNo}}) has been placed. <br>
                    You will be notified when a
                    driver is on the way.
                </h2>

                <div class="row">
                    <div class="col-md-12">
                        <button type="button" class="btn btn-default w-75 mx-auto d-block yes-btn clr-purp mt-3"
                            (click)="closeModal()">OK</button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
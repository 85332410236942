<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side"><img src="./../../../../assets/images/login-flow-images/Vector (3).png" class="pe-2">
                Reset Password</div>
            <div class="cross-side"><a href="javascript:void(0)" class="btn-close" (click)="closeModal()"><img
                        src="./../../../../assets/images/login-flow-images/Group 791063.png"></a></div>
        </div>

        <div class="p-30 popup-input">
            <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
                <div class="mb-3">
                    <label for="pwd">Current Password</label>
                    <div class="position-relative">
                        <input id="old-password-field" type="{{oldPasswordType}}" class="form-control" name="oldPassword"
                            placeholder="Enter your Current Password" autocomplete="off" formControlName="oldPassword">
                        <span toggle="#old-password-field" class="{{oldPasswordTypeClass}}" 
                        (click)="changePasswordType(1)" style="cursor: pointer;"></span>
                    </div>
                    <span class="show-input-error"
                    *ngIf="resetPasswordForm.controls['oldPassword'].hasError('required') && showError">Please enter your
                    Current Password</span>
                </div>


                <div class="mb-3">
                    <label for="pwd">New Password</label>
                    <div class="position-relative">
                        <input id="new-password-field" type="{{newPasswordType}}" class="form-control" name="newPassword" 
                        autocomplete="off" formControlName="newPassword" placeholder="Enter your New Password">
                        <span toggle="#new-password-field" class="{{newPasswordTypeClass}}"
                        (click)="changePasswordType(2)" style="cursor: pointer;"></span>
                    </div>
                    <span class="show-input-error"
                    *ngIf="resetPasswordForm.controls['newPassword'].hasError('required') && showError">Please enter your
                    New Password</span>
                <span class="show-input-error" *ngIf="!resetPasswordForm.controls['newPassword'].hasError('required') 
                && resetPasswordForm.controls['newPassword'].hasError('minlength') && showError">
                Password length must be at least 6 characters long</span>
                </div>

                <div class="mb-4 pb-2">
                    <label for="pwd">Confirm New Password</label>
                    <div class="position-relative">
                        <input id="conform-new-password-field" type="{{confirmNewPasswordType}}" class="form-control" name="confirmNewPassword" 
                        placeholder="Enter your Confirm New Password" autocomplete="off" formControlName="confirmNewPassword">
                        <span toggle="#conform-new-password-field" class="{{confirmNewPasswordTypeClass}}"
                        (click)="changePasswordType(3)" style="cursor: pointer;"></span>
                    </div>
                    <span class="show-input-error"
                    *ngIf="resetPasswordForm.controls['confirmNewPassword'].hasError('required') && showError">Please enter your
                    Confirm New Password</span>
                <span class="show-input-error" *ngIf="!resetPasswordForm.controls['confirmNewPassword'].hasError('required') 
                && (resetPasswordForm.controls['confirmNewPassword'].value != resetPasswordForm.controls['newPassword'].value) && showError">
                Confirm New Password should be same as New Password</span>
                </div>

                <button type="submit"
                    class="btn btn-default btn-hover color-1 text-uppercase mt-3 w-75 mx-auto d-block ">Update</button>
            </form>
        </div>

    </div>
</div>
import { Directive, ElementRef, OnInit, Output, EventEmitter, NgZone } from '@angular/core';

declare var google: any;

@Directive({
  selector: '[appGooglePlace]'
})
export class GooglePlaceDirective implements OnInit {

  @Output() selectedData: EventEmitter<any> = new EventEmitter();
  private element: HTMLInputElement;

  constructor(
    elRef: ElementRef,
    private _ngZone: NgZone
  ) {
    // elRef will get a reference to the element where
    // the directive is placed
    this.element = elRef.nativeElement;
  }

  getFormattedAddress(place: any) {
    const location_obj: any = {};
    for (const i of Object.keys(place.address_components)) {
      const item = place.address_components[i];
      location_obj['formatted_address'] = place.formatted_address;
      if (item['types'].indexOf('locality') > -1) {
        location_obj['locality'] = item['long_name'];
      } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
        location_obj['admin_area_l1'] = item['short_name'];
      } else if (item['types'].indexOf('street_number') > -1) {
        location_obj['street_number'] = item['short_name'];
      } else if (item['types'].indexOf('route') > -1) {
        location_obj['route'] = item['long_name'];
      } else if (item['types'].indexOf('country') > -1) {
        location_obj['country'] = item['long_name'];
      } else if (item['types'].indexOf('postal_code') > -1) {
        location_obj['postal_code'] = item['short_name'];
      }
    }
    location_obj['name'] = place.name;
    location_obj['lat'] = place.geometry.location.lat();
    location_obj['lng'] = place.geometry.location.lng();

    // location_obj['formatted_address_second'] = place.name + ', ' + place.formatted_address;
    location_obj['formatted_address_second'] = this.element.value || '';

    return location_obj;
  }

  ngOnInit(): void {
    const autocomplete = new google.maps.places.Autocomplete(this.element);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this._ngZone.run(() => {
        this.selectedData.emit(this.getFormattedAddress(autocomplete.getPlace()));
      });
    });
  }

  geocoderLatLng(lat: any, lng: any) {
    return new Promise((res, rej) => {
      const latlng = {
        lat: lat, // 37.787993799999995,
        lng: lng// -122.40743739999999,
      };
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: latlng }).then((response: any) => {
        if (((response || {}).results || []).length) {
          res(this.getFormattedAddress(response.results[0]));
        }
      }).catch((e: any) => res(''));
    });
  }

}

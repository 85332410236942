<header class="login-bg ">
    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <!-- <img
                    src="./../../../assets/images/login-flow-images/logo-with-title-purple.svg"> -->
                    <i class="">
                        <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                        <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px" class="logotext"/>
                    </i>
                </a>
            <div class="login-cover">
                <img src="./../.../../../../assets/images/login-flow-images/Ellipse-34151.png"
                    class="img-fluid mx-auto d-block pb-47">
                <h4 class="login-head pb-3">Welcome {{welcomeName}},</h4>
                <!-- <p class="verify-acc">Congratulations! Your account has been verified.</p> -->
                <p class="verify-acc" style="padding-bottom: 0px !important;">Congratulations! Your account sign up has been completed.</p>
                <p class="verify-acc">GIU Operations team will be verify your account details within two working day's to activate your account.</p>
                <form>
                    <!-- <button type="button" class="btn btn-default btn-hover color-1" (click)="navigateToLogin()">Get Started</button> -->
                    <button type="button" class="btn btn-default btn-hover color-1" (click)="navigateToLogin()">Close</button>
                </form>
            </div>
        </div>
    </nav>
</header>
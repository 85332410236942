import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { PaginationControlsOptions } from 'src/app/models/pagination';

@Component({
  selector: 'app-trucks-and-costs-inspections-logs',
  templateUrl: './trucks-and-costs-inspections-logs.component.html',
  styleUrls: ['./trucks-and-costs-inspections-logs.component.css']
})
export class TrucksAndCostsInspectionsLogsComponent implements OnInit {

  public paginationControlsOptions: PaginationControlsOptions = new PaginationControlsOptions();

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions
  ) { }

  ngOnInit(): void {
    this.paginationControlsOptions.page = 1;
    this.paginationControlsOptions.limit = 5;
    this.paginationControlsOptions.skip = 0;
    this.paginationControlsOptions.total = 0;
    this.paginationControlsOptions.data = [];
    if (this.options.initialState) {
      const tempData: any = this.options.initialState;
      this.paginationControlsOptions.data = tempData['data'] || [];
      this.paginationControlsOptions.total = this.paginationControlsOptions.data.length || 0;
    }
  }

  getPage = (page: number): void => {
    this.paginationControlsOptions.page = page;
  }

  closeModal = (): void => {
    this._bsModalRef.hide();
  }

}

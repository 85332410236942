<header class="login-bg ">
    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <i class="">
                    <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                    <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px"
                        class="logotext" />
                </i>
            </a>
            <div class="login-cover">
                <h4 class="login-head pb-2">Verify OTP</h4>
                <p class="veryfy-otp pb-57">Enter OTP below to continue</p>
                <form [formGroup]="verificationOTPForm" (ngSubmit)="verificationOTP()">
                    <div class="mb-3">
                        <label for="email">Enter OTP </label>
                        <input type="text" class="form-control" id="otp" placeholder="Enter your OTP" name="otp"
                            autocomplete="off" formControlName="otp">
                        <span class="show-input-error"
                            *ngIf="verificationOTPForm.controls['otp'].hasError('required') && showError">Please enter
                            your OTP</span>
                        <span class="show-input-error" *ngIf="!verificationOTPForm.controls['otp'].hasError('required') 
                        && verificationOTPForm.controls['otp'].hasError('minlength') && showError">OTP length must be
                            of 4 characters</span>
                    </div>
                    <button type="submit" class="btn btn-default btn-hover color-1 mb-3 ">Verify</button>
                </form>
            </div>
        </div>
    </nav>
</header>
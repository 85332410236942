<nav class="navbar navbar-expand-lg navbar-dark fixed-top py-lg-1 py-3" id="mainNav">
    <div class="container-fluid">
        <a class="site-logo d-lg-none align-items-center" href="javascript:void(0)">
            <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" alt="Site Logo">
            <img src="./../../../assets/images/login-flow-images/guilogotext.svg" alt="Site Logo">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon">
                <i class="fa-solid fa-bars"></i>
            </span>
        </button>
    </div>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="sidebar-outer">
            <a class="site-logo position-absolute d-lg-block d-none" href="javascript:void(0)">
                <!-- <img src="./../../../assets/images/login-flow-images/logo-with-title-white.svg" alt="Site Logo"> -->
                <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" alt="Site Logo" width="50px">
                <img src="./../../../assets/images/login-flow-images/guilogotext.svg" alt="Site Logo" width="140px">
            </a>
            <ul class="navbar-nav navbar-sidenav" id="exampleAccordion">


                <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 1}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/dashboard')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/dashboard.svg" alt="">
                        </i>
                        <span class="nav-link-text">Dashboard</span>
                    </a>
                </li>
                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 11}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/delivery-locations')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/delivery-location-maker-blue.svg"
                                alt="">
                        </i>
                        <span class="nav-link-text">Delivery Locations</span>
                    </a>
                </li>
                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 21}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/manage-vehicles')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/car.svg" alt="">
                        </i>
                        <span class="nav-link-text">Manage fleet</span>
                    </a>
                </li>

                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 121}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/route-optimization')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/route_optimize.svg" alt="">
                        </i>
                        <span class="nav-link-text">Route Optimization</span>
                    </a>
                </li>

                <li class="nav-item" data-placement="right">
                    <a class="nav-link nav-link-collapse" (click)="onClickCollapse('ORDERS')"
                        [ngClass]="{'collapsed': !isShowOrders}">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/orders-dropdown.svg" alt="">
                        </i>
                        <span class="nav-link-text">Orders Management</span>
                    </a>
                    <ul class="sidenav-second-level collapse" [ngClass]="{'show': isShowOrders}">
                        <li [ngClass]="{'active' : sidebarType== 31}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/orders')">
                                <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/petrol.svg" alt="">
                                </i>
                                <span class="nav-link-text show-reports">Orders</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 41}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/automatic-orders')">
                                <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                                </i>
                                <span class="nav-link-text show-reports">Non-Fueled</span>
                            </a>
                        </li>
                    </ul>
                </li>


                <li class="nav-item" data-placement="right">
                    <a class="nav-link nav-link-collapse" (click)="onClickCollapse('REPORTS')"
                        [ngClass]="{'collapsed': !isShowReports}">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/edit.svg" alt="">
                        </i>
                        <span class="nav-link-text">Reports</span>
                    </a>
                    <ul class="sidenav-second-level collapse" [ngClass]="{'show': isShowReports}">
                        <li [ngClass]="{'active' : sidebarType== 71}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/detailed-reports')">
                                <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/edit.svg" alt="">
                                </i>
                                <span class="nav-link-text show-reports">Detailed Reports</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 91}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/custom-reports')">
                                <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/edit.svg" alt="">
                                </i>
                                <span class="nav-link-text show-reports">Custom Reports</span>
                            </a>
                        </li>
                    </ul>
                </li> -->
               <!-- dashboard-->
               <li class="nav-item" data-placement="right">
                <a class="nav-link nav-link-collapse" (click)="onClickCollapse('DASHBOARD')"
                    [ngClass]="{'collapsed': !isShowdashboarddata}">
                    <i class="d-icon">
                        <img src="./../../../assets/images/login-flow-images/orders-dropdown.svg" alt="">
                    </i>
                    <span class="nav-link-text">Dashboard</span>
                </a>
                <ul class="sidenav-second-level collapse" [ngClass]="{'show': isShowdashboarddata}">
                    <!-- <li [ngClass]="{'active' : sidebarType== 135}">
                        <a class="nav-link" href="javascript:void(0)" (click)="navigate('/dashboard-dashboard-overview')">
                          
                            <span class="nav-link-text show-reports">Dashboard Review</span>
                        </a>
                    </li> -->
                    <li [ngClass]="{'active' : sidebarType== 199}">
                        <a class="nav-link" href="javascript:void(0)" (click)="navigate('/dashboard/dashboard-overview')">
                            <!-- <i class="d-icon">
                                <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                            </i> -->
                            <span class="nav-link-text show-reports">Overview</span>
                        </a>
                    </li>
                    <li [ngClass]="{'active' : sidebarType== 136}">
                        <a class="nav-link" href="javascript:void(0)" (click)="navigate('/dashboard-revenue-generated')">
                            <!-- <i class="d-icon">
                                <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                            </i> -->
                            <span class="nav-link-text show-reports">Revenue Generated</span>
                        </a>
                    </li>
                    <li [ngClass]="{'active' : sidebarType== 99}">
                        <a class="nav-link" href="javascript:void(0)" (click)="navigate('/dashboard-subscription')">
                            <!-- <i class="d-icon">
                                <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                            </i> -->
                            <span class="nav-link-text show-reports">Subscriptions</span>
                        </a>
                    </li>
                </ul>
            </li>

            <!--fuel master-->
            <li class="nav-item" data-placement="right">
                <a class="nav-link nav-link-collapse" (click)="onClickCollapse('FUELMASTER')"
                    [ngClass]="{'collapsed': !isShowdFUELdata}">
                    <i class="d-icon">
                        <img src="./../../../assets/images/login-flow-images/orders-dropdown.svg" alt="">
                    </i>
                    <span class="nav-link-text">Fuel Master</span>
                </a>
                <ul class="sidenav-second-level collapse" [ngClass]="{'show': isShowdFUELdata}">
                    <!-- <li [ngClass]="{'active' : sidebarType== 135}">
                        <a class="nav-link" href="javascript:void(0)" (click)="navigate('/dashboard-dashboard-overview')">
                          
                            <span class="nav-link-text show-reports">Dashboard Review</span>
                        </a>
                    </li> -->
                    <li [ngClass]="{'active' : sidebarType== 150}">
                        <a class="nav-link" href="javascript:void(0)" (click)="navigate('/fuel-master/price-master')">
                            <!-- <i class="d-icon">
                                <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                            </i> -->
                            <span class="nav-link-text show-reports">Price Master</span>
                        </a>
                    </li>
                    <li [ngClass]="{'active' : sidebarType== 151}">
                        <a class="nav-link" href="javascript:void(0)" (click)="navigate('/fuel-master/analytics-master')">
                            <!-- <i class="d-icon">
                                <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                            </i> -->
                            <span class="nav-link-text show-reports">Analytics Master</span>
                        </a>
                    </li>
                    <li [ngClass]="{'active' : sidebarType== 152}">
                        <a class="nav-link" href="javascript:void(0)" (click)="navigate('/fuel-master/margin-master')">
                            <!-- <i class="d-icon">
                                <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                            </i> -->
                            <span class="nav-link-text show-reports">Margin Master</span>
                        </a>
                    </li>
                    <li [ngClass]="{'active' : sidebarType== 153}">
                        <a class="nav-link" href="javascript:void(0)" (click)="navigate('/fuel-master/market-master')">
                            <!-- <i class="d-icon">
                                <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                            </i> -->
                            <span class="nav-link-text show-reports">Market Master</span>
                        </a>
                    </li>
                    <li [ngClass]="{'active' : sidebarType== 154}">
                        <a class="nav-link" href="javascript:void(0)" (click)="navigate('/fuel-master/demand-master')">
                            <!-- <i class="d-icon">
                                <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                            </i> -->
                            <span class="nav-link-text show-reports">Demand Master</span>
                        </a>
                    </li>
                    
                    
                    
                </ul>
            </li> 
            <!--subscription master-->
                
                <li class="nav-item" data-placement="right">
                    <a class="nav-link nav-link-collapse" (click)="onClickCollapse('SUBSCRIPTION')"
                        [ngClass]="{'collapsed': !isShowOrders}">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/orders-dropdown.svg" alt="">
                        </i>
                        <span class="nav-link-text">Subscriptions</span>
                    </a>
                    <ul class="sidenav-second-level collapse" [ngClass]="{'show': isShowOrders}">
                        <li [ngClass]="{'active' : sidebarType== 31}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/subscription')">
                                <!-- <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/petrol.svg" alt="">
                                </i> -->
                                <span class="nav-link-text show-reports">Subscriptions Plans</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 41}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/subscription-report')">
                                <!-- <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                                </i> -->
                                <span class="nav-link-text show-reports">Subscriptions Report</span>
                            </a>
                        </li>
                    </ul>
                </li>

                   <!-- business-->
                   <li class="nav-item" data-placement="right">
                    <a class="nav-link nav-link-collapse" (click)="onClickCollapse('BUSINESS')"
                        [ngClass]="{'collapsed': !isShowOrderbusinessdata}">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/orders-dropdown.svg" alt="">
                        </i>
                        <span class="nav-link-text">Business</span>
                    </a>
                    <ul class="sidenav-second-level collapse" [ngClass]="{'show': isShowOrderbusinessdata}">
                        <li [ngClass]="{'active' : sidebarType== 111}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/business-business-detail')">
                                <!-- <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/petrol.svg" alt="">
                                </i> -->
                                <span class="nav-link-text show-reports">Business Details</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 112}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/business-business-report')">
                                <!-- <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                                </i> -->
                                <span class="nav-link-text show-reports">Business Report</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!--DtN-->
                <li class="nav-item" data-placement="right">
                    <a class="nav-link nav-link-collapse" (click)="onClickCollapse('TAXES')"
                        [ngClass]="{'collapsed': !isShowOrder}">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/orders-dropdown.svg" alt="">
                        </i>
                        <span class="nav-link-text">DTN</span>
                    </a>
                    <ul class="sidenav-second-level collapse" [ngClass]="{'show': isShowOrder}">
                        <li [ngClass]="{'active' : sidebarType== 110}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/dtn-taxes')">
                                <!-- <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/petrol.svg" alt="">
                                </i> -->
                                <span class="nav-link-text show-reports">Taxes</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 102}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/dtn-product-code')">
                                <!-- <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                                </i> -->
                                <span class="nav-link-text show-reports">Product Code Season</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 103}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/dtn-blend-month')">
                                <!-- <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                                </i> -->
                                <span class="nav-link-text show-reports">Blend Month Season</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 104}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/dtn-terminal-detail')">
                                <!-- <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                                </i> -->
                                <span class="nav-link-text show-reports">Terminal Details</span>
                            </a>
                        </li>
                    </ul>
                </li>

                    <!--MetaData-->
                    <li class="nav-item" data-placement="right">
                        <a class="nav-link nav-link-collapse" (click)="onClickCollapse('METADATA')"
                            [ngClass]="{'collapsed': !isShowOrdermetadata}">
                            <i class="d-icon">
                                <img src="./../../../assets/images/login-flow-images/orders-dropdown.svg" alt="">
                            </i>
                            <span class="nav-link-text">Metadata</span>
                        </a>
                        <ul class="sidenav-second-level collapse" [ngClass]="{'show': isShowOrdermetadata}">
                            <li [ngClass]="{'active' : sidebarType== 101}">
                                <a class="nav-link" href="javascript:void(0)" (click)="navigate('/business-type')">
                                    <!-- <i class="d-icon">
                                        <img src="./../../../assets/images/login-flow-images/petrol.svg" alt="">
                                    </i> -->
                                    <span class="nav-link-text show-reports">Business Type</span>
                                </a>
                            </li>
                            <li [ngClass]="{'active' : sidebarType== 105}">
                                <a class="nav-link" href="javascript:void(0)" (click)="navigate('/invoice-template')">
                                    <!-- <i class="d-icon">
                                        <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                                    </i> -->
                                    <span class="nav-link-text show-reports">Invoice Template</span>
                                </a>
                            </li>
                            <li [ngClass]="{'active' : sidebarType== 106}">
                                <a class="nav-link" href="javascript:void(0)" (click)="navigate('/meta-vehicles')">
                                    <!-- <i class="d-icon">
                                        <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                                    </i> -->
                                    <span class="nav-link-text show-reports">Vehicles</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                <!-- Transcations -->

                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                [ngClass]="{'active' : sidebarType== 786}">
                <a class="nav-link" href="javascript:void(0)" (click)="navigate('/transactions')">
                    <i class="d-icon">
                        <img src="./../../../assets/images/login-flow-images/dashboard.svg" alt="">
                    </i>
                    <span class="nav-link-text">Transactions</span>
                </a>
            </li>
                <!-- Admin Defaults -->
                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 131}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/admin-defaults')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/dashboard.svg" alt="">
                        </i>
                        <span class="nav-link-text">Admin Defaults</span>
                    </a>
                </li>

                <!--chat support-->
                 <li class="nav-item" data-placement="right">
                    <a class="nav-link nav-link-collapse" (click)="onClickCollapse('CHAT')"
                        [ngClass]="{'collapsed': !isShowCHATdata}">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/orders-dropdown.svg" alt="">
                        </i>
                        <span class="nav-link-text">Support</span>
                    </a>
                    <ul class="sidenav-second-level collapse" [ngClass]="{'show': isShowCHATdata}">
                        <li [ngClass]="{'active' : sidebarType== 140}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/support/chat-report')">
                                <!-- <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/petrol.svg" alt="">
                                </i> -->
                                <span class="nav-link-text show-reports">Chat Report</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <!-- Reports-->
                <li class="nav-item" data-placement="right">
                    <a class="nav-link nav-link-collapse" (click)="onClickCollapse('REPORTS')"
                        [ngClass]="{'collapsed': !isShowREPORTdata}">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/orders-dropdown.svg" alt="">
                        </i>
                        <span class="nav-link-text">Reports</span>
                    </a>
                    <ul class="sidenav-second-level collapse" [ngClass]="{'show': isShowREPORTdata}">
                        <li [ngClass]="{'active' : sidebarType== 160}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/reports/financial-report')">
                                <!-- <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/petrol.svg" alt="">
                                </i> -->
                                <span class="nav-link-text show-reports">Financial Report</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 161}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/reports/marketing-report')">
                               
                                <span class="nav-link-text show-reports">Marketing Report</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 162}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/reports/feedback-report')">
                               
                                <span class="nav-link-text show-reports">Feedback Report</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 163}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/reports/revenue-report')">
                               
                                <span class="nav-link-text show-reports">Revenue Report</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 164}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/reports/price-model-report')">
                               
                                <span class="nav-link-text show-reports">Pricing Model QA</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 165}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/reports/churn-rate-report')">
                               
                                <span class="nav-link-text show-reports">Churn Rate</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>

        </div>
    </div>
</nav>
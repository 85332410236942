import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  public vehicleList: any[] = [];
  public address: string = '';

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    public utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.vehicleList = [];
    if (this.options.initialState) {
      const tempData: any = this.options.initialState;
      this.vehicleList = tempData['vehicles'] || [];
      this.address = tempData['address'] || '';
    }
  }


  closeModal = (): void => {
    this._bsModalRef.hide();
  }

  getToFixed(orderAmountTotal: any) {
    return parseFloat(orderAmountTotal).toFixed(2);
  }

}

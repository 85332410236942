<header class="login-bg ">
    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <!-- <img
                    src="./../../../assets/images/login-flow-images/logo-with-title-purple.svg"> -->
                <i class="">
                    <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                    <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px"
                        class="logotext" />
                </i>
            </a>

            <div class="payment-cover">
                <!-- <div class="row"> -->
                <div class="col-75">
                    <!-- <div class="container"> -->
                    <form action="/action_page.php">

                        <div class="row">
                            <div class="col-50">
                                <h3>Billing Address</h3>
                                <br>
                                <label for="fname"><i class="fa fa-user"></i> Full Name</label>
                                <input type="text" id="fname" name="firstname" placeholder="John M. Doe"
                                    [(ngModel)]="businessContactPersonFullName">
                                <label for="email"><i class="fa fa-envelope"></i> Email</label>
                                <input type="text" id="email" name="email" placeholder="john@example.com"
                                    [(ngModel)]="allpostdata.businessEmail">
                                <label for="adr"><i class="fa fa-address-card-o"></i> Address</label>
                                <input type="text" id="adr" name="address" placeholder="542 W. 15th Street"
                                    [(ngModel)]="allpostdata.bussinessAddress1">
                                <label for="city"><i class="fa fa-institution"></i> City</label>
                                <input type="text" id="city" name="city" placeholder="New York"
                                    [(ngModel)]="allpostdata.bussinessCity">

                                <div class="row">
                                    <div class="col-50">
                                        <label for="state">State</label>
                                        <input type="text" id="state" name="state" placeholder="NY"
                                            [(ngModel)]="allpostdata.bussinessState">
                                    </div>
                                    <div class="col-50">
                                        <label for="zip">Zip</label>
                                        <input type="text" id="zip" name="zip" placeholder="10001"
                                            [(ngModel)]="allpostdata.bussinessZipCode">
                                    </div>
                                </div>
                            </div>

                            <div class="col-50">
                                <h3>Payment</h3><br>
                                <label for="fname">Accepted Cards</label>
                                <div class="icon-container">
                                    <i class="fa fa-cc-visa p-1" style="color:navy;"></i>
                                    <i class="fa fa-cc-amex p-1" style="color:blue;"></i>
                                    <i class="fa fa-cc-mastercard p-1" style="color:red;"></i>
                                    <i class="fa fa-cc-discover" style="color:orange;"></i>
                                </div>
                                <label for="cname">Name on Card</label>
                                <input type="text" id="cname" name="cardname" placeholder="John More Doe"
                                    [(ngModel)]="carName">
                                <label for="ccnum">Credit card number</label>
                                <input type="password" id="ccnum" name="cardnumber" placeholder="********4444"
                                    autocomplete="off" [(ngModel)]="cardNumber">
                                <label for="expmonth">Exp Month</label>
                                <input type="text" id="expmonth" name="expmonth" placeholder="September"
                                    [(ngModel)]="cardExpiryMonth">
                                <div class="row">
                                    <div class="col-50">
                                        <label for="expyear">Exp Year</label>
                                        <input type="text" id="expyear" name="expyear" placeholder="2024"
                                            [(ngModel)]="cardExpiryYear">
                                    </div>
                                    <div class="col-50">
                                        <label for="cvv">CVV</label>
                                        <input type="text" id="cvv" name="cvv" placeholder="***" [(ngModel)]="cvv">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <input type="submit" (click)="onSubmit()" value="Pay Now" class="btn">
                    </form>
                    <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </nav>
</header>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
    window.addEventListener('storage', (event) => {
      if (event.storageArea != localStorage) {
        return;
      }
      if (event.key === 'accessToken') {
        location.reload();
      }
    });
  }

}

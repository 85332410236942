import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-notification-driver-rating',
  templateUrl: './notification-driver-rating.component.html',
  styleUrls: ['./notification-driver-rating.component.css']
})
export class NotificationDriverRatingComponent implements OnInit {

  public onClose: Subject<{}>;
  public notificationDetails: any = null;
  public selectedRating: number = 0;

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private utilityService: UtilityService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.notificationDetails = null;
    if (this.options.initialState) {
      const tempData: any = this.options.initialState;
      this.notificationDetails = tempData['notificationDetails'] || null;
    }
  }

  rateDriver = (): void => {
    if (this.selectedRating) {
      this.loaderService.showLoader();
      const postData = {
        bookingNo: (this.notificationDetails || {}).bookingNo || '',
        driverId: (this.notificationDetails.driverId || {})._id || '',
        rating: this.selectedRating
      };
      this.httpService.postDataFormData(ApiUrl.RATE_DRIVER, postData).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK) {
          this.messageService.alert('success', response.message, '');
          this.closeModalWithYes();
        }
      }, (error) => {
        this.loaderService.hideLoader();
        this.utilityService.unAuthorized(error);
      });
    } else {
      this.messageService.alert('error', 'Rating is required');
    }
  }

  closeModalWithYes = (): void => {
    this.onClose.next({ success: true });
    this._bsModalRef.hide();
  }

  closeModal = (): void => {
    this.onClose.next({ success: false });
    this._bsModalRef.hide();
  }

  onClickRating = (index: number): void => {
    this.selectedRating = index;
  }

}

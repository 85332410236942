import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  resetPasswordForm: FormGroup = new FormGroup({
    oldPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmNewPassword: new FormControl('', [Validators.required])
  });
  private resetPasswordFormSubscription: Subscription;
  showError: boolean = false;

  public oldPasswordType: string = 'password';
  public oldPasswordTypeClass: string = 'fa fa-fw fa-eye field-icon toggle-password fa-eye fa-eye-slash';

  public newPasswordType: string = 'password';
  public newPasswordTypeClass: string = 'fa fa-fw fa-eye field-icon toggle-password fa-eye fa-eye-slash';

  public confirmNewPasswordType: string = 'password';
  public confirmNewPasswordTypeClass: string = 'fa fa-fw fa-eye field-icon toggle-password fa-eye fa-eye-slash';

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private utilityService: UtilityService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.resetPasswordFormSubscription = this.resetPasswordForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
  }

  resetPassword = (): void => {
    if (this.resetPasswordForm.valid &&
      this.resetPasswordForm.controls.newPassword.value == this.resetPasswordForm.controls.confirmNewPassword.value
    ) {
      this.loaderService.showLoader();
      const postData: any = this.resetPasswordForm.getRawValue();
      delete postData.confirmNewPassword;
      this.httpService.postData(ApiUrl.CHANGE_PASSWORD, postData).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK) {
          this.messageService.alert('success', response.message, '');
          this.closeModal();
        }
      }, (error) => {
        this.loaderService.hideLoader();
        this.utilityService.unAuthorized(error);
      });
    } else {
      this.showError = true;
    }
  }

  closeModal = (): void => {
    this._bsModalRef.hide();
  }

  ngOnDestroy(): void {
    if (this.resetPasswordFormSubscription) {
      this.resetPasswordFormSubscription.unsubscribe();
    }
  }

  changePasswordType = (index: number): void => {
    switch (index) {
      case 1:
        if (this.oldPasswordType == 'password') {
          this.oldPasswordType = 'text';
          this.oldPasswordTypeClass = 'fa fa-fw fa-eye field-icon toggle-password';
        } else {
          this.oldPasswordType = 'password';
          this.oldPasswordTypeClass = 'fa fa-fw fa-eye field-icon toggle-password fa-eye fa-eye-slash';
        }
        break;
      case 2:
        if (this.newPasswordType == 'password') {
          this.newPasswordType = 'text';
          this.newPasswordTypeClass = 'fa fa-fw fa-eye field-icon toggle-password';
        } else {
          this.newPasswordType = 'password';
          this.newPasswordTypeClass = 'fa fa-fw fa-eye field-icon toggle-password fa-eye fa-eye-slash';
        }
        break;
      case 3:
        if (this.confirmNewPasswordType == 'password') {
          this.confirmNewPasswordType = 'text';
          this.confirmNewPasswordTypeClass = 'fa fa-fw fa-eye field-icon toggle-password';
        } else {
          this.confirmNewPasswordType = 'password';
          this.confirmNewPasswordTypeClass = 'fa fa-fw fa-eye field-icon toggle-password fa-eye fa-eye-slash';
        }
        break;
      default:
        break;
    }
  }

}

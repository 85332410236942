import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      // return true;
      if (localStorage.getItem('twoStepVerification') == 'true') {
        if (localStorage.getItem('loginDetails')) {
          const loginDetails: any = JSON.parse(localStorage.getItem('loginDetails') || '');
          this.router.navigate(['/two-step-verification', loginDetails]);
        } else {
          localStorage.removeItem('accessToken');
          this.router.navigate(['/login']);
        }
        return false;
      }
      return true;
    }
    this.router.navigate(['/login']);
    return false;

    // return true;
  }

}

<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">Vehicle Details</div>
            <div class="cross-side"><a href="javascript:void(0)" class="btn-close" (click)="closeModal()"><img
                        src="./../../../../assets/images/login-flow-images/Group 791063.png"></a></div>
        </div>

        <div class="p-20 pb-0">
            <h4 class="adress-vehicle">{{data?.locationId?.address || '-'}}
            </h4>
            <div class="row car-views">
                <div class="col-md-4 my-2">
                    <!-- <img src="./../../../../assets/images/login-flow-images/Group 791286.png" class="img-fluid"> -->
                    <img src="{{data?.imageUrl[0]?.original || './../../../../assets/images/login-flow-images/car_placeholder.png'}}"
                        class="img-fluid">
                    <h4 class="front-view">Front View</h4>
                </div>

                <div class="col-md-4 my-2">
                    <!-- <img src="./../../../../assets/images/login-flow-images/Group 791287.png" class="img-fluid"> -->
                    <img src="{{data?.imageUrl[2]?.original || './../../../../assets/images/login-flow-images/car_placeholder.png'}}"
                        class="img-fluid">
                    <h4 class="front-view">Side View</h4>
                </div>

                <div class="col-md-4 my-2">
                    <!-- <img src="./../../../../assets/images/login-flow-images/Group 791288.png" class="img-fluid"> -->
                    <img src="{{data?.imageUrl[2]?.original || './../../../../assets/images/login-flow-images/car_placeholder.png'}}"
                        class="img-fluid">
                    <h4 class="front-view">Back View</h4>
                </div>
            </div>


            <div class="row py-3">
                <div class="col-md-4  text-version">
                    <div class="row m-0 pt-3">
                        <div class="col-md-6">
                            <p>Version</p>
                            <h5>{{data?.version || '-'}}</h5>
                        </div>
                        <div class="col-md-6 text-end">
                            <p>State</p>
                            <h5>{{data?.stateId?.name || '-'}}</h5>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 text-version ">
                    <div class="row m-0 pt-3 ">
                        <div class="col-md-6">
                            <p>Plate Number</p>
                            <h5>{{data?.licensePlate || '-'}}</h5>
                        </div>
                        <div class="col-md-6 text-end">
                            <p>Year</p>
                            <h5>{{data?.year || '-'}}</h5>
                        </div>
                    </div>
                </div>

                <div class="col-md-4  text-version ">
                    <div class="row m-0 pt-3 border-right-0">
                        <div class="col-md-5">
                            <p>Color</p>
                            <h5><span class="red-dot"
                                    [ngStyle]="{background : data?.color?.hexCode}"></span>{{data?.color?.name || '-'}}
                            </h5>
                        </div>
                        <div class="col-md-7 text-end">
                            <p>VIN Number</p>
                            <h5>{{data?.vinNumber || '-'}}</h5>
                        </div>
                    </div>
                </div>

                <div class="col-md-4  text-version ">
                    <div class="row m-0 pt-3 border-bottom-0">
                        <div class="col-md-6">
                            <p>Fuel Type</p>
                            <h5>
                                {{data?.gasId?.name || '-'}} <span
                                    *ngIf="data?.gasId?.grade">{{data?.gasId?.grade}}</span>
                            </h5>
                        </div>
                        <div class="col-md-6 text-end">
                            <p>Fleet Number</p>
                            <h5>{{data?.fleetNumber || '-'}}</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4  text-version ">
                    <div class="row m-0 pt-3 border-bottom-0">
                        <div class="col-md-6">
                            <p>Miles</p>
                            <h5>
                                <span *ngIf="data?.miles">{{data?.miles}}</span>
                                <span *ngIf="!data?.miles">{{data?.defaultMiles}}</span>
                            </h5>
                        </div>
                        <div class="col-md-6 text-end">
                            <p>Other Details</p>
                            <h5 [innerText]="data?.moreDetails" *ngIf="data?.moreDetails !=''">
                            </h5>
                            <h5 *ngIf="data?.moreDetails ==''">-
                            </h5>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    </div>
</div>
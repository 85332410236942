import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-upload-vehicle-info',
  templateUrl: './upload-vehicle-info.component.html',
  styleUrls: ['./upload-vehicle-info.component.css']
})
export class UploadVehicleInfoComponent implements OnInit {

  public onClose: Subject<{}>;

  public locationList: any[] = [];

  public uploadVehicleInfoForm: FormGroup = new FormGroup({
    locationId: new FormControl('', [Validators.required]),
    file: new FormControl('', [Validators.required])
  });
  public uploadVehicleInfoFormSubscription: Subscription;
  public showError: boolean = false;
  public fileName: string = '';

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    if (this.options.initialState) {
      // const tempData: any = this.options.initialState;
      // this.vehicleData = tempData['vehicleData'] || null;
    }
    this.uploadVehicleInfoFormSubscription = this.uploadVehicleInfoForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
    this.getLocations();
  }

  getLocations = (): void => {
    this.locationList = [];
    this.loaderService.showLoader();
    this.httpService.getData(ApiUrl.MANAGED_LOCATIONS, { skip: 0, limit: 0 }).subscribe((response) => {
      this.loaderService.hideLoader();
      if (response.statusCode == ResponseStatusCode.OK) {
        //  this.locationList = (response.data || {}).data || [];
        const tempLocationList = (response.data || {}).data || [];
       // this.locationList = (tempLocationList || []).sort((a: any, b: any) => (a.address > b.address) ? 1 : ((b.address > a.address) ? -1 : 0));
       this.locationList = (tempLocationList || []).sort((a: any, b: any) => (a.locationName > b.locationName) ? 1 : ((b.locationName > a.locationName) ? -1 : 0));
      }
    }, (error) => {
      this.loaderService.hideLoader();
      this.utilityService.unAuthorized(error);
    });
  }

  downloadSample = (): void => {
    this.loaderService.showLoader();
    this.httpService.getDataCSV(ApiUrl.DOWNLOAD_SAMPLE, {}).subscribe((response) => {
      this.loaderService.hideLoader();
      this.utilityService.downloadCSV(response, 'Vehicle-Info-Sample');
      this.messageService.alertWithTimer('success', 'CSV Downloaded Successfully', '');
    }, (error) => {
      this.loaderService.hideLoader();
      this.closeModal();
      this.utilityService.unAuthorized(error);
    });
  }

  uploadVehicleInfo = (): void => {
    if (this.uploadVehicleInfoForm.valid) {
      this.loaderService.showLoader();
      const postData: any = this.uploadVehicleInfoForm.getRawValue();
      this.httpService.postDataFormData(ApiUrl.POST_GET_DATA_OF_CSV, postData).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK) {
          this.onClose.next({ success: true });
          this.closeModal();
          this.messageService.alertWithTimer('success', 'Uploaded Successfully', '');
        }
      }, (error) => {
        this.loaderService.hideLoader();
        this.closeModal();
        this.utilityService.unAuthorized(error);
      });
    } else {
      this.showError = true;
    }
  }

  changeSelectFile(event: any) {
    if (event && event.target.files && event.target) {
      this.fileName = event.target.files[0].name || '';
      this.uploadVehicleInfoForm.patchValue({
        file: event.target.files[0]
      });
    }
  }


  closeModal = (): void => {
    this._bsModalRef.hide();
  }

}

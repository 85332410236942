<div class="edit-modal border-0">
    <div class="modal-body border-0">

        <div class="header-modal p-lg-4 p-3">
            <div class="icon-side">
                <img src="./../../../../assets/images/login-flow-images/Vector (2).png" class="pe-2">
                Add New Card
            </div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="./../../../../assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>

        <div class="p-30 light-blue">
            <form [formGroup]="cardForm">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="cardNumber">Card Number</label>
                        <input type="text" class="form-control" id="cardNumber" placeholder="Enter Card Number"
                            name="cardNumber" appOnlyNumber autocomplete="off" formControlName="cardNumber" />
                        <span class="show-input-error"
                            *ngIf="cardForm.controls['cardNumber'].hasError('required') && showError">Please enter Card
                            Number
                        </span>
                        <span class="show-input-error" *ngIf="!cardForm.controls['cardNumber'].hasError('required') 
                        && cardForm.controls['cardNumber'].hasError('minlength') && showError">
                            Card Number length must be at least 8 characters long
                        </span>
                        <span class="show-input-error" *ngIf="!cardForm.controls['cardNumber'].hasError('required') 
                        && cardForm.controls['cardNumber'].hasError('maxlength') && showError">
                            Card Number length must be at most of 20 characters long only
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="cardHolderName">Cardholder Name</label>
                        <input type="text" class="form-control" id="cardHolderName" placeholder="Enter Cardholder Name"
                            name="cardHolderName" autocomplete="off" formControlName="cardHolderName">
                        <span class="show-input-error"
                            *ngIf="cardForm.controls['cardHolderName'].hasError('required') && showError">Please enter
                            Cardholder Name
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="expireMonth">Month</label>
                        <div class="drop-clr styled-select">
                            <select class="form-select" id="expireMonth" name="expireMonth"
                                formControlName="expireMonth">
                                <option value="" disabled>Select Option</option>
                                <option [value]="data?.value" *ngFor="let data of monthList">
                                    {{data?.name}}
                                </option>
                            </select>
                        </div>
                        <span class="show-input-error"
                            *ngIf="cardForm.controls['expireMonth'].hasError('required') && showError">Please select
                            Month
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="expireYear">Year</label>
                        <div class="drop-clr styled-select">
                            <select class="form-select" id="expireYear" name="expireYear" formControlName="expireYear">
                                <option value="" disabled>Select Option</option>
                                <option [value]="data" *ngFor="let data of yearList">
                                    {{data}}
                                </option>
                            </select>
                        </div>
                        <span class="show-input-error"
                            *ngIf="cardForm.controls['expireYear'].hasError('required') && showError">Please select Year
                        </span>
                    </div>

                    <div class="col-md-12 mb-3  street-address">
                        <label for="address">Address</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" id="address" placeholder="Enter Address"
                                name="address" appGooglePlace (selectedData)="address($event)"
                                formControlName="address">
                            <span class="input-group-text" id="basic-addon1"
                                style="border: 0; background-color: #EFF4F8;cursor: pointer;"
                                (click)="getCurrentPosition()">
                                <i><img src="../../../../assets/images/login-flow-images/location-marker-icon.png"
                                        alt="" width="20px" /></i>
                            </span>
                        </div>
                        <span class="show-input-error"
                            *ngIf="cardForm.controls['address'].hasError('required') && showError">Please enter Address
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="country">Country</label>
                        <input type="text" class="form-control" id="country" placeholder="Enter Country" name="country"
                            autocomplete="off" formControlName="country">
                        <span class="show-input-error"
                            *ngIf="cardForm.controls['country'].hasError('required') && showError">Please enter Country
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="state">State</label>
                        <input type="text" class="form-control" id="state" placeholder="Enter State" name="state"
                            autocomplete="off" formControlName="state">
                        <span class="show-input-error"
                            *ngIf="cardForm.controls['state'].hasError('required') && showError">Please enter State
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="city">City</label>
                        <input type="text" class="form-control" id="city" placeholder="Enter City" autocomplete="off"
                            formControlName="city" name="city">
                        <span class="show-input-error"
                            *ngIf="cardForm.controls['city'].hasError('required') && showError">Please enter City
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="zipCode">Zip Code</label>
                        <input type="text" class="form-control" id="zipCode" placeholder="Enter Zip Code"
                            autocomplete="off" formControlName="zipCode" name="zipCode" appOnlyNumber>
                        <span class="show-input-error"
                            *ngIf="cardForm.controls['zipCode'].hasError('required') && showError">Please enter Zip Code
                        </span>
                    </div>

                </div>
                <button type="button" class="btn btn-default btn-hover color-1 mt-3 w-50 mx-auto d-block "
                    (click)="addCard()">Submit</button>
            </form>
        </div>

    </div>
</div>
<header class="login-bg ">
    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <!-- <img
                    src="./../../../assets/images/login-flow-images/logo-with-title-purple.svg"> -->
                <i class="">
                    <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                    <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px"
                        class="logotext" />
                </i>
            </a>
            <div class="login-cover">
                <h4 class="login-head" style="padding-bottom: 10px !important;">Account Setup</h4>
                <form [formGroup]="accountSetupForm" (ngSubmit)="accountSetup()">
                    <div class="mb-3">
                        <label for="address">Street Address*</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" id="address" placeholder="Enter your Street Address"
                                name="address" autocomplete="off" formControlName="address" appGooglePlace
                                (selectedData)="address($event)">
                            <span class="input-group-text" id="basic-addon1"
                                style="border: 0; background-color: #f8f2f8;cursor: pointer;"
                                (click)="getCurrentPosition()">
                                <i><img src="./../../../assets/images/login-flow-images/location-marker-icon.png" alt=""
                                        width="20px" /></i>
                            </span>
                        </div>
                        <span class="show-input-error"
                            *ngIf="accountSetupForm.controls['address'].hasError('required') && showError">Please enter
                            your Street Address</span>
                    </div>

                    <div class="mb-3">
                        <label for="pwd">Zip Code*</label>
                        <input type="text" class="form-control" id="zip" placeholder="Enter your Zip Code" name="zip"
                            autocomplete="off" formControlName="zipCode">
                        <span class="show-input-error"
                            *ngIf="accountSetupForm.controls['zipCode'].hasError('required') && showError">Please enter
                            your Zip Code</span>
                    </div>
                    <div class="row">


                        <div class="col-md-6">
                            <!-- <div class="mb-3">
                                <label for="pwd">Country</label>
                                <input type="text" class="form-control" id="country" placeholder="Enter your Country"
                                    name="country" autocomplete="off" formControlName="countryId">
                            </div> -->
                            <div class="mb-3">
                                <label for="Country">Country*</label>
                                <div class="styled-select">
                                    <select class="form-select" id="Country" name="Country" formControlName="countryId"
                                        (change)="getCountryCity(true)">
                                        <option value="">Select Option</option>
                                        <option [value]="country._id" *ngFor="let country of countryList">
                                            {{country?.name || ''}}
                                        </option>
                                    </select>
                                </div>
                                <!-- <input type="text" class="form-control" id="countryId" placeholder="Enter your Country"
                                    name="countryId" autocomplete="off" formControlName="countryId"> -->
                            </div>
                            <span class="show-input-error"
                                *ngIf="accountSetupForm.controls['countryId'].hasError('required') && showError">
                                Please select Country
                            </span>
                        </div>

                        <div class="col-md-6">
                            <!-- <div class="mb-3">
                                <label for="pwd">State</label>
                                <input type="text" class="form-control" id="state" placeholder="Enter your State"
                                    name="state" autocomplete="off" formControlName="stateId">
                            </div> -->
                            <div class="mb-3">
                                <label for="State">State*</label>
                                <div class="styled-select">
                                    <select class="form-select" id="State" name="State" formControlName="stateId">
                                        <option value="">Select Option</option>
                                        <option [value]="state._id" *ngFor="let state of stateList">
                                            {{state?.name || ''}}
                                        </option>
                                    </select>
                                </div>
                                <!-- <input type="text" class="form-control" id="stateId" placeholder="Enter your State"
                                    name="stateId" autocomplete="off" formControlName="stateId"> -->
                            </div>
                            <span class="show-input-error"
                                *ngIf="accountSetupForm.controls['stateId'].hasError('required') && showError">
                                Please select State
                            </span>
                        </div>

                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="pwd">City</label>
                                <input type="text" class="form-control" id="city" placeholder="Enter your City"
                                    name="city" autocomplete="off" formControlName="city">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="pwd">County</label>
                                <input type="text" class="form-control" id="county" placeholder="Enter your County"
                                    name="county" autocomplete="off" formControlName="county">
                            </div>
                        </div>


                    </div>


                    <button type="submit" class="btn btn-default btn-hover color-1 mt-2 ">Next</button>
                </form>
            </div>
        </div>
    </nav>
</header>
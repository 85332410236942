<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">
                Orders Ended
            </div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="./../../../../assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>

        <div class="p-30 popup-input popup-input-date">
            <form>

                <h6 class="notification pb-3">How was your experience with {{notificationDetails?.driverId?.firstName+"
                    "+notificationDetails?.driverId?.lastName}}?</h6>
                <div class="edit-image">
                    <div class="edit-size">
                        <img src="{{notificationDetails?.driverId?.imageUrl?.thumbnail || './../../../../assets/images/login-flow-images/image 3.png'}}"
                            class="img-fluid round-shape">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="rating">
                            <ul>
                                <li (click)="onClickRating(1)">
                                    <i class="fa" aria-hidden="true"
                                        [ngClass]="selectedRating >= 1 ? 'fa-star' :'fa-star-o'"></i>
                                </li>
                                <li (click)="onClickRating(2)">
                                    <i class="fa" aria-hidden="true"
                                        [ngClass]="selectedRating >= 2 ? 'fa-star' :'fa-star-o'"></i>
                                </li>
                                <li (click)="onClickRating(3)">
                                    <i class="fa" aria-hidden="true"
                                        [ngClass]="selectedRating >= 3 ? 'fa-star' :'fa-star-o'"></i>
                                </li>
                                <li (click)="onClickRating(4)">
                                    <i class="fa" aria-hidden="true"
                                        [ngClass]="selectedRating >= 4 ? 'fa-star' :'fa-star-o'"></i>
                                </li>
                                <li (click)="onClickRating(5)">
                                    <i class="fa" aria-hidden="true"
                                        [ngClass]="selectedRating >= 5 ? 'fa-star' :'fa-star-o'"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>



                <button type="button" class="btn btn-default btn-hover color-1 text-uppercase mt-2 "
                    (click)="rateDriver()">Submit</button>

            </form>
        </div>

    </div>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  forgotForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    resetBy: new FormControl('EMAIL'),
    countryCode: new FormControl('+1'),
    phoneNumber: new FormControl('', [Validators.required])
  });
  forgotFormSubscription: Subscription;
  showError: boolean = false;


  constructor(
    private httpService: HttpService,
    private router: Router,
    private messageService: MessageService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.forgotFormSubscription = this.forgotForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
  }

  forgot = (): void => {
    if ((this.forgotForm.get('resetBy')?.value == 'EMAIL' && this.forgotForm.get('email')?.valid)
      || this.forgotForm.get('resetBy')?.value == 'PHONE' && this.forgotForm.get('phoneNumber')?.valid) {
      this.loaderService.showLoader();
      const postData: any = {
        resetBy: this.forgotForm.get('resetBy')?.value
      };
      if (this.forgotForm.get('resetBy')?.value == 'EMAIL') {
        postData['email'] = this.forgotForm.get('email')?.value;
      } else {
        postData['phoneNumber'] = this.forgotForm.get('phoneNumber')?.value;
        postData['countryCode'] = this.forgotForm.get('countryCode')?.value;
      }
      this.httpService.postData(ApiUrl.V2_FORGOT_PASSWORD, postData).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK) {
          const reference = this;
          this.messageService.alertCallback('success', response.message, '', function () {
            reference.router.navigate(['/login']);
          });
        }
      }, (error) => {
        this.loaderService.hideLoader();
      });
    } else {
      this.showError = true;
    }
  }

  ngOnDestroy(): void {
    if (this.forgotFormSubscription) {
      this.forgotFormSubscription.unsubscribe();
    }
  }

}

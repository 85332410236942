import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-two-step-verification',
  templateUrl: './two-step-verification.component.html',
  styleUrls: ['./two-step-verification.component.css']
})
export class TwoStepVerificationComponent implements OnInit, OnDestroy {

  twoStepVerificationForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    countryCode: new FormControl('+1', [Validators.required]),
    via: new FormControl('', [Validators.required]) // EMAIL , PHONE
  });
  private twoStepVerificationFormSubscription: Subscription;
  showError: boolean = false;
  private routeSubscription: Subscription;
  private params: any = null;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.params = null;
    this.twoStepVerificationFormSubscription = this.twoStepVerificationForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
    this.routeSubscription = this.route.params.subscribe((params) => {
      if (JSON.stringify(params) !== JSON.stringify({})) {
        this.params = params;
        this.twoStepVerificationForm.patchValue({
          email: (params || {}).email || '',
          phoneNumber: (params || {}).phoneNumber || '',
          countryCode: (params || {}).countryCode || '+1',
          via: ''
        });
      }
    });
  }

  twoStepVerification = (): void => {
    if (this.twoStepVerificationForm.valid) {
      this.loaderService.showLoader();
      this.httpService.postData(ApiUrl.SEND_OTP, this.twoStepVerificationForm.getRawValue()).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK && this.params) {
          const reference = this;
          this.messageService.alertCallback('success', response.message, '', function () {
            reference.router.navigate(['/verify-otp', reference.params]);
          });
        }
      }, (error) => {
        this.loaderService.hideLoader();
      });
    } else {
      this.showError = true;
    }
  }

  onCheckedChange = (type: string): void => {
    this.twoStepVerificationForm.get('via')?.setValue(type);
  }

  isChecked = (type: string): boolean => {
    if (type == this.twoStepVerificationForm.get('via')?.value) {
      return true;
    } else if (type == this.twoStepVerificationForm.get('via')?.value) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    if (this.twoStepVerificationFormSubscription) {
      this.twoStepVerificationFormSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCalculatorOnlyNumber]'
})
export class CalculatorOnlyNumberDirective {

  @Input() maxValue: any = 0;

  // Allow only numbers
  private regex: RegExp = new RegExp('^[0-9]*$');
  // Allow key codes for special events. Reflect : Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'Del', 'ArrowRight', 'ArrowLeft'];

  constructor(
    private el: ElementRef
  ) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    const maxValue: any = parseInt(this.maxValue);
    const currentSecond: any = parseInt(next);

    if ((next && !String(next).match(this.regex)) || (currentSecond > maxValue)) {
      event.preventDefault();
    }

  }

}

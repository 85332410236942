<div class="edit-modal border-0">
    <div class="modal-body border-0">

        <div class="header-modal p-lg-4 p-3">
            <div class="icon-side">
                <img src="./../../../../assets/images/login-flow-images/Vector (2).png" class="pe-2">
                Add New Bank
            </div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="./../../../../assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>

        <div class="p-30 light-blue">
            <form [formGroup]="bankForm">
                <div class="row">

                    <div class="col-md-6 mb-3">
                        <label for="accountNumber">Bank Account Number</label>
                        <input type="text" class="form-control" id="accountNumber"
                            placeholder="Enter Bank Account Number" name="accountNumber" autocomplete="off"
                            formControlName="accountNumber" appOnlyNumber>
                        <span class="show-input-error"
                            *ngIf="bankForm.controls['accountNumber'].hasError('required') && showError">Please enter
                            Bank Account Number
                        </span>
                        <span class="show-input-error" *ngIf="!bankForm.controls['accountNumber'].hasError('required') 
                        && bankForm.controls['accountNumber'].hasError('minlength') && showError">
                            Bank Account Number length must be at least 8 characters long
                        </span>
                        <span class="show-input-error" *ngIf="!bankForm.controls['accountNumber'].hasError('required') 
                        && bankForm.controls['accountNumber'].hasError('maxlength') && showError">
                            Bank Account Number length must be at most of 20 characters long only
                        </span>
                    </div>


                    <div class="col-md-6 mb-3">
                        <label for="confirmAccountNumber">Confirm Bank Account Number</label>
                        <input type="text" class="form-control" id="confirmAccountNumber"
                            placeholder="Enter Confirm Bank Account Number" name="confirmAccountNumber"
                            autocomplete="off" formControlName="confirmAccountNumber" appOnlyNumber>
                        <span class="show-input-error"
                            *ngIf="bankForm.controls['confirmAccountNumber'].hasError('required') && showError">Please
                            enter Confirm Bank Account Number
                        </span>
                        <span class="show-input-error"
                            *ngIf="!bankForm.controls['confirmAccountNumber'].hasError('required') 
                        && (bankForm.controls['confirmAccountNumber'].value != bankForm.controls['accountNumber'].value) && showError">
                            Confirm Bank Account Number should be same as Bank Account Number</span>
                    </div>


                    <div class="col-md-6 mb-3">
                        <label for="bankHolderName">Name On Account</label>
                        <input type="text" class="form-control" id="bankHolderName" placeholder="Enter Name On Account"
                            name="bankHolderName" autocomplete="off" formControlName="bankHolderName">
                        <span class="show-input-error"
                            *ngIf="bankForm.controls['bankHolderName'].hasError('required') && showError">Please enter
                            Name On Account
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="bankName">Bank Name</label>
                        <input type="text" class="form-control" id="bankName" placeholder="Enter Bank Name"
                            autocomplete="off" formControlName="bankName" name="bankName">
                        <span class="show-input-error"
                            *ngIf="bankForm.controls['bankName'].hasError('required') && showError">Please enter Bank
                            Name
                        </span>
                    </div>

                    <div class="col-md-12 mb-3  street-address">
                        <label for="address">Address</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" id="address" placeholder="Enter Address"
                                name="address" appGooglePlace (selectedData)="address($event)"
                                formControlName="address">
                            <span class="input-group-text" id="basic-addon1"
                                style="border: 0; background-color: #EFF4F8;cursor: pointer;"
                                (click)="getCurrentPosition()">
                                <i><img src="../../../../assets/images/login-flow-images/location-marker-icon.png"
                                        alt="" width="20px" /></i>
                            </span>
                        </div>
                        <span class="show-input-error"
                            *ngIf="bankForm.controls['address'].hasError('required') && showError">Please enter Address
                        </span>
                    </div>

                    <!-- <div class="col-md-6 mb-3">
                        <label for="routingNumber">Routing Number</label>
                        <input type="text" class="form-control" id="routingNumber" placeholder="Enter Routing Number"
                            name="routingNumber" autocomplete="off" formControlName="routingNumber">
                        <span class="show-input-error"
                            *ngIf="bankForm.controls['routingNumber'].hasError('required') && showError">Please enter
                            Routing Number
                        </span>
                    </div> -->

                    <div class="col-md-6 mb-3">
                        <label for="country">Country</label>
                        <input type="text" class="form-control" id="country" placeholder="Enter Country" name="country"
                            autocomplete="off" formControlName="country">
                        <span class="show-input-error"
                            *ngIf="bankForm.controls['country'].hasError('required') && showError">Please enter Country
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="state">State</label>
                        <input type="text" class="form-control" id="state" placeholder="Enter State" name="state"
                            autocomplete="off" formControlName="state">
                        <span class="show-input-error"
                            *ngIf="bankForm.controls['state'].hasError('required') && showError">Please enter State
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="city">City</label>
                        <input type="text" class="form-control" id="city" placeholder="Enter City" autocomplete="off"
                            formControlName="city" name="city">
                        <span class="show-input-error"
                            *ngIf="bankForm.controls['city'].hasError('required') && showError">Please enter City
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="zipCode">Zip Code</label>
                        <input type="text" class="form-control" id="zipCode" placeholder="Enter Zip Code"
                            autocomplete="off" formControlName="zipCode" name="zipCode" appOnlyNumber>
                        <span class="show-input-error"
                            *ngIf="bankForm.controls['zipCode'].hasError('required') && showError">Please enter Zip Code
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="routingNumber">Routing Number</label>
                        <input type="text" class="form-control" id="routingNumber" placeholder="Enter Routing Number"
                            name="routingNumber" autocomplete="off" formControlName="routingNumber">
                        <span class="show-input-error"
                            *ngIf="bankForm.controls['routingNumber'].hasError('required') && showError">Please enter
                            Routing Number
                        </span>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="accountType">Account Type</label>
                        <div class="drop-clr styled-select">
                            <select class="form-select" id="accountType" name="accountType"
                                formControlName="accountType">
                                <option value="" disabled>Select Option</option>
                                <option [value]="data?.value" *ngFor="let data of accountTypes">
                                    {{data?.name}}
                                </option>
                            </select>
                        </div>
                        <span class="show-input-error"
                            *ngIf="bankForm.controls['accountType'].hasError('required') && showError">Please select
                            Account Type
                        </span>
                    </div>

                </div>
                <button type="button" class="btn btn-default btn-hover color-1 mt-3 w-50 mx-auto d-block "
                    (click)="addBank()">Submit</button>
            </form>
        </div>

    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';

declare var google: any;

@Component({
  selector: 'app-account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.css']
})
export class AccountSetupComponent implements OnInit {

  accountSetupForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    countryCode: new FormControl('+1', [Validators.required]),
    userType: new FormControl(2, [Validators.required]),
    companyName: new FormControl('', [Validators.required]),
    designation: new FormControl('', [Validators.required]),

    original: new FormControl(''),
    thumbnail: new FormControl(''),

    address: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required]),
    city: new FormControl(''),
    stateId: new FormControl('', [Validators.required]),
    county: new FormControl(''),
    countryId: new FormControl('', [Validators.required])
  });
  private accountSetupFormSubscription: Subscription;
  private accountSetupFormSubscriptionSecond: Subscription;
  showError: boolean = false;
  private routeSubscription: Subscription;

  countryList: any[] = [];
  stateList: any[] = [];


  constructor(
    private httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {

    this.accountSetupFormSubscription = this.accountSetupForm.controls.zipCode.valueChanges.
      pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value) => {
        this.showError = false;
        // this.getAreaFromZipcode();
      });

    this.accountSetupFormSubscriptionSecond = this.accountSetupForm.controls.address.valueChanges.subscribe((value) => {
      this.showError = false;
    });

    this.routeSubscription = this.route.params.subscribe((params) => {
      if (JSON.stringify(params) !== JSON.stringify({})) {
        this.accountSetupForm.patchValue({
          email: (params || {}).email || '',
          phoneNumber: (params || {}).phoneNumber || '',
          countryCode: (params || {}).countryCode || '+1',
          userType: (params || {}).userType || 2,
          companyName: (params || {}).companyName || '',
          designation: (params || {}).designation || '',

          original: (params || {}).original || '',
          thumbnail: (params || {}).thumbnail || ''
        });
      }
    });
    this.getCountryCity(false);
  }

  accountSetup = (): void => {
    if (this.accountSetupForm.valid) {
      this.loaderService.showLoader();
      const postData = this.accountSetupForm.getRawValue();
      // delete postData.designation;
      delete postData.userType;
      delete postData.countryCode;
      delete postData.phoneNumber;
      delete postData.email;

      if (postData.original == '' || postData.thumbnail == '') {
        delete postData.original;
        delete postData.thumbnail;
      }
      
      this.httpService.postData(ApiUrl.UPDATE_PROFILE, postData).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK) {
          const reference = this;
          this.messageService.alertCallback('success', response.message, '', function () {
            reference.router.navigate(['/welcome', reference.accountSetupForm.getRawValue()]);
          });
        }
      }, (error) => {
        this.loaderService.hideLoader();
      });
    } else {
      this.showError = true;
    }
  }

  ngOnDestroy(): void {
    if (this.accountSetupFormSubscription) {
      this.accountSetupFormSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.accountSetupFormSubscriptionSecond) {
      this.accountSetupFormSubscriptionSecond.unsubscribe();
    }
  }

  getAreaFromZipcode = (): void => {
    if (this.accountSetupForm.controls.zipCode.valid) {
      // this.loaderService.showLoader();this
      const postData = {
        zipcode: this.accountSetupForm.controls.zipCode.value
      };
      this.httpService.getData(ApiUrl.GET_AREA_FROM_ZIPCODE, postData).subscribe((response) => {
        // this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK && response.data) {
          this.accountSetupForm.patchValue({
            // address: (response.data || {}).county || '',
            city: (response.data || {}).cityName || '',
            // stateId: (response.data || {}).state_short || '',
            county: (response.data || {}).county || '',
            // countryId: (response.data || {}).county || ''
          });
        } else {
          this.accountSetupForm.patchValue({
            // address: '',
            city: '',
            // stateId: '',
            county: '',
            // countryId: ''
          });
        }
      }, (error) => {
        // this.loaderService.hideLoader();
      });
    }
  }

  getCountryCity = (isCountryChange: boolean): void => {
    // this.loaderService.showLoader();this
    const postData: any = {};
    if (isCountryChange) {
      postData['countryId'] = this.accountSetupForm.controls.countryId.value;
      this.stateList = [];
    } else {
      this.countryList = [];
    }
    this.httpService.getData(ApiUrl.GET_COUNTRY_CITY, postData).subscribe((response) => {
      // this.loaderService.hideLoader();
      if (response.statusCode == ResponseStatusCode.OK) {
        if (isCountryChange) {
          this.stateList = response.data || [];
        } else {
          this.countryList = response.data || [];
        }
      }
    }, (error) => {
      // this.loaderService.hideLoader();
    });
  }

  address = (data: any): void => {
    this.accountSetupForm.patchValue({
      // stateId: (data || {}).admin_area_l1 || '',
      county: (data || {}).locality || '',
      //  countryId: (data || {}).country || '',

      address: (data || {}).formatted_address_second || '',//(data || {}).formatted_address || '',
      //  country: (data || {}).country || '',
      //  state: (data || {}).admin_area_l1 || '',
      city: (data || {}).locality || '',
      zipCode: (data || {}).postal_code || ''
    });
  }

  getCurrentPosition = (): void => {
    const reference = this;
    navigator.geolocation.getCurrentPosition((pos) => {
      const currentLocation = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
      const geocoder = new google.maps.Geocoder();
      const location_obj: any = {};
      geocoder.geocode({ 'latLng': currentLocation }, (results: any, status: any) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            for (var i = 0; i < results[0].address_components.length; i++) {
              for (var b = 0; b < results[0].address_components[i].types.length; b++) {
                if (results[0].address_components[i].types.indexOf('locality') > -1) {
                  location_obj['locality'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('administrative_area_level_1') > -1) {
                  location_obj['admin_area_l1'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('street_number') > -1) {
                  location_obj['street_number'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('route') > -1) {
                  location_obj['route'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('country') > -1) {
                  location_obj['country'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('postal_code') > -1) {
                  location_obj['postal_code'] = results[0].address_components[i]['long_name'];
                }
                location_obj['formatted_address'] = results[0].formatted_address;
              }
            }
          }
          reference.accountSetupForm.patchValue({
            address: (location_obj || {}).formatted_address || '',
            //  stateId: (location_obj || {}).admin_area_l1 || '',
            county: (location_obj || {}).locality || '',
            //  countryId: (location_obj || {}).country || '',
            city: (location_obj || {}).locality || '',
            zipCode: (location_obj || {}).postal_code || ''
          });
        }
      });
    }, (error) => {
      console.log('Unable to get location: ' + error.message);
    });
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guard/auth.guard';
import { LoginGuard } from './guard/login.guard';
import { AccessUserComponent } from './public-pages/access-user/access-user.component';
import { AccountSetupComponent } from './public-pages/account-setup/account-setup.component';
import { CalculatorComponent } from './public-pages/calculator/calculator.component';
import { ForgotPasswordComponent } from './public-pages/forgot-password/forgot-password.component';
import { LoginComponent } from './public-pages/login/login.component';
import { PageNotFoundComponent } from './public-pages/page-not-found/page-not-found.component';
import { ProfileSetupComponent } from './public-pages/profile-setup/profile-setup.component';
import { SignUpComponent } from './public-pages/sign-up/sign-up.component';
import { TwoStepVerificationComponent } from './public-pages/two-step-verification/two-step-verification.component';
import { VerificationCodeComponent } from './public-pages/verification-code/verification-code.component';
import { VerifyOTPComponent } from './public-pages/verify-otp/verify-otp.component';
import { WelcomeComponent } from './public-pages/welcome/welcome.component';
import { SubscriptionPlansComponent } from './public-pages/subscription-plans/subscription-plans.component';
import { PaymentForSubscriptionPlansComponent } from './public-pages/payment-for-subscription-plans/payment-for-subscription-plans.component';

const routes: Routes = [
  { path: '', redirectTo: '/sign-up', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'access-user/:token', component: AccessUserComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verification-code', component: VerificationCodeComponent },
  { path: 'profile-setup', component: ProfileSetupComponent },
  { path: 'account-setup', component: AccountSetupComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'calculator', component: CalculatorComponent },
  { path: 'two-step-verification', component: TwoStepVerificationComponent },
  { path: 'verify-otp', component: VerifyOTPComponent },
  { path: 'subscription-plans', component: SubscriptionPlansComponent },
  { path: 'payment-subscription-plans/:id', component: PaymentForSubscriptionPlansComponent },
  {
    path: '', canActivate: [AuthGuard],
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

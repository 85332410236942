import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PaginationControlsOptions } from 'src/app/models/pagination';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';
// import { GETDATA } from '../../common/get-data';
// import { RolePermissionService } from 'src/app/services/role-permission.service';
import { jsPDF } from "jspdf";
import * as kjua from 'kjua-svg';

@Component({
  selector: 'app-vehicles-listing',
  templateUrl: './vehicles-listing.component.html',
  styleUrls: ['./vehicles-listing.component.css']
})
export class VehiclesListingComponent  implements OnInit {
 
  public paginationControlsOptions: PaginationControlsOptions = new PaginationControlsOptions();
  constructor(
    public  httpService: HttpService,
    public  loaderService: LoaderService,
    public  utilityService: UtilityService,
    public  messageService: MessageService,
    public  modalService: BsModalService,
    public  router: Router,
    private _bsModalRef: BsModalRef,
    private options: ModalOptions
  ) {
    // super(httpService, loaderService, utilityService, messageService, modalService, router);
  }

  ngOnInit(): void {
    this.paginationControlsOptions.page = 1;
    this.paginationControlsOptions.limit = 5;
    this.paginationControlsOptions.skip = 0;
    this.paginationControlsOptions.total = 0;
    this.paginationControlsOptions.data = [];
    if (this.options.initialState) {
      const tempData: any = this.options.initialState;
      this.paginationControlsOptions.data = tempData['vehicles'] || [];
      this.paginationControlsOptions.total = this.paginationControlsOptions.data.length || 0;
      this.paginationControlsOptions.data.forEach((item: any) => {
        const todate = new Date(0, 0, 0);
        if (!!item.fillingTime) {
          item['fillingDateTime'] = todate.setMilliseconds(item.fillingTime);
        }
      });
    }
  }

   getPage = (page: number): void => {
    this.paginationControlsOptions.page = page;
  }

  closeModal = (): void => {
    this._bsModalRef.hide();
  }



}


        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header" style="background:#551D87;">
            <!-- <h6 class="modal-title text-white">Hatters Pond</h6> -->
            <button type="button" class="btn-close rounded-circle bg-white p-1" data-bs-dismiss="modal" (click)="closeModal()"  aria-label="Close" style="color:#FF7900;"></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">

                <form class="business_profile p-4 pb-0">
                
                    <div class="row">
                        <div class="profile_upload position-relative mb-5">
                          <div class="position-relative">
                            <img src="assets/img/profile_upload.png">
                            <!-- Use label as an edit icon and trigger file input click event on its click -->
                            <label class="position-absolute bottom-0 cursor-pointer" style="right:4px;">
                              <img src="assets/img/edit_profile.png" (click)="fileInput.click()">
                            </label>
                          </div>
                          <span style="color:#551d87;">Upload Business Logo</span>
                          <!-- Hidden input file element -->
                          <input #fileInput type="file" style="display: none;" (change)="onFileSelected1($event)">
                        </div>
                      </div>
                      
                
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div formArrayName="entries">
                          <div *ngFor="let entry of entries.controls; let i = index" [formGroupName]="i">
                            <div class="row">
                              <!-- General Details -->
                              <div class="col-lg-6 col-md-12">
                                <h4 class="mb-4"><b>General Details</b></h4>
                                <div class="mb-4">
                                  <label class="form-label" for="Business">Business Name</label>
                                  <input type="text" id="businessName" placeholder="Enter Business Name" class="form-control bg-light p-3" formControlName="businessName" />
                                  <span class="show-input-error"
                                  *ngIf="entries.controls[i].get('businessName')?.hasError('required') && showError">Please
                                  Enter Business Name</span>
                                </div>
                                <div class="mb-4">
                                  <label class="form-label" for="contact">Contact Number</label>
                                  <input type="text" id="contact" placeholder="Enter contact number" class="form-control bg-light p-3" formControlName="contactNumber" />
                                  <span class="show-input-error"
                                  *ngIf="entries.controls[i].get('contactNumber')?.hasError('required') && showError">Please
                                  Enter contact number</span>
                                </div>
                                <div class="mb-4">
                                  <label class="form-label" for="email">Email</label>
                                  <input type="text" id="email" placeholder="Enter Email" class="form-control bg-light p-3" formControlName="email" />
                                  <span class="show-input-error"
                                  *ngIf="entries.controls[i].get('email')?.hasError('required') && showError">Please
                                  Enter Email</span>
                                </div>
                                <div class="mb-4">
                                  <label class="form-label" for="date">Created On</label>
                                  <input type="date" id="date" class="form-control bg-light p-3" formControlName="createdOn" />
                                  <span class="show-input-error"
                                  *ngIf="entries.controls[i].get('createdOn')?.hasError('required') && showError">Please
                                  Select Date</span>
                                </div>
                              </div>
                              <!-- Business Details -->
                              <div class="col-lg-6 col-md-12">
                                <h4 class="mb-4"><b>Business Details</b></h4>
                                <div class="mb-4">
                                  <label class="form-label" for="owner">Owner Name</label>
                                  <input type="text" id="owner" placeholder="Enter Owner Name" class="form-control bg-light p-3" formControlName="ownerName" />
                                  <span class="show-input-error"
                                  *ngIf="entries.controls[i].get('ownerName')?.hasError('required') && showError">Please
                                  Enter Owner Name</span>
                                </div>
                                <div class="mb-4">
                                  <label class="form-label" for="invoice">Invoice Number</label>
                                  <input type="text" id="invoice" placeholder="Enter contact number" class="form-control bg-light p-3" formControlName="invoiceNumber" />
                                  <span class="show-input-error"
                                  *ngIf="entries.controls[i].get('invoiceNumber')?.hasError('required') && showError">Please
                                  Enter Invoice Number</span>
                                </div>
                                <div class="mb-4">
                                  <label class="form-label" for="address">Address</label>
                                  <input type="text" id="address" placeholder="Enter Email" class="form-control bg-light p-3" formControlName="address" />
                                  <span class="show-input-error"
                                  *ngIf="entries.controls[i].get('address')?.hasError('required') && showError">Please
                                  Enter Address</span>
                                </div>
                                <div class="mb-4">
                                  <label class="form-label w-100" for="payment">Location</label>
                                  <select class="selec bg-light p-3 w-100 border rounded" formControlName="location">
                                    <option value="">Select Location</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                  </select>
                                  <span class="show-input-error"
                                  *ngIf="entries.controls[i].get('location')?.hasError('required') && showError">Please
                                  Select Location</span>
                                </div>
                              </div>
                            </div>
                            <!-- <button type="button" class="btn btn-danger" (click)="removeEntry(i)">Remove Entry</button> -->
                          </div>
                        </div>
                        <!-- <button type="button" class="btn btn-primary mt-3" (click)="addEntry()">Add Entry</button> -->
                      </form>
                      

                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                          <div class="col-lg-6 col-md-12">
                            <h4 class="mt-3 mb-4"><b>Contact Details</b></h4>
                            <div class="mb-4">
                              <label class="form-label" for="name">Name</label>
                              <input type="text" id="name" placeholder="Enter Name" class="form-control bg-light p-3" formControlName="name" />
                              <span class="show-input-error"
                              *ngIf="contactForm.controls['name'].hasError('required') && showError">Please
                              Enter Name</span>
                            </div>
                            <div class="mb-4">
                              <label class="form-label" for="contactNumber">Contact Number</label>
                              <input type="number" id="contactNumber" placeholder="Enter contact number" class="form-control bg-light p-3" formControlName="contactNumber" />
                              <span class="show-input-error"
                              *ngIf="contactForm.controls['contactNumber'].hasError('required') && showError">Please
                              Enter Number</span>
                            </div>
                            <div class="mb-4">
                              <label class="form-label w-100" for="paymentMode">Payment Modes</label>
                              <select class="selec bg-light p-3 w-100 border rounded" formControlName="paymentMode">
                                <option value="">Select Payment Mode</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                                <option value="4">Four</option>
                                <option value="5">Five</option>
                                <option value="6">Six</option>
                                <option value="7">Seven</option>
                                <option value="8">Eight</option>
                              </select>
                              <span class="show-input-error"
                              *ngIf="contactForm.controls['paymentMode'].hasError('required') && showError">Please
                              Select paymentMode</span>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12">
                            <h6 class="yellow d-flex justify-content-end align-items-center mt-3" (click)="addEntry()" style="cursor: pointer;;"><img class="pe-1" src="assets/img/add.png" ><b> Add New Business</b></h6>
                            <div class="mb-4">
                              <label class="form-label" for="designation">Designation</label>
                              <input type="text" id="designation" placeholder="Enter Designation" class="form-control bg-light p-3" formControlName="designation" />
                              <span class="show-input-error"
                              *ngIf="contactForm.controls['designation'].hasError('required') && showError">Please
                              Enter Designation</span>
                            </div>
                            <div class="mb-4">
                              <label class="form-label" for="email">Email</label>
                              <input type="email" id="email" placeholder="Enter Email" class="form-control bg-light p-3" formControlName="email" />
                              <span class="show-input-error"
                              *ngIf="contactForm.controls['email'].hasError('required') && showError">Please
                              Enter Email</span>
                            </div>
                            <div class="mb-4">
                              <label class="form-label w-100" for="upload">Upload Contact Image</label>
                              <span class="position-relative upload-btn-wrapper w-100">
                                <button class="form-control bg-light p-3 text-start">Upload Image</button>
                                <input class="form-control p-3" type="file" id="formFile" formControlName="ContactImage" (change)="onFileSelected($event)">
                                <p class="position-absolute top-50 start-50">Browse</p>
                                <span class="show-input-error"
                                *ngIf="contactForm.controls['ContactImage'].hasError('required') && showError">Please
                                Enter Image</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center border-0 mt-2 mb-2">
                          <button type="submit" class="submit btn p-2 w-25 text-white">Submit</button>
                        </div>
                      </form>
                      
                    
                    <!-- <div class="d-flex  justify-content-center border-0 mt-2 mb-2">
                        <button type="button" class="submit btn p-2 w-25 text-white">Submit</button>
                    </div> -->
                    
                </form>
            
            </div>
                
        </div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOTPComponent implements OnInit, OnDestroy {

  verificationOTPForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    countryCode: new FormControl('+1', [Validators.required]),
    userType: new FormControl(2, [Validators.required]),
    otp: new FormControl('', [Validators.required, Validators.minLength(4)])
  });
  private verificationOTPFormSubscription: Subscription;
  showError: boolean = false;
  private routeSubscription: Subscription;


  constructor(
    private httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.verificationOTPFormSubscription = this.verificationOTPForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
    this.routeSubscription = this.route.params.subscribe((params) => {
      if (JSON.stringify(params) !== JSON.stringify({})) {
        this.verificationOTPForm.patchValue({
          email: (params || {}).email || '',
          phoneNumber: (params || {}).phoneNumber || '',
          countryCode: (params || {}).countryCode || '+1',
          userType: (params || {}).userType || 2
        });
      }
    });
  }

  verificationOTP = (): void => {
    if (this.verificationOTPForm.valid) {
      this.loaderService.showLoader();
      this.httpService.postData(ApiUrl.VERIFY_OTP, this.verificationOTPForm.getRawValue()).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK && response.data) {
          localStorage.setItem('twoStepVerification', 'false');
          this.router.navigate(['/dashboard']);
        }
      }, (error) => {
        this.loaderService.hideLoader();
      });
    } else {
      this.showError = true;
    }
  }


  ngOnDestroy(): void {
    if (this.verificationOTPFormSubscription) {
      this.verificationOTPFormSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.css']
})
export class CancelOrderComponent implements OnInit {

  public onClose: Subject<{}>;
  public text: string = '';

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.text = '';
    if (this.options.initialState) {
      const tempData: any = this.options.initialState;
      this.text = tempData['text'];
    }
  }

  closeModalWithYes = (): void => {
    this.onClose.next({ success: true });
    this._bsModalRef.hide();
  }

  closeModal = (): void => {
    this.onClose.next({ success: false });
    this._bsModalRef.hide();
  }

}

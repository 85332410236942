import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-request-now',
  templateUrl: './request-now.component.html',
  styleUrls: ['./request-now.component.css']
})
export class RequestNowComponent implements OnInit {

  public data: any;

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.data = {};
    if (this.options.initialState) {
      const tempData: any = this.options.initialState;
      this.data = tempData['data'] || {};
    }
  }

  closeModal = (): void => {
    this._bsModalRef.hide();
    this.router.navigate(['/dashboard']);
  }

}

<div class="row mt-4" *ngIf="paginationOptions.total">
    <div class="col-12 d-flex align-items-center justify-content-between">

        <div class="text-16  text-white">Showing {{paginationOptions.skip + 1 }} to 
            {{cal()}} of Total
            {{paginationOptions.total}} Results</div>

        <nav aria-label="Page navigation example">
            <ul class="pagination align-items-center">
                <li class="page-item">
                    <a class="page-link" href="javascript:void(0)" aria-label="Previous" (click)="previous()">
                        <span aria-hidden="true"><i class="fa-solid fa-chevron-left"></i></span>
                    </a>
                </li>
                <li class="page-item" *ngFor="let page of pageList; let i = index">
                    <a class="page-link" href="javascript:void(0)"  (click)="onChangePage(page)"
                    [ngClass]="{'selected-pagination-link' : paginationOptions.pageNo == page}">{{page}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="javascript:void(0)" aria-label="Next" (click)="next()">
                        <span aria-hidden="true"><i class="fa-solid fa-chevron-right"></i></span>
                    </a>
                </li>
            </ul>
        </nav>

    </div>
</div>
<div class="edit-modal">
    <div class="modal-body">
        <div class="header-modal">
            <div class="icon-side">Truck Details</div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="/assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>
        <div class="bg-white">
            <div class="light-blue">
                <div class="table-responsive popup-table table-height bg-th" style="height: auto;">
                    <table class="table mb-0">
                        <thead class="table-dark table-light">
                            <tr>
                                <th class="serialno text-center">Sr No.</th>
                                <th>Name</th>
                                <th>Grade</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody class="table-td">
                            <tr *ngFor="let item of data; let i = index">
                                <td class="serialno text-center">
                                    {{ i + 1}}
                                </td>
                                <td>{{item?.gasId?.name || '-'}}</td>
                                <td>{{item?.gasId?.grade != " " ?item?.gasId?.grade : '-'}}</td>
                                <td>{{item?.quantity || 0}}</td>
                            </tr>
                            <tr *ngIf="!data?.length">
                                <td colspan="4" style="text-align: center;"> No Record Found!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
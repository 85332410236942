import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ResetPasswordComponent } from '../modal/reset-password/reset-password.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {

  public sidebarType: number = 0;
  public isShowReports: boolean = false;
  public isShowReport: boolean = false;
  public isShowOrders: boolean = false;
  public isShowOrder: boolean = false
  isShowFUEL: boolean = false
  isShowmeta: boolean = false;
  isShowOrdermetadata: boolean = false;
  isShowbusiness: boolean = false;
  isShowOrderbusinessdata: boolean = false;
  isShowdashboard: boolean = false
  isShowdashboarddata: boolean = false
  isShowdFUELdata: boolean = false
  isShowREPORTdata: boolean = false
  isShowREPORT: boolean = false
  isShowCHATdata : boolean = false
  isShowCHAT: boolean = false
  private routeSubscription: Subscription;

  constructor(
    private httpService: HttpService,
    private loaderService: LoaderService,
    public utilityService: UtilityService,
    private router: Router,
    private modalService: BsModalService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.checkSidebarType();
    this.routeSubscription = this.router.events.subscribe(() => {
      this.checkSidebarType();
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  checkSidebarType = (): void => {
    if (this.router.url.includes('dashboard')) {
      this.sidebarType = 1;
      if (this.router.url.includes('dashboard/new-order')) {
        this.sidebarType = 2;
      } else if (this.router.url.includes('dashboard/notifications')) {
        this.sidebarType = 3;
      } else if (this.router.url.includes('dashboard/payment')) {
        this.sidebarType = 4;
      } else if (this.router.url.includes('dashboard/select-vehicles')) {
        this.sidebarType = 5;
      }
    } else if (this.router.url.includes('delivery-locations')) {
      this.sidebarType = 11;
      if (this.router.url.includes('delivery-locations/add-new-location')) {
        this.sidebarType = 12;
      } else if (this.router.url.includes('delivery-locations/edit-location')) {
        this.sidebarType = 13;
      }
    } else if (this.router.url.includes('manage-vehicles')) {
      this.sidebarType = 21;
      if (this.router.url.includes('manage-vehicles/add-new-vehicle')) {
        this.sidebarType = 22;
      } else if (this.router.url.includes('manage-vehicles/edit-vehicle')) {
        this.sidebarType = 23;
      }
    } else if (this.router.url.includes('subscription-report')) {
      this.sidebarType = 41;
      // if (this.router.url.includes('automatic-orders/edit-automatic-order')) {
      //   this.sidebarType = 42;
      // }
    } else if (this.router.url.includes('orders')) {
      this.sidebarType = 31;
      if (this.router.url.includes('orders/edit-order')) {
        this.sidebarType = 32;
      } else if (this.router.url.includes('orders/track-order')) {
        this.sidebarType = 33;
      }
    } else if (this.router.url.includes('payments')) {
      this.sidebarType = 51;
    } else if (this.router.url.includes('detailed-reports')) {
      this.sidebarType = 71;
    } else if (this.router.url.includes('custom-reports')) {
      this.sidebarType = 91;
    } else if (this.router.url.includes('snapshot-reports')) {
      this.sidebarType = 61;
    } else if (this.router.url.includes('profile')) {
      this.sidebarType = 81;
    } 
    // else if (this.router.url.includes('telematics')) {
    //   this.sidebarType = 101;
    // } 
    else if (this.router.url.includes('wallet')) {
      this.sidebarType = 111;
    } else if (this.router.url.includes('route-optimization')) {
      this.sidebarType = 121;
    }
    // ---------------------------------------------------------------------------------------------------------------------------------
    else if (this.router.url.includes('admin-defaults')) {
      this.sidebarType = 131;
    }
    else if (this.router.url.includes('transactions')) {
      this.sidebarType = 786;
    }
    else if (this.router.url.includes('subscription')) {
      this.sidebarType = 31;
    }
    else if (this.router.url.includes('dtn-taxes')) {
      this.sidebarType = 110;
    }
    else if (this.router.url.includes('dtn-product-code')) {
      this.sidebarType = 102;
    }
    else if (this.router.url.includes('dtn-blend-month')) {
      this.sidebarType = 103;
    }
    else if (this.router.url.includes('dtn-terminal-detail')) {
      this.sidebarType = 104;
    }
    else if (this.router.url.includes('invoice-template')) {
      this.sidebarType = 105;
    }
    else if (this.router.url.includes('meta-vehicles')) {
      this.sidebarType = 106;
    }
    else if (this.router.url.includes('business-business-detail')) {
      this.sidebarType = 111;
    }
    else if (this.router.url.includes('business-business-report')) {
      this.sidebarType = 112;
    }
    else if (this.router.url.includes('dashboard-revenue-generated')) {
      this.sidebarType = 136;
    }
    else if (this.router.url.includes('dashboard-subscription')) {
      this.sidebarType = 99;
    }
    else if (this.router.url.includes('fuel-master/price-master')) {
      this.sidebarType = 150;
    }
    else if (this.router.url.includes('fuel-master/analytics-master')) {
      this.sidebarType = 151;
    }
    else if (this.router.url.includes('fuel-master/margin-master')) {
      this.sidebarType = 152;
    }
    else if (this.router.url.includes('fuel-master/market-master')) {
      this.sidebarType = 153;
    }
    else if (this.router.url.includes('fuel-master/demand-master')) {
      this.sidebarType = 154;
    }
    else if (this.router.url.includes('reports/financial-report')) {
      this.sidebarType = 160;
    }
    else if (this.router.url.includes('reports/marketing-report')) {
      this.sidebarType = 161;
    }
    else if (this.router.url.includes('reports/feedback-report')) {
      this.sidebarType = 162;
    }
    else if (this.router.url.includes('reports/revenue-report')) {
      this.sidebarType = 163;
    }
    else if (this.router.url.includes('reports/price-model-report')) {
      this.sidebarType = 164;
    }
    else if (this.router.url.includes('reports/churn-rate-report')) {
      this.sidebarType = 165;
    }
    else if (this.router.url.includes('support/chat-report')) {
      this.sidebarType = 140;
    }
    else if (this.router.url.includes('dashboard/dashboard-overview')) {
      this.sidebarType = 199;
    }
    
    
    
    
    
    
    
 
    
  }

  navigate = (routeLink: string): void => {
    if (routeLink) {
      this.router.navigate([routeLink]);
    }
  }

  resetPassword = (): void => {
    const modalRef = this.modalService.show(ResetPasswordComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'custom-modal-content-radius modal-dialog-centered',
      // initialState: { data: this.data }
    });
  }

  signOut = (): void => {
    const reference = this;
    this.messageService.alertCallbackWithTwoButton('warning', 'Do you want to sign out?', '',
      function (data: boolean) {
        if (data) {
          //localStorage.clear();
          // localStorage.removeItem('accessToken');
          // reference.router.navigate(['login']);
          reference.logout();
        }
      });
  }

  logout = (): void => {
    const reference = this;
    this.loaderService.showLoader();
    this.httpService.postData(ApiUrl.LOGOUT).subscribe((response) => {
      this.loaderService.hideLoader();
      if (response.statusCode == ResponseStatusCode.OK) {
        localStorage.removeItem('accessToken');
        reference.router.navigate(['login']);
      }
    }, (error) => {
      this.loaderService.hideLoader();
      this.utilityService.unAuthorized(error);
    });
  }

  onClickCollapse = (type: string): void => {
    switch (type) {
      case 'SUBSCRIPTION':
        this.isShowReports = false;
        this.isShowOrders = !this.isShowOrders;
        break;
        case 'TAXES':
          this.isShowReport = false;
          this.isShowOrder = !this.isShowOrder;
          break;
          case 'METADATA':
          this.isShowmeta = false;
          this.isShowOrdermetadata = !this.isShowOrdermetadata;
          break;
          case 'BUSINESS':
            this.isShowbusiness = false;
            this.isShowOrderbusinessdata = !this.isShowOrderbusinessdata;
            break;
            case 'DASHBOARD':
              this.isShowdashboard = false;
              this.isShowdashboarddata = !this.isShowdashboarddata;
              break;
              case 'FUELMASTER':
                this.isShowFUEL = false;
                this.isShowdFUELdata = !this.isShowdFUELdata;
                break;
                case 'REPORTS':
                  this.isShowREPORT = false;
                  this.isShowREPORTdata = !this.isShowREPORTdata;
                  break;
                  case 'CHAT':
                    this.isShowCHAT = false;
                    this.isShowCHATdata = !this.isShowCHATdata;
                    break;
      default:
        break;
    }
  }

}

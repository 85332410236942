<header class="login-bg ">
    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <i class="">
                    <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                    <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px"
                        class="logotext" />
                </i>
            </a>
            <div class="login-cover">
                <h4 class="login-head pb-2">Two Step Verification</h4>

                <p class="veryfy-otp pb-2">Select any one to proceed further</p>

                <form [formGroup]="twoStepVerificationForm" (ngSubmit)="twoStepVerification()">

                    <div class="d-flex align-items-start gap-3 flex-column mt-2">
                        <div class="radio mb-0">
                            <input id="radio-1" name="radio" type="radio" [checked]="isChecked('EMAIL')"
                                (click)="onCheckedChange('EMAIL')">
                            <label for="radio-1" class="radio-label pb-0">
                                Send OTP to {{twoStepVerificationForm.controls['email'].value}}
                            </label>
                        </div>
                        <div class="radio mt-0">
                            <input id="radio-2" name="radio" type="radio" [checked]="isChecked('PHONE')"
                                (click)="onCheckedChange('PHONE')">
                            <label for="radio-2" class="radio-label">
                                Send OTP to {{twoStepVerificationForm.controls['countryCode'].value}}
                                {{twoStepVerificationForm.controls['phoneNumber'].value}}
                            </label>
                        </div>
                        <span class="show-input-error"
                            *ngIf="twoStepVerificationForm.controls['via'].hasError('required') && showError">Please
                            select any one to proceed further</span>
                    </div>
                    <button type="submit" class="btn btn-default btn-hover color-1 mb-3 ">Continue</button>

                </form>
            </div>
        </div>
    </nav>
</header>
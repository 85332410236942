import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { MESSAGE } from 'src/app/constant/message';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-update-booking-status-with-reason',
  templateUrl: './update-booking-status-with-reason.component.html',
  styleUrls: ['./update-booking-status-with-reason.component.css']
})
export class UpdateBookingStatusWithReasonComponent implements OnInit, OnDestroy {

  public onClose: Subject<{}>;
  public formGroup: FormGroup = new FormGroup({
    bookingId: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
    reason: new FormControl('', [Validators.required])
  });
  private formGroupSubscription: Subscription;
  public showError: boolean = false;

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private utilityService: UtilityService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    if (this.options.initialState) {
      const tempData: any = this.options.initialState;
      this.formGroup.patchValue({
        bookingId: (tempData || {}).bookingId || '',
        status: (tempData || {}).status || ''
      });
    }
    this.formGroupSubscription = this.formGroup.valueChanges.subscribe((value) => {
      this.showError = false;
    });
  }

  updateBookingStatusWithReason = (): void => {
    if (this.formGroup.valid) {
      this.loaderService.showLoader();
      this.httpService.postData(ApiUrl.UPDATE_BOOKING_STATUS_WITH_REASON, this.formGroup.getRawValue()).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK) {
          this.messageService.alertWithTimer('success', MESSAGE.UPDATED, '');
          this.closeModalWithYes();
        }
      }, (error) => {
        this.loaderService.hideLoader();
        this.utilityService.unAuthorized(error);
      });
    } else {
      this.showError = true;
    }
  }

  closeModalWithYes = (): void => {
    this.onClose.next({ success: true });
    this._bsModalRef.hide();
  }

  closeModal = (): void => {
    this.onClose.next({ success: false });
    this._bsModalRef.hide();
  }

  ngOnDestroy(): void {
    if (this.formGroupSubscription) {
      this.formGroupSubscription.unsubscribe();
    }
  }

}

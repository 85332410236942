import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.css']
})
export class VerificationCodeComponent implements OnInit, OnDestroy {

  verificationCodeForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    countryCode: new FormControl('+1', [Validators.required]),
    userType: new FormControl(2, [Validators.required]),
    otp: new FormControl('', [Validators.required, Validators.minLength(4)]),
    emailOTPcode: new FormControl('', [Validators.required, Validators.minLength(4)]),
  });
  private verificationCodeFormSubscription: Subscription;
  showError: boolean = false;
  private routeSubscription: Subscription;


  constructor(
    private httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.verificationCodeFormSubscription = this.verificationCodeForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
    this.routeSubscription = this.route.params.subscribe((params) => {
      if (JSON.stringify(params) !== JSON.stringify({})) {
        this.verificationCodeForm.patchValue({
          email: (params || {}).email || '',
          phoneNumber: (params || {}).phoneNumber || '',
          countryCode: (params || {}).countryCode || '+1',
          userType: (params || {}).userType || 2
        });
      }
    });
  }

  verificationCode = (): void => {
    if (this.verificationCodeForm.valid) {
      this.loaderService.showLoader();
      // ApiUrl.VERIFY_OTP
      this.httpService.postData(ApiUrl.V2_VERIFY_OTP, this.verificationCodeForm.getRawValue()).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK && response.data) {
          if (response.data.accessToken && response.data && response.data._id) {
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('companyTrackingId', response.data._id);
          }
          const reference = this;
          this.messageService.alertCallback('success', response.message, '', function () {
            reference.router.navigate(['/profile-setup', {
              userType: response.data.userType,
              phoneNumber: response.data.phoneNumber,
              email: response.data.email,
              countryCode: response.data.countryCode,
              companyName: response.data.companyName
            }]);
          });
        }
      }, (error) => {
        this.loaderService.hideLoader();
      });
    } else {
      this.showError = true;
    }
  }

  resendOTP = (): void => {
    this.loaderService.showLoader();
    const postData = {
      countryCode: this.verificationCodeForm.get('countryCode')?.value,
      phoneNumber: this.verificationCodeForm.get('phoneNumber')?.value,
      email: this.verificationCodeForm.get('email')?.value
    };
    this.httpService.postData(ApiUrl.RESEND_OTP, postData).subscribe((response) => {
      this.loaderService.hideLoader();
      if (response.statusCode == ResponseStatusCode.OK) {
        this.messageService.alert('success', 'OTP has been resent successfully', '');
      }
    }, (error) => {
      this.loaderService.hideLoader();
    });
  }

  ngOnDestroy(): void {
    if (this.verificationCodeFormSubscription) {
      this.verificationCodeFormSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-recurring-order-details',
  templateUrl: './recurring-order-details.component.html',
  styleUrls: ['./recurring-order-details.component.css']
})
export class RecurringOrderDetailsComponent implements OnInit {

  public previousBooking: any[] = [];

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    public utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.previousBooking = [];
    if (this.options.initialState) {
      const tempData: any = this.options.initialState;
      this.previousBooking = tempData['previousBooking'] || [];
    }
  }


  closeModal = (): void => {
    this._bsModalRef.hide();
  }

}

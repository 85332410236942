export const MESSAGE = {
    DELETED: 'Deleted Successfully',
    UPDATED: 'Updated Successfully',
    ADDED: 'Added Successfully',
    BLOCK: 'Blocked Successfully',
    UNBLOCK: 'Unblocked Successfully',
    SAMPLE: 'Sample Downloaded Successfully',
    CSV_DOWNLOADED: 'CSV Downloaded Successfully',
    EMAIL_SEND: 'Email Sent Successfully',
    RESET_PASSWORD: 'Reset Password Sent Successfully',
    EXPIRED: 'EXPIRED Successfully',
    CANCELED: 'Canceled Successfully',
    COMPLETED: 'Completed Successfully',
    REJECT: 'Rejected Successfully',
    APPROVE: 'Approved Successfully',
    SEND: 'Sent Successfully',
}

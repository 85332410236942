import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-profile-setup',
  templateUrl: './profile-setup.component.html',
  styleUrls: ['./profile-setup.component.css']
})
export class ProfileSetupComponent implements OnInit {

  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;

  profileSetupForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    countryCode: new FormControl('+1', [Validators.required]),
    userType: new FormControl(2, [Validators.required]),
    companyName: new FormControl('', [Validators.required]),
    designation: new FormControl('', [Validators.required]),
    original: new FormControl(''),
    thumbnail: new FormControl('')
  });
  private profileSetupFormSubscription: Subscription;
  showError: boolean = false;
  private routeSubscription: Subscription;


  constructor(
    private httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.profileSetupFormSubscription = this.profileSetupForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
    this.routeSubscription = this.route.params.subscribe((params) => {
      if (JSON.stringify(params) !== JSON.stringify({})) {
        this.profileSetupForm.patchValue({
          email: (params || {}).email || '',
          phoneNumber: (params || {}).phoneNumber || '',
          countryCode: (params || {}).countryCode || '+1',
          userType: (params || {}).userType || 2,
          companyName: (params || {}).companyName || ''
        });
      }
    });
  }

  navigateToAccountSetup = (): void => {
    if (this.profileSetupForm.valid) {
      this.router.navigate(['/account-setup', this.profileSetupForm.getRawValue()]);
    } else {
      this.showError = true;
    }
  }

  ngOnDestroy(): void {
    if (this.profileSetupFormSubscription) {
      this.profileSetupFormSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  readUrl = (event: any): void => {
    if (event.target.files && event.target.files[0]) {
      const postData = {
        image: event.target.files[0]
      };
      this.uploadImage(postData);
    }
  }

  uploadImage = (postData: any): void => {
    this.loaderService.showLoader();
    this.httpService.postDataFormData(ApiUrl.UPLOAD_IMAGE, postData).subscribe((response) => {
      this.loaderService.hideLoader();
      if (response.statusCode == ResponseStatusCode.OK) {
        this.profileSetupForm.patchValue({
          original: (response.data || {}).original || '',
          thumbnail: (response.data || {}).thumbnail || ''
        });
      }
    }, (error) => {
      this.loaderService.hideLoader();
    });

  }

  profileImageClick() {
    this.fileInput.nativeElement.click();
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-date-range',
  templateUrl: './dashboard-date-range.component.html',
  styleUrls: ['./dashboard-date-range.component.css']
})
export class DashboardDateRangeComponent implements OnInit, OnDestroy {

  public onClose: Subject<{}>;

  public dateRangeForm: FormGroup = new FormGroup({
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required])
  });
  public dateRangeFormSubscription: Subscription;
  public showError: boolean = false;

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    if (this.options.initialState) {
      // const tempData: any = this.options.initialState;
      // this.vehicleData = tempData['vehicleData'] || null;
    }
    this.dateRangeFormSubscription = this.dateRangeForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
  }

  closeModalWithYes = (): void => {
    if (this.dateRangeForm.valid) {
      this.showError = false;
      this.onClose.next({ success: true, data: this.dateRangeForm.getRawValue() });
      this._bsModalRef.hide();
    } else {
      this.showError = true;
    }
  }

  closeModal = (): void => {
    this.onClose.next({ success: false });
    this._bsModalRef.hide();
  }

  ngOnDestroy(): void {
    if (this.dateRangeFormSubscription) {
      this.dateRangeFormSubscription.unsubscribe();
    }
  }

}

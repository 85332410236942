export class GetState {
    convertLongStateToShortAndShortToLong = (state: string, conversionType: string): any => {
        state = state.trim();
        if (conversionType === "short") {
            if (state.length > 2) {
                switch (true) {
                    case /^alabama$/i.test(state):
                        return "AL";
                    case /^alaska$/i.test(state):
                        return "AK";
                    case /^arizona$/i.test(state):
                        return "AZ";
                    case /^arkansas$/i.test(state):
                        return "AR";
                    case /^california$/i.test(state):
                        return "CA";
                    case /^colorado$/i.test(state):
                        return "CO";
                    case /^connecticut$/i.test(state):
                        return "CT";
                    case /^delaware$/i.test(state):
                        return "DE";
                    case /^florida$/i.test(state):
                        return "FL";
                    case /^georgia$/i.test(state):
                        return "GA";
                    case /^hawaii$/i.test(state):
                        return "HI";
                    case /^idaho$/i.test(state):
                        return "ID";
                    case /^illinois$/i.test(state):
                        return "IL";
                    case /^indiana$/i.test(state):
                        return "IN";
                    case /^iowa$/i.test(state):
                        return "IA";
                    case /^kansas$/i.test(state):
                        return "KS";
                    case /^kentucky$/i.test(state):
                        return "KY";
                    case /^louisiana$/i.test(state):
                        return "LA";
                    case /^maine$/i.test(state):
                        return "ME";
                    case /^maryland$/i.test(state):
                        return "MD";
                    case /^massachusetts$/i.test(state):
                        return "MA";
                    case /^michigan$/i.test(state):
                        return "MI";
                    case /^minnesota$/i.test(state):
                        return "MN";
                    case /^mississippi$/i.test(state):
                        return "MS";
                    case /^missouri$/i.test(state):
                        return "MO";
                    case /^montana$/i.test(state):
                        return "MT";
                    case /^nebraska$/i.test(state):
                        return "NE";
                    case /^nevada$/i.test(state):
                        return "NV";
                    case /^new hampshire$/i.test(state):
                        return "NH";
                    case /^new jersey$/i.test(state):
                        return "NJ";
                    case /^new mexico$/i.test(state):
                        return "NM";
                    case /^new york$/i.test(state):
                        return "NY";
                    case /^north carolina$/i.test(state):
                        return "NC";
                    case /^north dakota$/i.test(state):
                        return "ND";
                    case /^ohio$/i.test(state):
                        return "OH";
                    case /^oklahoma$/i.test(state):
                        return "OK";
                    case /^oregon$/i.test(state):
                        return "OR";
                    case /^pennsylvania$/i.test(state):
                        return "PA";
                    case /^rhode island$/i.test(state):
                        return "RI";
                    case /^south carolina$/i.test(state):
                        return "SC";
                    case /^south dakota$/i.test(state):
                        return "SD";
                    case /^tennessee$/i.test(state):
                        return "TN";
                    case /^texas$/i.test(state):
                        return "TX";
                    case /^utah$/i.test(state):
                        return "UT";
                    case /^vermont$/i.test(state):
                        return "VT";
                    case /^virginia$/i.test(state):
                        return "VA";
                    case /^washington$/i.test(state):
                        return "WA";
                    case /^west virginia$/i.test(state):
                        return "WV";
                    case /^wisconsin$/i.test(state):
                        return "WI";
                    case /^wyoming$/i.test(state):
                        return "WY";
                    case /^district of columbia$/i.test(state):
                        return "DC";
                    case /^puerto rico$/i.test(state):
                        return "PR";
                    case /^guam$/i.test(state):
                        return "GU";
                    case /^american samoa$/i.test(state):
                        return "AS";
                    case /^u\.?s\.? virgin islands$/i.test(state):
                        return "VI";
                    case /^northern mariana islands$/i.test(state):
                        return "MP";
                    case /^armed forces europe$/i.test(state):
                        return "AE";
                    case /^armed forces americas$/i.test(state):
                        return "AA";
                    case /^armed forces pacific$/i.test(state):
                        return "AP";
                    default:
                        return state;
                }
            } else {
                return state.toUpperCase();
            }
        } else if (conversionType === "long") {
            if (state.length === 2) {
                switch (true) {
                    case /^AL$/i.test(state):
                        return "Alabama";
                    case /^AK$/i.test(state):
                        return "Alaska";
                    case /^AZ$/i.test(state):
                        return "Arizona";
                    case /^AR$/i.test(state):
                        return "Arkansas";
                    case /^CA$/i.test(state):
                        return "California";
                    case /^CO$/i.test(state):
                        return "Colorado";
                    case /^CT$/i.test(state):
                        return "Connecticut";
                    case /^DE$/i.test(state):
                        return "Delaware";
                    case /^FL$/i.test(state):
                        return "Florida";
                    case /^GA$/i.test(state):
                        return "Georgia";
                    case /^HI$/i.test(state):
                        return "Hawaii";
                    case /^ID$/i.test(state):
                        return "Idaho";
                    case /^IL$/i.test(state):
                        return "Illinois";
                    case /^IN$/i.test(state):
                        return "Indiana";
                    case /^IA$/i.test(state):
                        return "Iowa";
                    case /^KS$/i.test(state):
                        return "Kansas";
                    case /^KY$/i.test(state):
                        return "Kentucky";
                    case /^LA$/i.test(state):
                        return "Louisiana";
                    case /^ME$/i.test(state):
                        return "Maine";
                    case /^MD$/i.test(state):
                        return "Maryland";
                    case /^MA$/i.test(state):
                        return "Massachusetts";
                    case /^MI$/i.test(state):
                        return "Michigan";
                    case /^MN$/i.test(state):
                        return "Minnesota";
                    case /^MS$/i.test(state):
                        return "Mississippi";
                    case /^MO$/i.test(state):
                        return "Missouri";
                    case /^MT$/i.test(state):
                        return "Montana";
                    case /^NE$/i.test(state):
                        return "Nebraska";
                    case /^NV$/i.test(state):
                        return "Nevada";
                    case /^NH$/i.test(state):
                        return "New Hampshire";
                    case /^NJ$/i.test(state):
                        return "New Jersey";
                    case /^NM$/i.test(state):
                        return "New Mexico";
                    case /^NY$/i.test(state):
                        return "New York";
                    case /^NC$/i.test(state):
                        return "North Carolina";
                    case /^ND$/i.test(state):
                        return "North Dakota";
                    case /^OH$/i.test(state):
                        return "Ohio";
                    case /^OK$/i.test(state):
                        return "Oklahoma";
                    case /^OR$/i.test(state):
                        return "Oregon";
                    case /^PA$/i.test(state):
                        return "Pennsylvania";
                    case /^RI$/i.test(state):
                        return "Rhode Island";
                    case /^SC$/i.test(state):
                        return "South Carolina";
                    case /^SD$/i.test(state):
                        return "South Dakota";
                    case /^TN$/i.test(state):
                        return "Tennessee";
                    case /^TX$/i.test(state):
                        return "Texas";
                    case /^UT$/i.test(state):
                        return "Utah";
                    case /^VT$/i.test(state):
                        return "Vermont";
                    case /^VA$/i.test(state):
                        return "Virginia";
                    case /^WA$/i.test(state):
                        return "Washington";
                    case /^WV$/i.test(state):
                        return "West Virginia";
                    case /^WI$/i.test(state):
                        return "Wisconsin";
                    case /^WY$/i.test(state):
                        return "Wyoming";
                    case /^DC$/i.test(state):
                        return "District of Columbia";
                    case /^PR$/i.test(state):
                        return "Puerto Rico";
                    case /^GU$/i.test(state):
                        return "Guam";
                    case /^AS$/i.test(state):
                        return "American Samoa";
                    case /^VI$/i.test(state):
                        return "U.S. Virgin Islands";
                    case /^MP$/i.test(state):
                        return "Northern Mariana Islands";
                    case /^AE$/i.test(state):
                        return "Armed Forces Europe";
                    case /^AA$/i.test(state):
                        return "Armed Forces Americas";
                    case /^AP$/i.test(state):
                        return "Armed Forces Pacific";
                    default:
                        return state;
                }
            } else {
                return state;
            }
        }
    }

}



import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from '../models/loader-state';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  /**  
  * Constructs the subject from rxjs as @Interface LoaderState.
  * @return An `Observable` of the @Interface LoaderState.
  */
  private loaderSubject = new Subject<LoaderState>();
  public loaderState = this.loaderSubject.asObservable();

  constructor() { }

  /**  
  * Showing the loader to the page.
  */
  showLoader = (): void => {
    this.loaderSubject.next(<LoaderState>{ show: true });
  }

  /**  
  * Hide the loader from the page.
  */
  hideLoader = (): void => {
    this.loaderSubject.next(<LoaderState>{ show: false });
  }

}

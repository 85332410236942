<header class="login-bg ">
    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <!-- <img
                    src="./../../../assets/images/login-flow-images/logo-with-title-purple.svg"> -->
                    <i class="">
                        <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                        <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px" class="logotext"/>
                    </i>
                </a>
            <div class="login-cover">
                <h4 class="login-head pb-3">Profile Setup</h4>
                <div class="edit-image">
                    <div class="edit-size">
                        <img src="./../../../assets/images/login-flow-images/image 3.png" class="img-fluid round-shape"
                        *ngIf="profileSetupForm.get('original')?.value == '' ">
                        <img src="{{profileSetupForm.get('original')?.value}}" class="img-fluid round-shape"
                        *ngIf="profileSetupForm.get('original')?.value != '' ">

                        <img src="./../../../assets/images/login-flow-images/Ellipse-1.png" class="img-fluid edit-pos"
                        (click)="profileImageClick()">

                        <input type="file" id="fileInput" name="original" accept="image/*" (change)="readUrl($event)" 
                        style="display: none;cursor: pointer;" #fileInput>

                    </div>
                </div>

                <form  [formGroup]="profileSetupForm" (ngSubmit)="navigateToAccountSetup()">
                    <div class="mb-3">
                        <label for="email">Business Name</label>
                        <input type="text" class="form-control" id="companyName" placeholder="Enter your Business Name"
                            name="companyName" autocomplete="off" formControlName="companyName">
                            <span class="show-input-error"
                            *ngIf="profileSetupForm.controls['companyName'].hasError('required') && showError">Please enter
                            your Business Name</span>
                    </div>
                    <div class="mb-3">
                        <label for="pwd">Designation </label>
                        <input type="text" class="form-control" id="designation" placeholder="Enter your Designation"
                            name="designation"  autocomplete="off" formControlName="designation">
                            <span class="show-input-error"
                            *ngIf="profileSetupForm.controls['designation'].hasError('required') && showError">Please enter
                            your Designation</span>
                    </div>

                    <button type="submit" class="btn btn-default btn-hover color-1">Next</button>
                </form>
            </div>
        </div>
    </nav>
</header>
<div style="background-color: #07070a;">
    <section class="section_transaction">
        <div class="col-lg-12">

            <div class="row">
                <a href="javascript:void(0)" class="logo-position">
                    <i><img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px">
                        <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px"
                            class="logotext">
                    </i>
                </a>
                <h1>Try Gas It Up Workspace for 90 days.</h1>
                <div class="pricing__table">

                    <div class="pt__title">
                        <div class="pt__title__wrap">
                            <div class="pt__row"><b>Deliverables</b></div>
                            <div class="pt__row"><b>Trial</b></div>
                            <div class="pt__row"><b>Standard</b></div>
                            <div class="pt__row"><b>Premium</b></div>
                            <div class="pt__row"><b>Custom</b></div>
                        </div>
                    </div>
                    <div class="pt__option">
                        <div class="pt__option__mobile__nav">
                            <a id="pricing-card-left" href="#" class="mobile__nav__btn">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.1538 11.9819H1.81972" stroke="currentColor" stroke-width="3"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.9863 22.1535L1.82043 11.9865L11.9863 1.81946" stroke="currentColor"
                                        stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </a>
                            <a id="pricing-card-right" href="#" class="mobile__nav__btn">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.81934 11.9819H22.1534" stroke="currentColor" stroke-width="3"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.9863 22.1535L22.1522 11.9865L11.9863 1.81946" stroke="currentColor"
                                        stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </a>
                        </div>
                        <div class="pt__option__slider swiper" id="pricing-card-slider">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Dispatcher</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Admin Panel</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Business Panel</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">User App</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Driver App</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">DTN</div>
                                            <div class="pt__row">No</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Terminal Access</div>
                                            <div class="pt__row">No</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Bidding Process</div>
                                            <div class="pt__row">No</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Third party gas station data</div>
                                            <div class="pt__row">No</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">(To be defined OPIS)</div>
                                            <div class="pt__row">No</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Fuel Master (3rd Part Data Push)</div>
                                            <div class="pt__row">No</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Price USD</div>
                                            <div class="pt__row">$0</div>
                                            <div class="pt__row">$3,000</div>
                                            <div class="pt__row">$5,000</div>
                                            <div class="pt__row">TBD</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Truck (Fleet)</div>
                                            <div class="pt__row">2</div>
                                            <div class="pt__row">15</div>
                                            <div class="pt__row">25</div>
                                            <div class="pt__row">Custom</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">24/7 Email & Chat Support</div>
                                            <div class="pt__row">Email Support</div>
                                            <div class="pt__row">24/7 Email & Chat Support</div>
                                            <div class="pt__row">Phone & Priority Support<span style="font-size: 14px;">
                                                    2 Hours of Support for
                                                    free Monthly <br> there after we charge every hour</span></div>
                                            <div class="pt__row">TBD</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Reporting & Analytics <span
                                                    style="font-size: 14px;">(Hype for reports with an
                                                    objective of upgrading the subscription plan)</span></div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Yes</div>
                                            <div class="pt__row">Unlimited</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Users</div>
                                            <div class="pt__row">2</div>
                                            <div class="pt__row">10</div>
                                            <div class="pt__row">15</div>
                                            <div class="pt__row">TBD</div>
                                        </div>
                                    </div>
                                </div>




                                <div class="swiper-slide pt__option__item">
                                    <div class="pt__item selected">
                                        <div class="pt__item__wrap">
                                            <div class="pt__row">Get started</div>
                                            <div class="pt__row"><a href="/payment-subscription-plans/4">Subscribe Now</a>
                                            </div>
                                            <div class="pt__row"><a href="/payment-subscription-plans/4">Subscribe Now</a>
                                            </div>
                                            <div class="pt__row"><a href="/payment-subscription-plans/5">Subscribe Now</a>
                                            </div>
                                            <div class="pt__row"><a href="/payment-subscription-plans/6">Subscribe Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</div>
<header class="login-bg ">
    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <!-- <img
                    src="./../../../assets/images/login-flow-images/logo-with-title-purple.svg"> -->
                    <i class="">
                        <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                        <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px" class="logotext"/>
                    </i>
                </a>
            <div class="login-cover">
                <h4 class="login-head pb-2">Verification Code</h4>
                
                <p class="veryfy-otp pb-57">Please verify a 4 digit OTP (one-time password) sent to
                    your Phone number and Email-ID</p>
                <form [formGroup]="verificationCodeForm" (ngSubmit)="verificationCode()">
                    <div class="mb-3">
                        <label for="email">OTP Sent to your Phone Number</label>
                        <input type="text" class="form-control" id="otp" placeholder="Enter your OTP" name="otp"
                            autocomplete="off" formControlName="otp">
                        <span class="show-input-error"
                            *ngIf="verificationCodeForm.controls['otp'].hasError('required') && showError">Please enter
                            your OTP</span>
                        <span class="show-input-error" *ngIf="!verificationCodeForm.controls['otp'].hasError('required') 
                        && verificationCodeForm.controls['otp'].hasError('minlength') && showError">OTP length must be
                            of 4 characters</span>
                    </div>
                    <div class="mb-3">
                        <label for="pwd">OTP Sent to Email-ID </label>
                        <input type="text" class="form-control" id="emailOTPcode" placeholder="Enter your OTP" name="emailOTPcode"
                        autocomplete="off" formControlName="emailOTPcode">
                        <span class="show-input-error"
                            *ngIf="verificationCodeForm.controls['emailOTPcode'].hasError('required') && showError">Please enter
                            your OTP</span>
                        <span class="show-input-error" *ngIf="!verificationCodeForm.controls['emailOTPcode'].hasError('required') 
                        && verificationCodeForm.controls['emailOTPcode'].hasError('minlength') && showError">OTP length must be
                            of 4 characters</span>
                    </div>

                    <button type="submit" class="btn btn-default btn-hover color-1 mb-3 ">Verify</button>
                    <p class="acc-create">Didn't receive the OTP yet?<a href="javascript:void(0)"
                            (click)="resendOTP()"> Resend OTP</a></p>
                </form>
            </div>
        </div>
    </nav>
</header>
import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-trucks-and-costs-details',
  templateUrl: './trucks-and-costs-details.component.html',
  styleUrls: ['./trucks-and-costs-details.component.css']
})
export class TrucksAndCostsDetailsComponent implements OnInit {

  public data: any[] = [];

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions
  ) { }

  ngOnInit(): void {
    this.data = [];
    if (this.options.initialState) {
      const tempData: any = this.options.initialState;
      this.data = tempData['data'] || [];
    }
  }

  closeModal = (): void => {
    this._bsModalRef.hide();
  }

}

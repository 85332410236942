<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">
                <!--Recurring-->
                <img src="./../../../../assets/images/login-flow-images/Group.png" class="pe-2">
                Automatic Fuel Delivery
            </div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="./../../../../assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>

        <div class="p-30 popup-input popup-input-date">
            <form [formGroup]="recurringFuelDeliveryForm" (ngSubmit)="closeModalWithYes()">

                <div class="mb-3">
                    <label for="date">Date</label>
                    <div class="ui calendar" id="date">
                        <div class="icon-place ui input left icon">
                            <input type="date" class="form-control" id="date" placeholder="" name="date"
                                autocomplete="off" formControlName="date"
                                [min]="recurringFuelDeliveryForm.controls['date']?.value">
                            <!-- <img src="./../../../../assets/images/login-flow-images/calender.png" class="icon-pos"> -->
                        </div>
                    </div>
                    <span class="show-input-error"
                        *ngIf="recurringFuelDeliveryForm.controls['date'].hasError('required') && showError">Please
                        select date</span>
                </div>

                <div class="mb-3">
                    <label for="time">Time</label>
                    <div class="ui calendar" id="time">
                        <div class="icon-place ui input left icon">
                            <input type="time" class="form-control" id="time" placeholder="" name="time"
                                autocomplete="off" formControlName="time">
                            <!-- <img src="./../../../../assets/images/login-flow-images/Vector (1).png" class="icon-pos"> -->
                        </div>
                    </div>
                    <span class="show-input-error"
                        *ngIf="recurringFuelDeliveryForm.controls['time'].hasError('required') && showError">Please
                        select time</span>
                </div>

                <div class="mb-3">
                    <label for="recurringType">Recurring Type</label>
                    <div class="styled-select">
                        <select class="form-select" id="recurringType" name="recurringType"
                            formControlName="recurringType" (change)="onChangeRecurringType()">
                            <option value="" disabled>Select Type</option>
                            <option [value]="data?.value" *ngFor="let data of recurringTypeList">
                                {{data?.name}}
                            </option>
                        </select>
                    </div>
                    <span class="show-input-error"
                        *ngIf="recurringFuelDeliveryForm.controls['recurringType'].hasError('required') && showError">Please
                        select recurring type</span>
                </div>

                <div class="mb-3" *ngIf="recurringFuelDeliveryForm.controls['recurringType']?.value == 'DAILY' ">
                    <label for="dailyCount">For How Many Days?</label>
                    <div class="ui calendar" id="dailyCount">
                        <div class="icon-place ui input left icon">
                            <input type="text" class="form-control" id="dailyCount" placeholder="Enter Days"
                                name="dailyCount" autocomplete="off" formControlName="dailyCount" appOnlyNumber>
                            <span class="text-14"><b>Note:</b> Orders can be set to recur for up to 90 days</span>
                        </div>
                    </div>
                    <span class="show-input-error"
                        *ngIf="recurringFuelDeliveryForm.controls['dailyCount'].hasError('required') && showError">Please
                        enter days</span>
                    <span class="show-input-error" *ngIf="recurringFuelDeliveryForm.controls['dailyCount'].hasError('min') && 
                        !recurringFuelDeliveryForm.controls['dailyCount'].hasError('required') && showError">
                        Days must be at least 1
                    </span>
                </div>

                <div class="mb-3" *ngIf="recurringFuelDeliveryForm.controls['recurringType']?.value == 'WEEKLY' ">
                    <label for="daysCount">Days</label>
                    <div class="row">
                        <div class="col-md-3 weekdays_ul" *ngFor="let data of daysList"
                            (click)="data.isActive = !data.isActive">
                            <span [ngClass]="data.isActive ? 'active' : ''">{{data.name}}</span>
                        </div>
                    </div>
                </div>

                <div class="mb-3" *ngIf="recurringFuelDeliveryForm.controls['recurringType']?.value == 'WEEKLY' ">
                    <label for="weeklyCount">For How Many Weeks?</label>
                    <div class="ui calendar" id="weeklyCount">
                        <div class="icon-place ui input left icon">
                            <input type="text" class="form-control" id="weeklyCount" placeholder="Enter Weeks"
                                name="weeklyCount" autocomplete="off" formControlName="weeklyCount" appOnlyNumber>
                                <span class="text-14"><b>Note:</b> Orders can be set to recur for up to 12 weeks</span>

                        </div>
                    </div>
                    <span class="show-input-error"
                        *ngIf="recurringFuelDeliveryForm.controls['weeklyCount'].hasError('required') && showError">Please
                        enter weeks</span>
                    <span class="show-input-error" *ngIf="recurringFuelDeliveryForm.controls['weeklyCount'].hasError('min') &&
                        !recurringFuelDeliveryForm.controls['weeklyCount'].hasError('required') && showError">
                        Weeks must be at least 1
                    </span>
                </div>

                <div class="mb-4 pb-2" *ngIf="recurringFuelDeliveryForm.controls['recurringType']?.value == 'MONTHLY' ">
                    <label for="monthlyCount">For How Many Months?</label>
                    <div class="ui calendar" id="monthlyCount">
                        <div class="icon-place ui input left icon">
                            <input type="text" class="form-control" id="monthlyCount" placeholder="Enter Months"
                                name="monthlyCount" autocomplete="off" formControlName="monthlyCount" appOnlyNumber>
                            <span class="text-14"><b>Note:</b> Orders can be set to recur for up to 3 months</span>

                        </div>
                    </div>
                    <span class="show-input-error"
                        *ngIf="recurringFuelDeliveryForm.controls['monthlyCount'].hasError('required') && showError">Please
                        enter months</span>
                    <span class="show-input-error" *ngIf="recurringFuelDeliveryForm.controls['monthlyCount'].hasError('min') 
                        && !recurringFuelDeliveryForm.controls['monthlyCount'].hasError('required') && showError">
                        Months must be at least 1
                    </span>
                </div>

                <button type="submit" class="btn btn-default btn-hover color-1 text-uppercase mt-2">Next</button>

            </form>
        </div>

    </div>
</div>
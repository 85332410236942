<header class="login-bg " *ngIf="!contactPersonFlag && !businessAddressFlag">
    <!-- <header class="login-bg "> -->

    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <!-- <img
                    src="./../../../assets/images/login-flow-images/logo-with-title-purple.svg"> -->
                <i class="">
                    <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                    <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px"
                        class="logotext" />
                </i>
            </a>
            <div class="login-cover">
                <h4 class="login-head" style="padding-bottom: 10px !important;">Signup</h4>
                <form [formGroup]="signUpForm" (ngSubmit)="signUp()">

                    <div class="mb-3">
                        <label for="businessName">Business Name</label>
                        <input type="text" class="form-control" id="businessName" placeholder="Enter your Business Name"
                            name="businessName" autocomplete="off" formControlName="businessName">
                        <span class="show-input-error"
                            *ngIf="signUpForm.controls['businessName'].hasError('required') && showError">Please enter
                            your Business Name</span>
                    </div>

                    <div class="mb-3">
                        <label for="businessEmail">E-mail</label>
                        <input type="text" class="form-control" id="businessEmail" placeholder="Enter your E-mail"
                            name="businessEmail" autocomplete="off" formControlName="businessEmail">
                        <span class="show-input-error"
                            *ngIf="signUpForm.controls['businessEmail'].hasError('required') && showError">Please enter
                            your
                            E-mail</span>
                        <span class="show-input-error" *ngIf="!signUpForm.controls['businessEmail'].hasError('required') 
                            && signUpForm.controls['businessEmail'].hasError('businessEmail') && showError">Please
                            enter valid
                            E-mail</span>
                    </div>

                    <div class="mb-3">
                        <label for="businessPhone">Mobile Number</label>
                        <input type="text" class="form-control" id="businessPhone"
                            placeholder="Enter your Mobile number" name="businessPhone" autocomplete="off"
                            formControlName="businessPhone">
                        <span class="show-input-error"
                            *ngIf="signUpForm.controls['businessPhone'].hasError('required') && showError">Please enter
                            your Mobile Number</span>
                    </div>

                    <div class="mb-3">
                        <label for="businessPassword">Password</label>
                        <div class="position-relative">
                            <input id="businessPassword" type="{{passwordType}}" class="form-control"
                                name="businessPassword" autocomplete="off" formControlName="businessPassword"
                                placeholder="Enter your Password">
                            <span toggle="#businessPassword" class="{{passwordTypeClass}}"
                                (click)="changePasswordType()" style="cursor: pointer;"></span>
                        </div>
                        <span class="show-input-error"
                            *ngIf="signUpForm.controls['businessPassword'].hasError('required') && showError">Please
                            enter your
                            Password</span>
                        <span class="show-input-error" *ngIf="!signUpForm.controls['businessPassword'].hasError('required') 
                        && signUpForm.controls['businessPassword'].hasError('minlength') && showError">Password length
                            must be
                            at least 6 characters long</span>
                    </div>

                    <!-- <div class="mb-3">
                        <label for="referBy">Referral Code (Optional)</label>
                        <input type="text" class="form-control" id="referBy" placeholder="Enter your Referral Code "
                            name="referBy" autocomplete="off" formControlName="referBy">
                    </div> -->

                    <button type="submit" class="btn btn-default btn-hover color-1 mb-3 ">Next</button>
                    <!-- <p class="acc-create">Already have an account?<a href="javascript:void(0)"
                            [routerLink]="['/login']"> Login</a></p> -->
                </form>
            </div>
        </div>
    </nav>
</header>


<!-- Contact Person -->
<header class="login-bg " *ngIf="contactPersonFlag && !businessAddressFlag">
    <!-- <header class="login-bg "> -->

    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <i class="">
                    <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                    <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px"
                        class="logotext" />
                </i>
            </a>
            <div class="login-cover">
                <h4 class="login-head" style="padding-bottom: 10px !important;">Contact Person</h4>
                <form [formGroup]="contactPersonForm" (ngSubmit)="contactPersonSubmit()">
                    <div class="mb-3">
                        <label for="businessContactPersonFirstName">First Name</label>
                        <input type="text" class="form-control" id="businessContactPersonFirstName"
                            placeholder="Enter your First Name" name="businessContactPersonFirstName" autocomplete="off"
                            formControlName="businessContactPersonFirstName">
                        <span class="show-input-error"
                            *ngIf="contactPersonForm.controls['businessContactPersonFirstName'].hasError('required') && showError">Please
                            enter
                            your First Name</span>
                    </div>
                    <div class="mb-3">
                        <label for="businessContactPersonLastName">Last Name</label>
                        <input type="text" class="form-control" id="businessContactPersonLastName"
                            placeholder="Enter your Last Name" name="businessContactPersonLastName" autocomplete="off"
                            formControlName="businessContactPersonLastName">
                        <span class="show-input-error"
                            *ngIf="contactPersonForm.controls['businessContactPersonLastName'].hasError('required') && showError">Please
                            enter
                            your Last Name</span>
                    </div>

                    <div class="mb-3">
                        <label for="businessContactPersonEmail">Contact E-mail</label>
                        <input type="text" class="form-control" id="businessContactPersonEmail"
                            placeholder="Enter your E-mail" name="businessContactPersonEmail" autocomplete="off"
                            formControlName="businessContactPersonEmail">
                        <span class="show-input-error"
                            *ngIf="contactPersonForm.controls['businessContactPersonEmail'].hasError('required') && showError">Please
                            enter
                            your
                            E-mail</span>
                        <span class="show-input-error"
                            *ngIf="!contactPersonForm.controls['businessContactPersonEmail'].hasError('required') 
                            && contactPersonForm.controls['businessContactPersonEmail'].hasError('businessContactPersonEmail') && showError">Please
                            enter valid
                            E-mail</span>
                    </div>

                    <div class="mb-3">
                        <label for="businessContactPersonPhone">Contact Mobile Number</label>
                        <input type="text" class="form-control" id="businessContactPersonPhone"
                            placeholder="Enter your Mobile number" name="businessContactPersonPhone" autocomplete="off"
                            formControlName="businessContactPersonPhone">
                        <span class="show-input-error"
                            *ngIf="contactPersonForm.controls['businessContactPersonPhone'].hasError('required') && showError">Please
                            enter
                            your Mobile Number</span>
                    </div>

                    <div class="d-flex justify-content-between">
                        <button type="submit" class="btn w-25 btn-default btn-hover color-1 mb-3 "
                            (click)="backToBusiness()">Back</button>
                        <button type="submit" class="btn w-25 btn-default btn-hover color-1 mb-3 ">Next</button>
                    </div>
                    <!-- <p class="acc-create">Already have an account?<a href="javascript:void(0)"
                            [routerLink]="['/login']"> Login</a></p> -->
                </form>
            </div>
        </div>
    </nav>
</header>

<!-- ADDRESS -->
<header class="login-bg" *ngIf="businessAddressFlag && !contactPersonFlag">
    <!-- <header class="login-bg"> -->

    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <i class="">
                    <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                    <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px"
                        class="logotext" />
                </i>
            </a>
            <div class="login-cover">
                <h4 class="login-head" style="padding-bottom: 10px !important;">Account Setup</h4>
                <form [formGroup]="addressSetupForm" (ngSubmit)="addressSetup()">

                    <div class="mb-3">
                        <label for="searchAddress">Want to search for an address.</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" id="searchAddress"
                                placeholder="Use me for google search" name="searchAddress" autocomplete="off"
                                formControlName="searchAddress" appGooglePlace (selectedData)="searchAddress($event)">
                            <span class="input-group-text" id="basic-addon1"
                                style="border: 0; background-color: #f8f2f8;cursor: pointer;"
                                (click)="getCurrentPosition()">
                                <i><img src="./../../../assets/images/login-flow-images/location-marker-icon.png" alt=""
                                        width="20px" /></i>
                            </span>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="businessAddress1">Address1*</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" id="businessAddress1"
                                placeholder="Enter your Street address1" name="businessAddress1" autocomplete="off"
                                formControlName="businessAddress1">
                        </div>
                        <span class="show-input-error"
                            *ngIf="addressSetupForm.controls['businessAddress1'].hasError('required') && showError">Please
                            enter
                            your Street Address</span>
                    </div>

                    <div class="mb-3">
                        <label for="businessAddress2">Address2</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" id="businessAddress2"
                                placeholder="Enter your Street businessAddress2" name="businessAddress2"
                                autocomplete="off" formControlName="businessAddress2">
                        </div>
                    </div>


                    <div class="mb-3">
                        <label for="businessZipCode">Postal Code</label>
                        <input type="text" class="form-control" id="businessZipCode" placeholder="Enter your Zip Code"
                            name="businessZipCode" autocomplete="off" formControlName="businessZipCode">
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="businessCountry">Country*</label>

                                <input type="text" class="form-control" id="businessCountry"
                                    placeholder="Enter your country" name="businessCountry" autocomplete="off"
                                    formControlName="businessCountry">
                                <span class="show-input-error"
                                    *ngIf="addressSetupForm.controls['businessCountry'].hasError('required') && showError">
                                    Please select Country
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="businessState">State*</label>

                                <input type="text" class="form-control" id="businessState"
                                    placeholder="Enter your state" name="businessState" autocomplete="off"
                                    formControlName="businessState">
                                <span class="show-input-error"
                                    *ngIf="addressSetupForm.controls['businessState'].hasError('required') && showError">
                                    Please select State
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="businessCity">City (Locality)*</label>
                                <input type="text" class="form-control" id="businessCity" placeholder="Enter your City"
                                    name="businessCity" autocomplete="off" formControlName="businessCity">
                                <span class="show-input-error"
                                    *ngIf="addressSetupForm.controls['businessCity'].hasError('required') && showError">
                                    Please enter city
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="businessCounty">County</label>
                                <input type="text" class="form-control" id="businessCounty"
                                    placeholder="Enter your County" name="businessCounty" autocomplete="off"
                                    formControlName="businessCounty">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button type="submit" class="btn w-25 btn-default btn-hover color-1 mt-2 "
                            (click)="backToContact()">Back</button>
                        <button type="submit" class="btn w-25 btn-default btn-hover color-1 mt-2 ">Next</button>
                    </div>
                </form>
            </div>
        </div>
    </nav>
</header>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { MESSAGE } from 'src/app/constant/message';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';
import * as moment from 'moment';

@Component({
  selector: 'app-manual-delivery-date',
  templateUrl: './manual-delivery-date.component.html',
  styleUrls: ['./manual-delivery-date.component.css']
})
export class ManualDeliveryDateComponent implements OnInit, OnDestroy {

  public onClose: Subject<{}>;
  public formGroup: FormGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    time: new FormControl(null, [Validators.required]),
    bookingNo: new FormControl('', [Validators.required])
  });
  public minSelectDate: any = moment().subtract(0, 'days').format("yyyy-MM-DD");
  public showError: boolean = false;
  private formGroupSubscription: Subscription;

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private utilityService: UtilityService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    if (this.options.initialState) {
      const tempData: any = this.options.initialState;
      this.formGroup.patchValue({
        bookingNo: tempData['bookingNo'] || ''
      });
    }
    this.formGroupSubscription = this.formGroup.valueChanges.subscribe((value) => {
      this.showError = false;
    });
  }

  addDeliveryDateManualy = (): void => {
    if (this.formGroup.valid) {
      this.loaderService.showLoader();
      const postData: any = this.formGroup.getRawValue();
      const startTime = moment(postData.date).format("MM/DD/YYYY");
      const startTimeSecond = (startTime + ' ' + postData.time);
      postData['manualDeliveryDate'] = (new Date(startTimeSecond)).getTime();
      delete postData.date;
      delete postData.time;
      this.httpService.postData(ApiUrl.ADD_DELIVERY_DATE_MANUALY, postData).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK) {
          this.messageService.alertWithTimer('success', MESSAGE.UPDATED, '');
          this.closeModalWithYes();
        }
      }, (error) => {
        this.loaderService.hideLoader();
        this.utilityService.unAuthorized(error);
      });
    } else {
      this.showError = true;
    }
  }

  closeModalWithYes = (): void => {
    this.onClose.next({ success: true });
    this._bsModalRef.hide();
  }

  closeModal = (): void => {
    this.onClose.next({ success: false });
    this._bsModalRef.hide();
  }

  ngOnDestroy(): void {
    if (this.formGroupSubscription) {
      this.formGroupSubscription.unsubscribe();
    }
  }

}

<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">
                <img src="./../../../../assets/images/login-flow-images/Group 791096.png" class="pe-2">Delete
            </div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close"><img
                        src="./../../../../assets/images/login-flow-images/Group 791063.png" (click)="closeModal()"></a>
            </div>
        </div>

        <div class="p-20 popup-input ">
            <form>
                <h2 class="delet-card">Are you sure you want to <br>Delete this {{text}}?</h2>
                <div class="row">
                    <div class="col-md-6">
                        <button type="button" class="btn btn-default update-btn" (click)="closeModal()">No, Keep
                            it</button>
                    </div>
                    <div class="col-md-6">
                        <button type="button" class="btn btn-default  yes-btn"
                            (click)="closeModalWithYes()">Yes</button>
                    </div>
                </div>

            </form>
        </div>

    </div>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';

declare var google: any;

@Component({
  selector: 'app-add-new-bank',
  templateUrl: './add-new-bank.component.html',
  styleUrls: ['./add-new-bank.component.css']
})
export class AddNewBankComponent implements OnInit, OnDestroy {

  public bankForm: FormGroup = new FormGroup({
    accountNumber: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]),
    confirmAccountNumber: new FormControl('', [Validators.required]),
    accountType: new FormControl('', [Validators.required]),
    routingNumber: new FormControl('', [Validators.required]),
    bankHolderName: new FormControl('', [Validators.required]),
    bankName: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required])
  });
  private bankFormSubscription: Subscription;
  public showError: boolean = false;

  public accountTypes: { name: string, value: string }[] = [
    { name: 'Checking', value: "checking" },
    { name: 'Savings', value: "savings" },
    { name: 'Business Checking', value: "businessChecking" }
  ];

  public onClose: Subject<{}>;

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.bankFormSubscription = this.bankForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
  }

  addBank = (): void => {
    if (this.bankForm.valid) {
      this.loaderService.showLoader();
      const postData: any = this.bankForm.getRawValue();
      delete postData.confirmAccountNumber;
      this.httpService.postDataFormData(ApiUrl.BANK_ADD, postData).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK) {
          this.messageService.alert('success', response.message, '');
          this.onClose.next({ success: true });
          this.closeModal();
        }
      }, (error) => {
        this.loaderService.hideLoader();
        if (error.statusCode == ResponseStatusCode.UNAUTHORIZED) {
          this.closeModal();
        }
        this.utilityService.unAuthorized(error);
      });
    } else {
      this.showError = true;
    }
  }


  closeModal = (): void => {
    this._bsModalRef.hide();
  }

  ngOnDestroy(): void {
    if (this.bankFormSubscription) {
      this.bankFormSubscription.unsubscribe();
    }
  }

  address = (data: any): void => {
    this.bankForm.patchValue({
      address: (data || {}).formatted_address_second || '',// (data || {}).formatted_address || '',
      country: (data || {}).country || '',
      state: (data || {}).admin_area_l1 || '',
      city: (data || {}).locality || '',
      zipCode: (data || {}).postal_code || ''
    });
  }

  getCurrentPosition = (): void => {
    const reference = this;
    navigator.geolocation.getCurrentPosition((pos) => {
      const currentLocation = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
      const geocoder = new google.maps.Geocoder();
      const location_obj: any = {};
      geocoder.geocode({ 'latLng': currentLocation }, (results: any, status: any) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            for (var i = 0; i < results[0].address_components.length; i++) {
              for (var b = 0; b < results[0].address_components[i].types.length; b++) {
                if (results[0].address_components[i].types.indexOf('locality') > -1) {
                  location_obj['locality'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('administrative_area_level_1') > -1) {
                  location_obj['admin_area_l1'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('street_number') > -1) {
                  location_obj['street_number'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('route') > -1) {
                  location_obj['route'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('country') > -1) {
                  location_obj['country'] = results[0].address_components[i]['long_name'];
                } else if (results[0].address_components[i].types.indexOf('postal_code') > -1) {
                  location_obj['postal_code'] = results[0].address_components[i]['long_name'];
                }
                location_obj['formatted_address'] = results[0].formatted_address;
              }
            }
          }
          reference.bankForm.patchValue({
            address: (location_obj || {}).formatted_address || '',
            country: (location_obj || {}).country || '',
            state: (location_obj || {}).admin_area_l1 || '',
            city: (location_obj || {}).locality || '',
            zipCode: (location_obj || {}).postal_code || ''
          });
        }
      });
    }, (error) => {
      console.log('Unable to get location: ' + error.message);
    });
  }

}

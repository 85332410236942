<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">
                <img src="./../../../../assets/images/login-flow-images/Group.png" class="pe-2">
                Date Range
            </div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="./../../../../assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>

        <div class="p-30 popup-input popup-input-date">
            <form [formGroup]="dateRangeForm" (ngSubmit)="closeModalWithYes()">

                <div class="mb-3">
                    <label for="startDate">Start Date</label>
                    <div class="ui calendar" id="startDate">
                        <div class="icon-place ui input left icon">
                            <input type="date" class="form-control" id="startDate" placeholder="" name="startDate"
                                autocomplete="off" formControlName="startDate"
                                [max]="dateRangeForm.controls['endDate']?.value">
                            <!-- <img src="./../../../../assets/images/login-flow-images/calender.png" class="icon-pos"> -->
                        </div>
                    </div>
                    <span class="show-input-error"
                        *ngIf="dateRangeForm.controls['startDate'].hasError('required') && showError">Please
                        select start date</span>
                </div>

                <div class="mb-4 pb-2">
                    <label for="endDate">End Date</label>
                    <div class="ui calendar" id="endDate">
                        <div class="icon-place ui input left icon">
                            <input type="date" class="form-control" id="endDate" placeholder="" name="endDate"
                                autocomplete="off" formControlName="endDate" 
                                [min]="dateRangeForm.controls['startDate']?.value">
                            <!-- <img src="./../../../../assets/images/login-flow-images/calender.png" class="icon-pos"> -->
                        </div>
                    </div>
                    <span class="show-input-error"
                        *ngIf="dateRangeForm.controls['endDate'].hasError('required') && showError">Please
                        select end date</span>
                </div>

                <button type="submit" class="btn btn-default btn-hover color-1 text-uppercase mt-2 ">Submit</button>

            </form>
        </div>

    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { HttpService } from 'src/app/services/http.service';
import { UtilityService } from 'src/app/services/utility.service';
import { MessageService } from 'src/app/services/message.service';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';

@Component({
  selector: 'app-payment-for-subscription-plans',
  templateUrl: './payment-for-subscription-plans.component.html',
  styleUrls: ['./payment-for-subscription-plans.component.css']
})
export class PaymentForSubscriptionPlansComponent implements OnInit {
  allpostdata: any;
  businessContactPersonFullName: string;
  carName: string = '';
  cardNumber: string = '';
  cardExpiryMonth: string = '';
  cardExpiryYear: string = ''
  cvv: string = ''
  constructor(
    private location: Location,
    private router: Router,
    private loaderService: LoaderService,
    private httpService: HttpService,
    private messageService: MessageService,
    private utilityServiceError: UtilityService
  ) { }

  ngOnInit(): void {
    const storedData = localStorage.getItem('alldata');
    let postData;
    if (storedData !== null) {
      postData = JSON.parse(storedData);
      this.businessContactPersonFullName = `${postData.businessContactPersonFirstName} ${postData.businessContactPersonLastName}`;
      this.allpostdata = postData
      console.log('post', this.allpostdata)
    } else {
      postData = {};
    }
    // Check if page is being reloaded
    if (performance.navigation.type === 1) {
      this.onClickChangeLocation();
    }
    // this.location.back();
  }
  onClickChangeLocation = (): void => {
    this.router.navigate(['/subscription-plans']);
  }

  onSubmit() {
    const postdata = {
      fullName: this.businessContactPersonFullName,
      email: this.allpostdata.businessEmail,
      address: this.allpostdata.bussinessAddress1,
      city: this.allpostdata.bussinessCity,
      state: this.allpostdata.bussinessState,
      zip: Number(this.allpostdata.bussinessZipCode),
      cardName: this.carName,
      cardNumber: Number(this.cardNumber),
      cardExpiryMonth: this.cardExpiryMonth,
      cardExpiryYear: this.cardExpiryYear,
      paymentType: "creditCard",
      cvv: Number(this.cvv)
    }
    console.log('all', postdata);
    this.loaderService.showLoader();
    this.httpService.postData(ApiUrl.SAAS_PAYMENT_API, postdata).subscribe((response) => {
      this.loaderService.hideLoader();
      // if (response.statusCode == ResponseStatusCode.OK) {
      this.messageService.alertWithTimer('success', 'Successfully created');
      this.router.navigate(['/login']);
      window.open(`https://${response.data.domain}`, "_blank");
      // window.open('https://workspace.gasitup.com/sign-up', "_blank");
      // }
    }, (error) => {
      this.utilityServiceError.unAuthorized(error)
      // this.messageService.alert('error', error.customMessage);
      this.loaderService.hideLoader();
    });
  }

}

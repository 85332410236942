<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">Inspections Logs</div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="/assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>

        <div class="bg-white">
            <div class="light-blue">
                <div class="table-responsive popup-table table-height bg-th" style="height: auto;">
                    <table class="table mb-0">
                        <thead class="table-dark table-light">
                            <tr>
                                <th class="serialno text-center">Sr No.</th>
                                <th>Log Date</th>
                                <th>Driver Name</th>
                                <th>Vehicle Condition</th>
                                <th>Inspections</th>
                            </tr>
                        </thead>
                        <tbody class="table-td">
                            <tr
                                *ngFor="let item of paginationControlsOptions.data | paginate: { itemsPerPage: paginationControlsOptions.limit, currentPage: paginationControlsOptions.page, totalItems: paginationControlsOptions.total }; let i = index">
                                <td class="serialno text-center">
                                    {{(paginationControlsOptions.page-1)*paginationControlsOptions.limit + i + 1}}
                                </td>
                                <td>{{item?.logTime ? (item?.logTime | date:'mediumDate') : '-'}}</td>
                                <td>{{item?.driverId?.firstName || '-'}} {{item?.driverId?.lastName || ''}}</td>
                                <td>
                                    {{!item?.submitMechanicRequest ? 'Vehicle Condition is Satisfactory' : 'Submit Mechanic Request'}}
                                </td>
                                <td>
                                    <span *ngFor="let data of item.inspectionId;let last = last">
                                        {{data?.name || '-'}}<span *ngIf="!last">,</span><br>
                                    </span>
                                </td>
                            </tr>
                            <tr *ngIf="!paginationControlsOptions.data?.length">
                                <td colspan="5" style="text-align: center;"> No Record Found!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="p-3 pt-0" *ngIf="paginationControlsOptions.total > 0">
                <!-- <div class="row mt-4" > -->
                    <div class="col-md-12" style="text-align: end;">
                      <pagination-controls class="my-pagination" (pageChange)="getPage($event)"></pagination-controls>
                    </div>
                  <!-- </div> -->
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.css']
})
export class SubscriptionPlansComponent implements OnInit {

  constructor(
    private httpService: HttpService,
    private router: Router,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getSubscriptionPlansData();
  }

  getSubscriptionPlansData = (): void => {
    this.loaderService.showLoader();
    this.httpService.getData_saas(ApiUrl.SAAS_FETCH_SUBSCRIPTIONS_SAAS).subscribe((response: any) => {
      console.log("🚀 ~ SubscriptionPlansComponent ~ this.httpService.getData_saas ~ response:", response)
      this.loaderService.hideLoader();

    }, (error) => {
      this.loaderService.hideLoader();
    })
  }

}

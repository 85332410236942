<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">
                <!-- <img src="/assets/images/login-flow-images/Vector (2).png" class="pe-2"> -->
                <img src="./../../../assets/images/GIU Purple Pin.svg" class="img-fluid m-w-140">
                Reassigned Driver
            </div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="/assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>

        <div class="bg-white">
            <div class="p-30 light-blue">
                <form [formGroup]="formGroup" (ngSubmit)="assignDriverManually()">
                    <div class="row">
                        <!-- <div class="col-md-12 mb-2">
                            <label for="bookingNo">Order No</label>
                            <input class="form-control height-50 border-8" type="text" name="bookingNo" id="bookingNo"
                                placeholder="Enter Order No" formControlName="bookingNo" autocomplete="off">
                        </div> -->

                        <div class="col-md-12 mb-3">
                            <label for="driverId">Driver Name</label>
                            <div class="styled-select black-icon w-100">
                                <select class="form-select" id="driverId" name="driverId" formControlName="driverId">
                                    <option value="" selected disabled>Select Driver Name</option>
                                    <option [value]="item?._id" *ngFor="let item of driverList">
                                        {{(item?.firstName +' '+ item?.lastName)}} ({{item?.email}})
                                    </option>
                                </select>
                            </div>
                            <span class="show-input-error"
                                *ngIf="formGroup.controls['driverId'].hasError('required') && showError">Please
                                select Driver</span>
                        </div>
                    </div>
                    <div class="col-12">
                        <button type="submit"
                            class="btn btn-default btn-hover color-1 w-100 height-50 mx-auto d-block ">Submit</button>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>
<header class="login-bg ">
    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <!-- <img
                    src="./../../../assets/images/login-flow-images/logo-with-title-purple.svg"> -->
                <!-- <img
                    src="./../../../assets/images/login-flow-images/Logo-Animation.gif">                -->
                <i class="">
                    <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                    <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px"
                        class="logotext" />
                </i>

            </a>
            <div class="login-cover">
                <h4 class="login-head">Login to Gas It Up</h4>
                <form [formGroup]="loginForm" (ngSubmit)="login()">
                    <div class="mb-3">
                        <label for="email">E-mail</label>
                        <input type="email" class="form-control" id="email" placeholder="Enter your E-mail" name="email"
                            formControlName="email" autocomplete="off">
                        <span class="show-input-error"
                            *ngIf="loginForm.controls['email'].hasError('required') && showError">Please enter your
                            E-mail</span>
                        <span class="show-input-error" *ngIf="!loginForm.controls['email'].hasError('required') 
                            && loginForm.controls['email'].hasError('email') && showError">Please enter valid
                            E-mail</span>
                    </div>
                    <div class="mb-3">
                        <label for="pwd">Password</label>
                        <div class="position-relative">
                            <input  type="{{passwordType}}"  class="form-control" id="password-field" placeholder="Enter your Password"
                                name="pswd" formControlName="password" autocomplete="off">
                            <span toggle="#password-field" class="{{passwordTypeClass}}" (click)="changePasswordType()"
                                style="cursor: pointer;"></span>
                        </div>
                        <span class="show-input-error"
                            *ngIf="loginForm.controls['password'].hasError('required') && showError">Please enter your
                            Password</span>
                    </div>
                    <div class="form-check ps-0 mb-3 flex-text login-form-check">
                        <input type="checkbox" id="RememberMe" name="RememberMe" [(ngModel)]="isRememberMe"
                            [ngModelOptions]="{standalone: true}">
                        <label class="remember-me" for="RememberMe">Remember me</label>
                        <a href="javascript:void(0)" class="forgot-pw" [routerLink]="['/forgot-password']">Forgot
                            Password?</a>
                    </div>
                    <button type="submit" class="btn btn-default btn-hover color-1 mb-3 ">Login</button>
                    <p class="acc-create">Create an account?<a href="javascript:void(0)"
                            [routerLink]="['/sign-up']">Signup</a></p>
                </form>
            </div>
        </div>
    </nav>
</header>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-recurring-fuel-delivery',
  templateUrl: './recurring-fuel-delivery.component.html',
  styleUrls: ['./recurring-fuel-delivery.component.css']
})
export class RecurringFuelDeliveryComponent implements OnInit, OnDestroy {

  public onClose: Subject<{}>;
  public recurringFuelDeliveryForm: FormGroup = new FormGroup({
    date: new FormControl('', [Validators.required]),
    time: new FormControl('', [Validators.required]),
    recurringType: new FormControl('', [Validators.required]),
    dailyCount: new FormControl(''), // ,[Validators.required, Validators.min(1)]
    monthlyCount: new FormControl(''), // , [Validators.required, Validators.min(1)]
    weeklyCount: new FormControl('') //, [Validators.required, Validators.min(1)]
  });
  private recurringFuelDeliveryFormSubscription: Subscription;
  public showError: boolean = false;
  public recurringTypeList: { name: string, value: string }[] =
    [
      { name: 'Daily', value: 'DAILY' }, { name: 'Weekly', value: 'WEEKLY' }, { name: 'Monthly', value: 'MONTHLY' }
    ];
  public daysList: { name: string, value: string, isActive: boolean }[] =
    [
      { name: 'Sunday', value: '7', isActive: false }, { name: 'Monday', value: '1', isActive: false },
      { name: 'Tuesday', value: '2', isActive: false }, { name: 'Wednesday', value: '3', isActive: false },
      { name: 'Thursday', value: '4', isActive: false }, { name: 'Friday', value: '5', isActive: false },
      { name: 'Saturday', value: '6', isActive: false }
    ];

  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private utilityService: UtilityService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.recurringFuelDeliveryForm.patchValue({
      date: moment().format("yyyy-MM-DD"),
      time: moment().add(2, 'hours').format("HH:mm")
    });
    this.onClose = new Subject();
    // if (this.options.initialState) {
    //   this.data = this.options.initialState['data'] || '';
    // }
    this.recurringFuelDeliveryFormSubscription = this.recurringFuelDeliveryForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
  }

  onChangeRecurringType = (): void => {
    switch (this.recurringFuelDeliveryForm.get('recurringType')?.value) {
      case 'DAILY':
        this.recurringFuelDeliveryForm.get('dailyCount')?.setValidators([Validators.required, Validators.min(1)]);
        this.recurringFuelDeliveryForm.get('monthlyCount')?.setValidators([]);
        this.recurringFuelDeliveryForm.get('weeklyCount')?.setValidators([]);
        break;
      case 'WEEKLY':
        this.recurringFuelDeliveryForm.get('dailyCount')?.setValidators([]);
        this.recurringFuelDeliveryForm.get('weeklyCount')?.setValidators([Validators.required, Validators.min(1)]);
        this.recurringFuelDeliveryForm.get('monthlyCount')?.setValidators([]);
        break;
      case 'MONTHLY':
        this.recurringFuelDeliveryForm.get('dailyCount')?.setValidators([]);
        this.recurringFuelDeliveryForm.get('weeklyCount')?.setValidators([]);
        this.recurringFuelDeliveryForm.get('monthlyCount')?.setValidators([Validators.required, Validators.min(1)]);
        break;
      default:
        break;
    }
    this.recurringFuelDeliveryForm.get('dailyCount')?.updateValueAndValidity({ onlySelf: true });
    this.recurringFuelDeliveryForm.get('monthlyCount')?.updateValueAndValidity({ onlySelf: true });
    this.recurringFuelDeliveryForm.get('weeklyCount')?.updateValueAndValidity({ onlySelf: true });
    this.recurringFuelDeliveryForm.updateValueAndValidity({ onlySelf: true });
  }

  closeModalWithYes = (): void => {
    if (this.recurringFuelDeliveryForm.valid) {
      this.showError = false;
      const toDate = moment().unix() * 1000;
      const time = 2 * 60 * 60 * 1000;
      const newTime = moment(new Date(this.recurringFuelDeliveryForm.get('date')?.value + ' ' + this.recurringFuelDeliveryForm.get('time')?.value)).unix() * 1000;
      if ((newTime - toDate) >= time) {
        if (this.recurringFuelDeliveryForm.get('recurringType')?.value == 'WEEKLY') {
          if ((this.daysList.filter((item) => item.isActive == true)).length) {
            this.onClose.next({ success: true, data: this.recurringFuelDeliveryForm.getRawValue(), selectedDaysList: this.daysList.filter((item) => item.isActive == true) });
            this._bsModalRef.hide();
          } else {
            this.messageService.alert('error', 'Please select days');
          }
        } else {
          this.onClose.next({ success: true, data: this.recurringFuelDeliveryForm.getRawValue(), selectedDaysList: this.daysList.filter((item) => item.isActive == true) });
          this._bsModalRef.hide();
        }
      } else {
        this.messageService.alert('error', 'Scheduled orders must be placed at least 2 hours in advance');
      }
    } else {
      this.showError = true;
    }
  }

  closeModal = (): void => {
    this.onClose.next({ success: false });
    this._bsModalRef.hide();
  }

  ngOnDestroy(): void {
    if (this.recurringFuelDeliveryFormSubscription) {
      this.recurringFuelDeliveryFormSubscription.unsubscribe();
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';
@Component({
  selector: 'app-saas-add-business',
  templateUrl: './saas-add-business.component.html',
  styleUrls: ['./saas-add-business.component.css']
})
export class SaasAddBusinessComponent implements OnInit {
  contactForm: FormGroup;
  form: FormGroup;
  mergedFormGroup: FormGroup;
  selectedFile: File | null = null;
  public showError: boolean = false;
  public onClose: Subject<{}>;
  private formGroupSubscription: Subscription;
  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private utilityService: UtilityService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) { }
  

  createEntry(): FormGroup {
    return this.fb.group({
      businessName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      email: ['', Validators.required],
      createdOn: ['', Validators.required],
      ownerName: ['', Validators.required],
      invoiceNumber: ['', Validators.required],
      address: ['', Validators.required],
      location: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      contactNumber: ['', Validators.required],
      paymentMode: ['', Validators.required],
      designation: ['', Validators.required],
      email: ['', Validators.required],
      ContactImage:['', Validators.required]
      // Add more form controls as needed
    });

    this.form = this.fb.group({
      entries: this.fb.array([this.createEntry()])
    });
    if (this.options.initialState) {
      const tempData: any = this.options.initialState;
    }

     this.mergedFormGroup = this.fb.group({
      name: this.contactForm.get('name'),
      contactNumber: this.contactForm.get('contactNumber'),
      paymentMode: this.contactForm.get('paymentMode'),
      designation: this.contactForm.get('designation'),
      email: this.contactForm.get('email'),
      ContactImage: this.contactForm.get('ContactImage'),
      entries: this.form.get('entries')
    });
  }

  closeModal = (): void => {
    this.onClose.next({ success: false });
    this._bsModalRef.hide();
  }

  get entries(): FormArray {
    return this.form.get('entries') as FormArray;
  }

  addEntry(): void {
    this.entries.push(this.createEntry());
  }

  removeEntry(index: number): void {
    this.entries.removeAt(index);
  }
  
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0] as File;
    const formData = new FormData();
    formData.append('image', this.selectedFile);
    console.log('selected file',this.selectedFile);
  }

  uploadImage() {
    if (!this.selectedFile) {
      console.log('No file selected.');
      return;
    }
  }

  onFileSelected1(event: any): void {
    // Handle the file selection here
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // You can perform further actions with the selected file, such as uploading it
      console.log('Selected file:', selectedFile);
    }
  }

  onSubmit() {
    if (this.mergedFormGroup.valid) {
      console.log(this.mergedFormGroup.value);
    } else {
        this.showError = true;
      }
  }

}

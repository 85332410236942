import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor() { }

  /**
  * Alert dialog box with OK button.
  * @param icon as @type SweetAlertIcon
  * @param title to pass title to the alert to showing
  * @param text to pass text to the alert to showing
  */
  alert(icon: SweetAlertIcon, title: string, text?: string) {
    Swal.fire({
      title: title || '',
      text: text || '',
      icon: icon,
      confirmButtonText: 'OK',
      customClass: 'uk-animation-slide-top-small',
      confirmButtonColor: '#542581'
    });
  }

  /**
  * Alert dialog box with OK button and even with timer i.e 1500 ms to close this alert itself.
  * @param icon as @type SweetAlertIcon
  * @param title to pass title to the alert to showing
  * @param text to pass text to the alert to showing
  */
  alertWithTimer(icon: SweetAlertIcon, title: string, text?: string) {
    Swal.fire({
      title: title || '',
      text: text || '',
      icon: icon,
      confirmButtonText: 'OK',
      customClass: 'uk-animation-slide-top-small',
      confirmButtonColor: '#542581',
      timer: 1500
    });
  }

  /**
  * Alert dialog box with OK button and when click OK button @method callback called itself.
  * @param icon as @type SweetAlertIcon
  * @param title to pass title to the alert to showing
  * @param text to pass text to the alert to showing
  * @function callback
  */
  alertCallback(icon: SweetAlertIcon, title: string, text: string, callback: any) {
    Swal.fire({
      title: title || '',
      text: text || '',
      icon: icon,
      confirmButtonText: 'OK',
      customClass: 'uk-animation-slide-top-small',
      confirmButtonColor: '#542581'
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  }

  /**
  * Alert dialog box with OK OR CANCEL button and when click any button @method callback called itself with @param TRUE for OK button , FALSE for CANCEL button.
  * @param icon as @type SweetAlertIcon
  * @param title to pass title to the alert to showing
  * @param text to pass text to the alert to showing
  * @function callback
  */
  alertCallbackWithTwoButton(icon: SweetAlertIcon, title: string, text: string, callback: any) {
    Swal.fire({
      title: title || '',
      text: text || '',
      icon: icon,
      confirmButtonText: 'Yes',
      customClass: 'uk-animation-slide-top-small',
      confirmButtonColor: '#542581',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#D0D0D0'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(true);
      } else {
        callback(false);
      }
    });
  }

}
